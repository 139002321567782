import axios from 'axios'
// import qs from "qs";

const serverUrl = window.config.ServerUrl;
let service = ""; // 创建axios实例

let options = {
    urlBit: "", // 控制器路径
    baseURL: serverUrl,
    // baseURL: '', // 服务器路径
    method: "get", // 发送类型
    data: [], // 发送数据
    timeout: 15000, // 超时时间
    retry: 3, //设置全局重试请求次数（最多重试几次请求）
    retryDelay: 1000, //设置全局请求间隔
}

function interfaceParam(data) {
    // 遍历填入对象
    extend(options, data);

    // 发送Axios
    createAxios();

    return new Promise((resolve, reject) => {
        service({
            url: options.urlBit,
            method: options.method,
            data: options.data
        }).then(response => {
            if (response) {
                resolve(response);
            } else {
                resolve("null");
            }
        }).catch(error => {
            reject(error)
        })
    });

}

// 创建Axios
function createAxios() {
    service = axios.create({
        baseURL: options.baseURL,
        timeout: options.timeout, // 请求超时时间
        retry: options.retry, //设置全局重试请求次数（最多重试几次请求）
        retryDelay: options.retryDelay, //设置全局请求间隔
    });

// request拦截器
    service.interceptors.request.use(config => {
        // console.log(config);
        // eslint-disable-next-line no-constant-condition
        // config.method === 'POST' || 'post'
        //     ? config.data = qs.parse({...config.data})
        //     : config.params = {...config.params};
        config.headers['Content-Type'] = 'application/json';

        return config
    }, error => {
        console.error(error);
        // Promise.reject(error);
        // Promise.reject(error);
        //超时处理 error.config是一个对象，包含上方create中设置的三个参数
        const config = error.config;
        if (!config || !config.retry) return Promise.reject(error);

        //如果有响应内容，就直接返回错误信息，不再发送请求
        if(error.response.data){
            return Promise.reject({type: "error", msg: error.response.data});
        }

        // __retryCount用来记录当前是第几次发送请求
        config.__retryCount = config.__retryCount || 0;

        // 如果当前发送的请求大于等于设置好的请求次数时，不再发送请求，返回最终的错误信息
        if (config.__retryCount >= config.retry) {
            if (error.message === "Network Error") {
                //message为"Network Error"代表断网了
                return Promise.reject({type: "warning",msg: "网络连接已断开，请检查网络"});
            } else if (error.message === "timeout of 5000ms exceeded") {
                //网太慢了，5秒内没有接收到数据，这里的8000ms对应上方timeout设置的值
                return Promise.reject({type: "warning",msg: "请求超时，请检查网络"});
            } else {
                //除以上两种以外的所有错误，包括接口报错 400 500 之类的
                return Promise.reject({type: "error",msg: "出现错误，请稍后再试"});
            }
        }

        // 记录请求次数+1
        config.__retryCount += 1;

        // 设置请求间隔 在发送下一次请求之前停留一段时间，时间为上方设置好的请求间隔时间
        const backoff = new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, config.retryDelay || 1);
        });

        // 再次发送请求
        return backoff.then(function () {
            return service(config);
        });
    });

// respone拦截器
    service.interceptors.response.use(
        response => {
            // console.log(response);
            /**
             * code为非200是抛错 可结合自己业务进行修改
             */
            if (response.status === 200) {
                return response.data
            } else if (response.data.Result === 16) {
                return response.data
            } else {
                return Promise.reject('error')
            }
        },
        error => {
            console.error('err' + error); // for debug
            return Promise.reject(error);
        }
    );
}

// 遍历对象
function extend(dst, obj) {
    for (const i in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(i)) {
            dst[i] = obj[i];
        }
    }
}

export default interfaceParam
