<template>
    <div class="emp-info">
        <div class="title">
            <span>批量更新员工信息</span>
        </div>
        <a-divider />
        <div class="emp-steps">
            <a-steps :current="current">
                <a-step v-for="item in steps" :key="item.title" :title="item.title" />
            </a-steps>
            <div class="steps-content">
                {{ steps[current].content }}
            </div>
            <div class="steps-action">
                <a-button v-if="current < steps.length - 1" type="primary" @click="next">
                    下一步
                </a-button>
                <a-button v-if="current == steps.length - 1" type="primary" @click="$message.success('修改成功!')">
                    完成
                </a-button>
                <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">
                    上一步
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            current: 0,
            steps: [
                {
                    title: '选择更新字段',
                    content: 'First-content',
                },
                {
                    title: '上传文件',
                    content: 'Second-content',
                },
                {
                    title: '校验中',
                    content: 'Thrid-content',
                },
                {
                    title: '完成',
                    content: 'Last-content',
                },
            ],
        };
    },
    methods: {
        next() {
            this.current++;
        },
        prev() {
            this.current--;
        },
    },
}
</script>

<style scoped lang="stylus">
.emp-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.title
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 800;
    line-height: 24px;

    span
        font-weight 500
        font-size 16px
        margin-left 25px
        color rgba(0, 0, 0, 0.85)

.emp-steps{
    margin: 16px;
}
.steps-content {
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    margin-top: 60px;
}

.steps-action {
    margin-top: 24px;
}
</style>

<style>
.emp-info .ant-steps-item-process .ant-steps-item-icon {
    background: #ED6D01 !important;
    border-color: #ED6D01;
}

.emp-info .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #ED6D01;
}

.emp-info .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
    color: #ED6D01;
}

.emp-info .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #ED6D01;
}

.ant-btn:hover {
    color: #ED6D01 !important;
    border-color: #ED6D01 !important;
}

.ant-btn-primary {
    background-color: #ED6D01 !important;
    border-color: #ED6D01 !important;
}

.ant-btn-primary:hover {
    background-color: #fd8733 !important;
    color: #fff !important;
    border-color: #fd8733;
}

</style>