import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less';
Vue.use(Antd)

//引入iconfont矢量库
import '@/assets/icon/iconfont.css';

import "@/utils/flexible";
// import 'lib-flexible'

import axios from 'axios'
Vue.prototype.axios = axios

import moment from 'moment'
Vue.prototype.$moment = moment

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
