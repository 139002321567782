import request from "../utils/request";

//项目信息 'http://10.192.167.42:8084/api/Project/SearchMyProjectPaged?Page=1&PageNum=10'
export function SearchMyProjectPaged(pagination, params) {
    const urlBitTxt = "/api/Project/SearchMyProjectPaged?Page=" + pagination.current + '&PageNum=' + pagination.pageSize;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//参与项目 'http://10.192.167.42:8084/api/Project/SearchMyParticipateInProjectPaged?Page=1&PageNum=10'
export function SearchMyParticipateInProjectPaged(pagination, params) {
    const urlBitTxt = "/api/Project/SearchMyParticipateInProjectPaged?Page=" + pagination.current + '&PageNum=' + pagination.pageSize;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//管理项目 'http://10.192.167.42:8084/api/Project/SearchMyManageProjectPaged?Page=1&PageNum=10'
export function SearchMyManageProjectPaged(pagination, params) {
    const urlBitTxt = "/api/Project/SearchMyManageProjectPaged?Page=" + pagination.current + '&PageNum=' + pagination.pageSize;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

// WBS查询'http://10.192.167.42:8084/api/WBS/GetWorkBreakdownStructure?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&ProjectID=a04c0a86-3033-4598-ac94-345f6a25b2b6'
export function GetWorkBreakdownStructure(params) {
    if (!params) {
        params = {
            UserID: "",
            ProjectID: ""
        }
    }
    const urlBitTxt = "/api/WBS/GetWorkBreakdownStructure?UserID=" + params.UserID + "&ProjectID=" + params.ProjectID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

// 任务参与人 查询'http://10.192.167.42:8084/api/WBS/GetProjectTaskUserList?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&TaskID=a04c0a86-3033-4598-ac94-345f6a25b2b6'
export function GetProjectTaskUserList(params) {
    if (!params) {
        params = {
            UserID: "",
            TaskID: ""
        }
    }
    const urlBitTxt = "/api/WBS/GetProjectTaskUserList?UserID=" + params.UserID + "&TaskID=" + params.TaskID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}
//所有人员http://10.192.167.42:8084/api/UserInfo/SelecttEmployeesALL?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be
export function SelecttEmployeesALL(params) {
    if (!params) {
        params = {
            UserID: "",
        }
    }
    const urlBitTxt = "/api/UserInfo/SelecttEmployeesALL?UserID=" + params.UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}
//添加人员 http://10.192.167.42:8084/api/WBS/AddProjectUserList?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&ProjectID=a04c0a86-3033-4598-ac94-345f6a25b2b6&ProjectTaskID=33b867c1-6f53-411f-a4fc-63ea9efc7601'
export function AddProjectUserList(params,data) {
    const urlBitTxt = "/api/WBS/AddProjectUserList?UserID=" + params.UserID + '&ProjectID=' + params.ProjectID+ '&ProjectTaskID=' + params.ProjectTaskID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: data
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}
//删除参与人 'http://10.192.167.42:8084/api/WBS/DeleteProjectTaskUserList?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&TaskID=33b867c1-6f53-411f-a4fc-63ea9efc7601&TaskUserID=35f38acd-50a9-4cd8-8da6-9010bc6f0bfe'
export function DeleteProjectTaskUserList(params) {
    if (!params) {
        params = {
            UserID: "",
            TaskID: "",
            TaskUserID: ""
        }
    }
    const urlBitTxt = "/api/WBS/DeleteProjectTaskUserList?UserID=" + params.UserID + "&TaskID=" + params.TaskID + "&TaskUserID=" + params.TaskUserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}
//修改wbs分解
export function ProjectTaskUpDate(params,data) {
    const urlBitTxt = "/api/WBS/ProjectTaskUpDate?UserID=" + params.UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: data
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}
//修改wbs分解
export function EditWorkBreakdownStructure(params,data) {
    const urlBitTxt = "/api/WBS/EditWorkBreakdownStructure?UserID=" + params.UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: data
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//类型库 'http://10.192.167.42:8084/api/BasicData/SelecttBasicCodesChild?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&ParentID=1CD7B10E-45E9-44C7-8815-D9B5A6D2B308&Status=T&OrderField=fOrderValue'
export function SelecttBasicCodesChild(params) {
    if (!params) {
        params = {
            UserID: "",
            ParentID: "",
            Status: "",
            OrderField: "",
        }
    }
    const urlBitTxt = "/api/BasicData/SelecttBasicCodesChild?UserID=" + params.UserID + "&ParentID=" + params.ParentID + "&Status=" + params.Status + "&OrderField=" + params.OrderField;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

// 部门 'http://10.192.167.42:8084/api/BasicData/SelectDepartmentsParent?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be'
export function SelectDepartmentsParent(UserID) {
    if (!UserID) {
        UserID = ''
    }
    const urlBitTxt = "/api/BasicData/SelectDepartmentsParent?UserID=" + UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

// 区域 'http://10.192.167.42:8084/api/BasicData/SelecttAreaType?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be'
export function SelecttAreaType(UserID) {
    if (!UserID) {
        UserID = ''
    }
    const urlBitTxt = "/api/BasicData/SelecttAreaType?UserID=" + UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

// 项目状态 'http://10.192.167.42:8084/api/BasicData/SelecttProjectStatus?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be'
export function SelecttProjectStatus(UserID) {
    if (!UserID) {
        UserID = ''
    }
    const urlBitTxt = "/api/BasicData/SelecttProjectStatus?UserID=" + UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}