import Vue from 'vue'
import VueRouter from 'vue-router'
import Tasks from '../views/tasks/Tasks'
import FillIn from "../views/fill/FillIn";
import ReportForms from "../views/fill/ReportForms";
import Calendar from "../views/calendar/Calendar";
import Index from "../views/index/Index";
import EmployeeInfo from "../views/info/EmployeeInfo.vue";
// import EditEmployee from "../views/info/EditEmployee.vue";
import EmployeeList from "../views/info/EmployeeList.vue";
import DepartmentList from "../views/info/DepartmentList.vue";
import PositionList from "../views/info/PositionList.vue";
import WBSDecomposition from "../views/WBSDecomposition/WBSDecomposition";
import RoleList from "../views/permission/RoleList.vue";
import ModuleList from "../views/permission/ModuleList.vue";
import InvolvedInProject from "../views/projects/InvolvedInProject";
import ManageProjects from "../views/projects/ManageProjects";
import ProjectInformation from "../views/projects/ProjectInformation";
import Detailed from "../views/fill/Detailed.vue";
import ApplyList from "../views/time/ApplyList.vue";
import ApproveList from "../views/time/ApproveList.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: () => import('../views/login/Login.vue'),
        meta: { title: "登录" }
    },
    {
        path: '/EmailUpdatePassword',
        name: 'EmailUpdatePassword',
        component: () => import('../components/home/EmailUpdatePassword.vue'),
        meta: { title: "找回密码" }
    },
    {
        path: '/Home',
        name: 'Home',
        redirect: "/Home/index",
        component: () => import('../components/home/Home.vue'),
        meta: { title: "项目管理系统" },
        children: [
            {
                path: 'ReportForms',
                name: 'ReportForms',
                component: ReportForms
            },
            {
                path: 'FillIn',
                name: 'FillIn',
                component: FillIn
            },
            {
                path: 'InvolvedInProject',
                name: 'InvolvedInProject',
                component: InvolvedInProject
            },
            {
                path: 'ManageProjects',
                name: 'ManageProjects',
                component: ManageProjects
            },
            {
                path: 'ProjectInformation',
                name: 'ProjectInformation',
                component: ProjectInformation
            },
            {
                path: 'Tasks',
                name: 'Tasks',
                component: Tasks
            },
            {
                path: 'Calendar',
                name: 'Calendar',
                component: Calendar
            },
            {
                path: 'Index',
                name: 'Index',
                component: Index
            },
            {
                path: 'EmployeeInfo',
                name: 'EmployeeInfo',
                component: EmployeeInfo
            },
            {
                path: 'EmployeeList',
                name: 'EmployeeList',
                component: EmployeeList
            },
            {
                path: 'DepartmentList',
                name: 'DepartmentList',
                component: DepartmentList
            },
            {
                path: 'PositionList',
                name: 'PositionList',
                component: PositionList
            },
            {
                path: 'WBSDecomposition',
                name: 'WBSDecomposition',
                component: WBSDecomposition
            },
            {
                path: 'RoleList',
                name: 'RoleList',
                component: RoleList
            },
            {
                path: 'ModuleList',
                name: 'ModuleList',
                component: ModuleList
            },
            {
                path: 'Detailed',
                name: 'Detailed',
                component: Detailed
            },
            {
                path: 'ApplyList',
                name: 'ApplyList',
                component: ApplyList
            },
            {
                path: 'ApproveList',
                name: 'ApproveList',
                component: ApproveList
            },
        ]
    }
]


const router = new VueRouter({
    routes
})

const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return RouterPush.call(this, to).catch(err => err)
}

export default router
