<template>
    <div>
        <a-modal :footer="null" width="700px" :centered="true" :visible="addShow" title="编辑权限" @ok="addOk" @cancel="addCancel">
            <a-checkbox :checked="item.checked" :key="index" v-for="(item,index) in ModuleData"
                        @change="onChange(item)">
                {{ item.fModuleName }}
            </a-checkbox>
        </a-modal>
    </div>
</template>

<script>

import {GetModuleALL} from "../../api/ModuleList";
import {
    GetStationModule,
    DeletetPermissionsByfStationIDOrModuleID,
    InsertRolePermissions
} from "../../api/RoleList"

export default {
    name: "RoleListAdd",
    props: ['addShow', 'StationID'],
    data() {
        return {
            ModuleData: [],
            UserID: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
            moduleId: '',//模块id
            checkboxData: [],
            SelectedArr: ['工作日期设置']
        }
    }, mounted() {
        // console.log('用户id', this.StationID)
        this._GetModuleALL()
    }, methods: {
        _DeletetPermissionsByfStationIDOrModuleID(param) {
            DeletetPermissionsByfStationIDOrModuleID(param).then((res) => {
                // console.log('删除权限回调', res)
                if (res.result === true) {
                    this.$message.success('删除权限成功');
                    this.$parent._GetStationModuleALL();
                } else {
                    this.$message.error('删除权限失败');
                }
            })
        },
        _InsertRolePermissions(param, data) {
            InsertRolePermissions(param, data).then((res) => {
                // console.log('新增权限回调', res)
                if (res.result.bRet === true) {
                    this.$message.success('新增权限成功');
                    this.$parent._GetStationModuleALL();
                } else {
                    this.$message.error('新增权限失败');
                }
            })
        },
        //获取职务权限模块
        _GetStationModule() {
            const param = {
                UserID: this.UserID,
                StationID: this.StationID,
            }
            GetStationModule(param).then((res) => {
                // console.log("222222")
                // console.log(res.result)
                let arr = []
                res.result.forEach(item => item.lSystemModuleRights.forEach(item1 => arr.push(item1.fModuleName)))
                // console.log(arr)
                this.ModuleData.forEach(item => {
                    arr.forEach(item1 => {
                        if (item.fModuleName === item1) {
                            item.checked = true
                        }
                    })
                })
            })
        },
        onChange(item) {
            // console.log('checked = ', item);
            item.checked = !item.checked
            if (item.checked === true) {
                //新增
                const params = {
                    UserID: this.UserID,
                }
                const data = {
                    fStationID: this.StationID,
                    fSystemModuleID: item.fSystemModuleID,
                }
                this._InsertRolePermissions(params, data)
                // console.log("新增", item.fSystemModuleID)
            } else if (item.checked === false) {
                //删除
                const params = {
                    UserID: this.UserID,
                    fStationID: this.StationID,
                    fSystemModuleID: item.fSystemModuleID
                }
                this._DeletetPermissionsByfStationIDOrModuleID(params)
                // console.log("删除", item.fSystemModuleID)
                // console.log("删除")
            }
        },
        //获取所以模块
        _GetModuleALL() {
            let param = {
                UserID: this.UserID
            };
            GetModuleALL(param).then((res) => {
                // console.log(res.result)
                this.ModuleData = []
                res.result.objReturnInfo.forEach(item => item.lSystemModuleRights.forEach(item1 => {
                    item1.checked = false
                    this.ModuleData.push(item1)
                }))
                // console.log('模块id', this.moduleId)
                // console.log(this.ModuleData)
                this._GetStationModule()
            })
        },
        addOk() {
            this.$emit("update:addShow", false)
        },
        addCancel() {
            this.$emit("update:addShow", false)
        },
    }
}
</script>

<style scoped lang="stylus">
.ActionBar
    width 100%
    height 40px
    display flex
    flex-direction row
    justify-content flex-start
    align-items center
    margin-bottom 20px

    .ant-select
        width 200px
        margin-left 10px

/deep/ .ant-checkbox-wrapper + .ant-checkbox-wrapper
    margin-left 0

/deep/ .ant-checkbox-wrapper
    margin-right 10px
    margin-bottom 10px
</style>