<template>
    <div id="ProjectInformation">
        <div class="title">
            <span>项目信息</span>
        </div>
        <div class="forms">
            <a-form layout="inline">
                <a-form-item label="项目编号">
                    <a-input placeholder="项目编号" v-model="fProjectNum"/>
                </a-form-item>
                <a-form-item label="项目名称">
                    <a-input placeholder="项目名称" v-model="fProjectName"/>
                </a-form-item>
                <a-form-item label="所属部门">
                    <a-select placeholder="请选择" :value="fDepartmentID" @change="handleChange2" allowClear>
                        <a-select-option :key="index" :value="item.fDepartmentID"
                                         v-for="(item,index) in fDepartmentData">
                            {{ item.fDepartmentName }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="项目状态">
                    <a-select placeholder="请选择" :value="fProjectStatus" @change="handleChange1" allowClear>
                        <a-select-option :key="index" :value="item.fBasicCodeID"
                                         v-for="(item,index) in fProjectStatusData">
                            {{ item.fCodeName }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="区域">
                    <a-select placeholder="请选择" :value="fAreaID" @change="handleChange" allowClear>
                        <a-select-option :key="index" :value="item.fBasicCodeID" v-for="(item,index) in fAreaData">
                            {{ item.fCodeName }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="_SearchMyProjectPaged">查询</a-button>
                </a-form-item>
            </a-form>
        </div>
        <div class="tables">
            <a-table :rowKey="(record,index) => index" :columns="columns" :data-source="ProjectData"
                     :pagination="pagination" @change="handleTableChange">
                <div slot="fDepartmentID" slot-scope="fDepartmentID">
                    <div :key="index" v-for="(item,index) in fDepartmentData">
                        <span v-if="item.fDepartmentID === fDepartmentID">{{ item.fDepartmentName }}</span>
                    </div>
                </div>
                <span slot="action" slot-scope="text, record">
                    <span style="color: #ED6D01;cursor: pointer;" @click="goProgressQuery(text, record)">进度查询</span>
                </span>
            </a-table>
        </div>
    </div>
</template>

<script>
import {SearchMyProjectPaged, SelectDepartmentsParent, SelecttAreaType, SelecttProjectStatus} from '../../api/projects'

export default {
    name: "ProjectInformation",
    data() {
        return {
            columns: [
                {
                    title: '项目编号',
                    key: 'fProjectNum',
                    dataIndex: 'fProjectNum',
                },
                {
                    title: '项目名称',
                    dataIndex: 'fProjectName',
                    key: 'fProjectName',
                },
                {
                    title: '负责部门',
                    dataIndex: 'fDepartmentID',
                    key: 'fDepartmentID',
                    scopedSlots: {customRender: 'fDepartmentID'}
                },
                {
                    title: '区域',
                    dataIndex: 'fAreaName',
                    key: 'fAreaName'
                },
                {
                    title: '项目状态',
                    key: 'fProjectStatusName',
                    dataIndex: 'fProjectStatusName',
                },
                {
                    title: '项目经理',
                    key: 'fProjectManagerName',
                    dataIndex: 'fProjectManagerName',
                }
            ],
            userId: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,//获取用户id
            ProjectData: [],
            fProjectNum: "",//项目编号
            fProjectName: "",//项目名称
            fProjectStatus: "",//项目状态GUID
            fDepartmentID: "",//所属部门GUID
            fAreaID: "",//项目所属区域Guid
            fState: "",
            fProjectStatusData: [],
            fDepartmentData: [],
            fAreaData: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            }
        }
    },
    mounted() {
        console.log(this.userId)
        this._SearchMyProjectPaged()
        this._SelectDepartmentsParent()
        this._SelecttAreaType()
        this._SelecttProjectStatus()
    },
    methods: {
        handleChange2(value) {
            this.fDepartmentID = value === undefined ? "" : value
            console.log(this.fDepartmentID)
        },
        handleChange1(value) {
            this.fProjectStatus = value === undefined ? "" : value
            console.log(this.fProjectStatus)
        },
        handleChange(value) {
            this.fAreaID = value === undefined ? "" : value
            console.log(this.fAreaID)
        },
        handleTableChange(pagination) {
            console.log(pagination)
            this.pagination = pagination;
            this._SearchMyProjectPaged()
        },
        //部门
        _SelectDepartmentsParent() {
            SelectDepartmentsParent(this.userId).then((res) => {
                this.fDepartmentData = res.result
                // this.fDepartmentID=this.fDepartmentData[0].fDepartmentID
                console.log('部门', res.result)
            })
        },
        //区域
        _SelecttAreaType() {
            SelecttAreaType(this.userId).then((res) => {
                this.fAreaData = res.result
                // this.fAreaID=this.fAreaData[0].fBasicCodeID
                console.log('区域', res.result)
            })
        },
        //项目状态
        _SelecttProjectStatus() {
            SelecttProjectStatus(this.userId).then((res) => {
                this.fProjectStatusData = res.result
                // this.fProjectStatus=this.fProjectStatusData[0].fBasicCodeID
                console.log('项目状态', res.result)
            })
        },
        _SearchMyProjectPaged() {
            const params = {
                fProjectNum: this.fProjectNum,//项目编号
                fProjectName: this.fProjectName,//项目名称
                fProjectStatus: this.fProjectStatus === "" ? "00000000-0000-0000-0000-000000000000" : this.fProjectStatus,//项目状态GUID
                fDepartmentID: this.fDepartmentID === "" ? "00000000-0000-0000-0000-000000000000" : this.fDepartmentID,//所属部门GUID
                fAreaID: this.fAreaID === "" ? "00000000-0000-0000-0000-000000000000" : this.fAreaID,//项目所属区域Guid
                fProjectManager: this.userId //操作人
            }
            console.log(JSON.stringify(params))
            SearchMyProjectPaged(this.pagination, params).then((res) => {
                console.log(res)
                this.pagination.total = res.result.totalData
                this.ProjectData = res.result.objReturnInfo
            })
        },
        goProgressQuery(text, record) {
            console.log(text)
            console.log(record)
        }
    }
}
</script>

<style scoped lang="stylus">
#ProjectInformation
    width 100%
    height 100%

    .title
        width 100%
        border-bottom solid 1px #E9E9E9
        box-sizing border-box
        padding-bottom 16px

        span
            font-weight 500
            font-size 16px
            margin-left 25px
            color rgba(0, 0, 0, 0.85)

    .forms
        width 100%
        height 64px
        padding-left 24px
        box-sizing border-box
        display flex
        align-items center
        justify-content flex-start

    .tables
        padding 0 25px
        box-sizing border-box

/deep/ .components-input-demo-size .ant-input
    width 200px
    height 32px
    box-sizing border-box

/deep/ form .ant-select
    width 200px
    height 32px
    box-sizing border-box

/deep/ .ant-table-wrapper
    width 100%
</style>