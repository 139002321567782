import request from '../utils/request'

//'http://10.192.167.42:8084/api/UserInfo/SelecttEmployeesALL?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be'
export function SelecttEmployeesALL(params) {
    if (!params) {
        params = {
            UserID: ''
        }
    }
    const urlBitTxt = "/api/UserInfo/SelecttEmployeesALL?UserID=" + params.UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/UserInfo/SelecttEmployeesPaged?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&Page=1&PageNum=10'
export function SelecttEmployeesPaged(params) {
    if (!params) {
        params = {
            UserID: '',
            Page: 1,
            PageNum: 10
        }
    }
    const urlBitTxt = "/api/UserInfo/SelecttEmployeesPaged?UserID=" + params.UserID + "&Page=" + params.Page + "&PageNum=" + params.PageNum;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/UserInfo/SelecttEmployeesPaged?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&Page=1&PageNum=10'
export function SelecttEmployeesALLPaged(params) {
    if (!params) {
        params = {
            UserID: '',
            Page: 1,
            PageNum: 10
        }
    }
    const urlBitTxt = "/api/UserInfo/SelecttEmployeesALLPaged?UserID=" + params.UserID + "&Page=" + params.Page + "&PageNum=" + params.PageNum;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/BasicData/SelectDepartmentsParent?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be'
export function SelectDepartmentsParent(params) {
    if (!params) {
        params = {
            UserID: '',
        }
    }
    const urlBitTxt = "/api/BasicData/SelectDepartmentsParent?UserID=" + params.UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/BasicData/SelecttDepartmentsDynamic?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&WhereClause=%5BfState%5D%20%3D%27T%27&OrderField=%5BfOrderValue%5D'
export function SelecttDepartmentsDynamic(params) {
    if (!params) {
        params = {
            UserID: '',
            WhereClause: '[fState] =\'T\'',
            OrderField: '[fOrderValue]',
        }
    }
    const urlBitTxt = "/api/BasicData/SelecttDepartmentsDynamic?UserID=" + params.UserID + "&WhereClause=" + params.WhereClause + "&OrderField=" + params.OrderField;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/BasicData/SelectDepartments?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&Page=1&PageNum=10'
export function SelectDepartments(params) {
    if (!params) {
        params = {
            UserID: '',
            Page: 1,
            PageNum: 10,
            Data: {}
        }
    }
    const urlBitTxt = "/api/BasicData/SelectDepartments?UserID=" + params.UserID + "&Page=" + params.Page + "&PageNum=" + params.PageNum;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: params.Data
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/UserInfo/SelecttEmployeesByfDepartmentIDPaged?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&fDepartmentID=312f4ed1-71e3-45f2-abd8-cb50fdb09f3c&Page=1&PageNum=10'
export function SelecttEmployeesByfDepartmentIDPaged(params) {
    if (!params) {
        params = {
            UserID: '',
            fDepartmentID: '',
            Page: 1,
            PageNum: 10
        }
    }
    const urlBitTxt = "/api/UserInfo/SelecttEmployeesByfDepartmentIDPaged?UserID=" + params.UserID + "&fDepartmentID=" + params.fDepartmentID + "&Page=" + params.Page + "&PageNum=" + params.PageNum;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/UserInfo/DepartEmployeesPaged?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&Page=1&PageNum=10'
export function DepartEmployeesPaged(params) {
    if (!params) {
        params = {
            UserID: '',
            Page: 1,
            PageNum: 10
        }
    }
    const urlBitTxt = "/api/UserInfo/DepartEmployeesPaged?UserID=" + params.UserID + "&Page=" + params.Page + "&PageNum=" + params.PageNum;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/UserInfo/DepartEmployeesByfDepartmentIDPaged?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&fDepartmentID=9a01474e-ecd2-456b-a4f8-b48391c76d6c&Page=1&PageNum=10'
export function DepartEmployeesByfDepartmentIDPaged(params) {
    if (!params) {
        params = {
            UserID: '',
            fDepartmentID: '',
            Page: 1,
            PageNum: 10
        }
    }
    const urlBitTxt = "/api/UserInfo/DepartEmployeesByfDepartmentIDPaged?UserID=" + params.UserID + "&fDepartmentID=" + params.fDepartmentID + "&Page=" + params.Page + "&PageNum=" + params.PageNum;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/BasicData/SelecttStationsDynamic?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&WhereClause=%5BfState%5D%20%3D%27T%27&OrderField=%5BfOrderValue%5D'
export function SelecttStationsDynamic(params) {
    if (!params) {
        params = {
            UserID: '',
            WhereClause: '[fState] =\'T\'',
            OrderField: '[fOrderValue]',
        }
    }
    const urlBitTxt = "/api/BasicData/SelecttStationsDynamic?UserID=" + params.UserID + "&WhereClause=" + params.WhereClause + "&OrderField=" + params.OrderField;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/BasicData/SelectStations?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&Page=1&PageNum=99'
export function SelectStations(params) {
    if (!params) {
        params = {
            UserID: '',
            Page: 1,
            PageNum: 10,
            Data: {}
        }
    }
    const urlBitTxt = "/api/BasicData/SelectStations?UserID=" + params.UserID + "&Page=" + params.Page + "&PageNum=" + params.PageNum;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: params.Data
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/UserInfo/SelectEmployeesByfStationIDPaged?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&fStationID=2fbc896d-ac6a-4b96-847f-2cadaeed9d76&Page=1&PageNum=20'
export function SelectEmployeesByfStationIDPaged(params) {
    if (!params) {
        params = {
            UserID: '',
            fStationID: '',
            Page: 1,
            PageNum: 20
        }
    }
    const urlBitTxt = "/api/UserInfo/SelectEmployeesByfStationIDPaged?UserID=" + params.UserID + "&fStationID=" + params.fStationID + "&Page=" + params.Page + "&PageNum=" + params.PageNum;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/BasicData/SelecttCompaniesPaged?Page=1&PageNum=10'
export function SelecttCompaniesPaged(params) {
    if (!params) {
        params = {
            Page: 1,
            PageNum: 10
        }
    }
    const urlBitTxt = "/api/BasicData/SelecttCompaniesPaged?Page=" + params.Page + "&PageNum=" + params.PageNum;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/BasicData/SelecttBasicCodesChild?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&ParentID=8E3171AE-574B-40E8-9E2B-9B09F7302546&Status=T&OrderField=fOrderValue'
export function SelecttBasicCodesChild(params) {
    if (!params) {
        params = {
            UserID: '',
            ParentID: '8E3171AE-574B-40E8-9E2B-9B09F7302546',
            Status: 'T',
            OrderField: 'fOrderValue',
        }
    }
    const urlBitTxt = "/api/BasicData/SelecttBasicCodesChild?UserID=" + params.UserID + "&ParentID=" + params.ParentID + "&Status=" + params.Status + "&OrderField=" + params.OrderField;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/BasicData/InsertOrUpdatetDepartment'
export function InsertOrUpdatetEmployee(params) {
    if (!params) {
        params = {}
    }
    const urlBitTxt = "/api/UserInfo/InsertOrUpdatetEmployee";
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/BasicData/SelecttHourType?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be'
export function SelecttHourType(params) {
    if (!params) {
        params = {
            UserID: '',
        }
    }
    const urlBitTxt = "/api/BasicData/SelecttHourType?UserID=" + params.UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/BasicData/InsertOrUpdatetDepartment'
export function InsertOrUpdatetDepartment(params) {
    if (!params) {
        params = {}
    }
    const urlBitTxt = "/api/BasicData/InsertOrUpdatetDepartment";
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/SystemModule/GetStationModule?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&StationID=14e60ac8-21f8-4cde-b923-c62bd33aee65'
export function GetStationModule(params) {
    if (!params) {
        params = {
            UserID: '',
            StationID: ''
        }
    }
    const urlBitTxt = "/api/SystemModule/GetStationModule?UserID=" + params.UserID + "&StationID=" + params.StationID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//http://10.192.167.42:8084/api/UserInfo/ResetPassword?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&fEmpID=d4da4a18-aa17-42a7-b83e-d5399ebc9bb0&Password=Welcom1234
export function ResetPassword(params) {
    if (!params) {
        params = {
            UserID: '',
            fEmpID: ''
        }
    }
    const urlBitTxt = "/api/UserInfo/ResetPassword?UserID=" + params.UserID + "&fEmpID=" + params.fEmpID + "&Password=Welcom1234";
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//http://10.192.167.42:8084/api/UserInfo/DeletetEmployee?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&fEmployeeID=d4da4a18-aa17-42a7-b83e-d5399ebc9bb0
export function DeletetEmployee(params) {
    if (!params) {
        params = {
            UserID: '',
            fEmployeeID: ''
        }
    }
    const urlBitTxt = "/api/UserInfo/DeletetEmployee?UserID=" + params.UserID + "&fEmployeeID=" + params.fEmployeeID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/UserInfo/GetEmployee?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&Page=1&PageNum=10'
export function GetEmployee(params) {
    if (!params) {
        params = {
            UserID: '',
            Page: 1,
            PageNum: 10,
            Data: ''
        }
    }
    const urlBitTxt = "/api/UserInfo/GetEmployee?UserID=" + params.UserID + "&Page=" + params.Page + "&PageNum=" + params.PageNum;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: params.Data
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/BasicData/InsertOrUpdatetStation?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e'
export function InsertOrUpdatetStation(params) {
    if (!params) {
        params = {
            UserID: '',
            Data: {}
        }
    }
    const urlBitTxt = "/api/BasicData/InsertOrUpdatetStation?UserID=" + params.UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: params.Data
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/UserInfo/UpdatePassword?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&OldPassword=12&NewPassword=12'
export function UpdatePassword(params) {
    if (!params) {
        params = {
            UserID: '',
            OldPassword: '',
            NewPassword: ''
        }
    }
    const urlBitTxt = "/api/UserInfo/UpdatePassword?UserID=" + params.UserID + "&OldPassword=" + params.OldPassword + "&NewPassword=" + params.NewPassword;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/UserInfo/ForgotPassword?fUserNum=ziqi.he&Email=ziqi.he%40bocowinz.com'
export function ForgotPassword(params) {
    if (!params) {
        params = {
            fUserNum: '',
            Email: '',
        }
    }
    const urlBitTxt = "/api/UserInfo/ForgotPassword?fUserNum=" + params.fUserNum + "&Email=" + params.Email;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/UserInfo/EmailUpdatePassword?LicenseKey=MjA3NGI2MDMtMzcxZS00MGE2LThkNDAtNWM1MWM3Y2I1Zjg0&RandomCode=XD6jSb&Password=1'
export function EmailUpdatePassword(params) {
    if (!params) {
        params = {
            LicenseKey: '',
            RandomCode: '',
            Password: ''
        }
    }
    const urlBitTxt = "/api/UserInfo/EmailUpdatePassword?LicenseKey=" + params.LicenseKey + "&RandomCode=" + params.RandomCode + "&Password=" + params.Password;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}