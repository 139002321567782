<template>
    <div id="WBSDecomposition">
        <a-modal :footer="null" :visible="ifWBS" title="WBS分解" @ok="Ok" @cancel="Cancel" width="90%">
            <a-table
                :scroll="{ y: 490 }"
                :pagination="false"
                :columns="columns"
                :data-source="data"
                :expanded-row-keys.sync="expandedRowKeys"
            >
                <div slot="fTaskStatus" slot-scope="fTaskStatus">
                    <div :key="index" v-for="(item,index) in fTaskStatusData">
                        <span v-if="item.fBasicCodeID === fTaskStatus">{{ item.fCodeName }}</span>
                    </div>
                </div>
                <span slot="fTaskPercent" slot-scope="fTaskPercent">
                    {{ fTaskPercent + "%" }}
                </span>
                <template
                    v-for="col in ['fStartDate','fEndDate','fActualStartDate','fActualEndDate','fTaskWeight']"
                    :slot="col"
                    slot-scope="text, record"
                >
                    <div :key="col">
                        <a-input
                            suffix="%"
                            v-if="record.editable&&col==='fTaskWeight'"
                            :value="text"
                            oninput="if(value>100)value=100;if(value.length>3)value=value.slice(0,3);if(value<0)value=0;value=value.replace(/^(0+)|[^\d]+/g,'')"
                            @change="e => ChangeText(e.target.value, record.key, col)"
                        />
                        <a-date-picker
                            v-else-if="record.editable&&(col==='fStartDate'||col==='fEndDate'||col==='fActualStartDate'||col==='fActualEndDate')"
                            :default-value="text"
                            :format="'YYYY-MM-DD'"
                            @change="e => ChangeDates(e, record.key, col)"/>
                        <template v-else-if="!record.editable&&col==='fTaskWeight'">
                            {{ text + "%" }}
                        </template>
                        <template
                            v-else-if="!record.editable&&(col==='fStartDate'||col==='fEndDate'||col==='fActualStartDate'||col==='fActualEndDate')">
                            {{ text }}
                        </template>
                    </div>
                </template>
                <template slot="operation" slot-scope="text, record, index">
                    <div class="editable-row-operations">
                        <span v-if="record.editable">
                          <a @click="() => save(record.key)">保存</a>
                          <a-popconfirm title="确定要取消?" @confirm="() => cancel(record.key)">
                            <a>取消</a>
                          </a-popconfirm>
                        </span>
                        <span v-else>
                          <a :disabled="editingKey !== ''" @click="() => edit(record.key,index)">编辑</a>
                        </span>
                    </div>
                </template>
                <span slot="participant" slot-scope="participant">
                    <span v-if="!participant.children" style="color: #ED6D01;cursor: pointer;"
                          @click="editor(participant)">参与人</span>
                </span>
            </a-table>
            <div>
                <editor :ifEditor.sync="ifEditor" :editorData="editorData" :subKeyObj="subKeyObj"
                        :_GetProjectTaskUserList="_GetProjectTaskUserList"></editor>
            </div>
        </a-modal>
    </div>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll';
import editor from "../../components/WBS/editor";
import {SelecttBasicCodesChild, GetProjectTaskUserList, EditWorkBreakdownStructure} from "../../api/projects"

export default {
    props: {
        ifWBS: Boolean,
        WBSData: {
            type: Array,
            required: true
        }
    },
    components: {editor},
    directives: {infiniteScroll},
    name: "WBSDecomposition",
    data() {
        this.cacheData = this.WBSData.map(item => ({...item}));
        return {
            data: this.WBSData,
            editingKey: '',
            ifEditor: false,
            ifAddIcon: true,
            busy: false,
            visibleWBS: false,
            columns: [
                {
                    title: '任务名称',
                    dataIndex: 'fTaskName',
                    key: 'fTaskName',
                    width: '200px'
                },
                {
                    title: '任务编码',
                    dataIndex: 'fCode',
                    key: 'fCode',
                },
                {
                    title: '计划开始日期',
                    dataIndex: 'fStartDate',
                    key: 'fStartDate',
                    scopedSlots: {customRender: 'fStartDate'},
                    width: '160px'
                },
                {
                    title: '计划完成日期',
                    dataIndex: 'fEndDate',
                    key: 'fEndDate',
                    scopedSlots: {customRender: 'fEndDate'},
                    width: '160px'
                },
                {
                    title: '实际开始日期',
                    dataIndex: 'fActualStartDate',
                    key: 'fActualStartDate',
                    scopedSlots: {customRender: 'fActualStartDate'},
                    width: '160px'
                },
                {
                    title: '实际完成日期',
                    dataIndex: 'fActualEndDate',
                    key: 'fActualEndDate',
                    scopedSlots: {customRender: 'fActualEndDate'},
                    width: '160px'
                },
                {
                    title: '工作日',
                    dataIndex: 'fWorkDays',
                    key: 'fWorkDays',
                },
                {
                    title: '权重(总计100%)',
                    dataIndex: 'fTaskWeight',
                    key: 'fTaskWeight',
                    width: '100px',
                    scopedSlots: {customRender: 'fTaskWeight'},
                },
                {
                    title: '进度',
                    dataIndex: 'fTaskPercent',
                    key: 'fTaskPercent',
                    scopedSlots: {customRender: 'fTaskPercent'},
                },
                {
                    title: '参与人',
                    key: 'participant',
                    scopedSlots: {customRender: 'participant'},
                },
                {
                    title: '任务状态',
                    dataIndex: 'fTaskStatus',
                    key: 'fTaskStatus',
                    scopedSlots: {customRender: 'fTaskStatus'},
                },
                {
                    title: '操作',
                    key: 'operation',
                    scopedSlots: {customRender: 'operation'},
                    width: '100px',
                }
            ],
            expandedRowKeys: [],
            fTaskStatusData: [],
            editorData: [],
            subKeyObj: {},
        }
    },
    watch: {
        WBSData() {
            this.data = this.WBSData
            this.cacheData = this.WBSData
        }
    },
    mounted() {
        this._SelecttBasicCodesChild()
    },
    methods: {
        //修改date数据
        ChangeDates(value, key, column) {
            console.log(value, key, column)
            if (key.split('-').length === 1) {
                const newData = [...this.data];
                const target = newData.find(item => key === item.key);
                console.log(target)
                if (target) {
                    target[column] = value !== null ? this.$moment(value._d).format('YYYY-MM-DD') : null;
                    this.data = newData;
                }
            } else if (key.split('-').length === 2) {
                const newData = [...this.data];
                let target
                for (let i of newData) {
                    for (let j of i.children) {
                        if (j.key === key) {
                            target = j
                        }
                    }
                }
                console.log(target)
                if (target) {
                    target[column] = value !== null ? this.$moment(value._d).format('YYYY-MM-DD') : null;
                    this.data = newData;
                }
            }
        },
        //修改input数据
        ChangeText(value, key, column) {
            console.log(value, key, column)
            if (key.split('-').length === 1) {
                const newData = [...this.data];
                const target = newData.find(item => key === item.key);
                console.log(target)
                if (target) {
                    target[column] = parseInt(value);
                    let num=0;
                    let n=0;
                    for(let i in newData){
                        num=num+parseInt(newData[i][column])
                        if(newData[i].key!==key){
                          n=n+parseInt(newData[i][column])
                        }
                      console.log(i,newData[i][column])
                    }
                  console.log("n",n)
                  console.log("num",num)
                    if(num>100){
                        this.$message.warning('权重不可大于100%');
                        target[column] = 100 - n;
                        this.data = newData;
                    }else{
                        this.data = newData;
                    }
                }
            } else if (key.split('-').length === 2) {
                const newData = [...this.data];
                let target
                for (let i of newData) {
                    for (let j of i.children) {
                        if (j.key === key) {
                            target = j
                        }
                    }
                }
                console.log(target)
                if (target) {
                    target[column] = parseInt(value);
                    let num=0;
                    let n=0;
                    for(let i in newData){
                        for (let j in i.children) {
                            num=num+parseInt(i.children[j][column])
                            if(i.children[j].key!==key){
                                n=n+parseInt(i.children[j][column])
                            }
                            console.log(j,i.children[j][column])
                        }

                    }
                    if(num>100){
                        this.$message.warning('权重不可大于100%');
                        target[column] = 100 - n;
                        this.data = newData;
                    }else{
                        this.data = newData;
                    }
                }
            }
        },
        //修改
        edit(key) {
            if (key.split('-').length === 1) {
                const newData = [...this.data];
                const target = newData.find(item => key === item.key);
                this.editingKey = key;
                if (target) {
                    target.editable = true;
                    this.data = newData;
                }
            } else if (key.split('-').length === 2) {
                const newData = [...this.data];
                let target
                for (let i of newData) {
                    for (let j of i.children) {
                        if (j.key === key) {
                            target = j
                        }
                    }
                }
                if (target) {
                    target.editable = true;
                    this.data = newData;
                }
            }
        },
        //保存
        save(key) {
            console.log(key)
            const params = {
                UserID:JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID
            }
            if (key.split('-').length === 1) {
                const newData = [...this.data];
                const newCacheData = [...this.cacheData];
                const target = newData.find(item => key === item.key);
                const targetCache = newCacheData.find(item => key === item.key);
                if (target && targetCache) {
                    console.log("target", target)
                  console.log("this.data",this.data)
                  console.log(JSON.stringify(this.data))
                  EditWorkBreakdownStructure(params,this.data).then((res)=>{
                        console.log(res)
                    })
                    delete target.editable;
                    this.data = newData;
                    Object.assign(targetCache, target);
                    this.cacheData = newCacheData;
                }
                this.editingKey = '';
            } else if (key.split('-').length === 2) {
                const newData = [...this.data];
                const newCacheData = [...this.cacheData];
                let target
                let data
                for (let i of newData) {
                    for (let j of i.children) {
                        if (j.key === key) {
                            data = i
                            target = j
                        }
                    }
                }
                let targetCache
                for (let i of newCacheData) {
                    for (let j of i.children) {
                        if (j.key === key) {
                            targetCache = j
                        }
                    }
                }
                console.log("target1", target)
                console.log("data", data)
              console.log("this.data",this.data)
              console.log(JSON.stringify(this.data))

              if (target && targetCache) {
                  EditWorkBreakdownStructure(params,this.data).then((res)=>{
                        console.log(res)
                    })
                    delete target.editable;
                    this.data = newData;
                    Object.assign(targetCache, target);
                    this.cacheData = newCacheData;
                }
                this.editingKey = '';
            }
        },
        //取消
        cancel(key) {
            if (key.split('-').length === 1) {
                const newData = [...this.data];
                const target = newData.find(item => key === item.key);
                this.editingKey = '';
                if (target) {
                    Object.assign(target, this.cacheData.find(item => key === item.key));
                    delete target.editable;
                    this.data = newData;
                }
            } else if (key.split('-').length === 2) {
                const newData = [...this.data];
                let target
                for (let i of newData) {
                    for (let j of i.children) {
                        if (j.key === key) {
                            target = j
                        }
                    }
                }
                let targetCache
                for (let i of this.cacheData) {
                    for (let j of i.children) {
                        if (j.key === key) {
                            targetCache = j
                        }
                    }
                }
                this.editingKey = '';
                if (target) {
                    Object.assign(target, targetCache);
                    console.log("target1", target)
                    delete target.editable;
                    this.data = newData;
                }
            }
        },
        //任务状态
        _SelecttBasicCodesChild() {
            const params = {
                UserID: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
                ParentID: "31B05145-196E-40ED-B60D-AF2253A3E771",
                Status: "T",
                OrderField: "fOrderValue",
            }
            SelecttBasicCodesChild(params).then((res) => {
                this.fTaskStatusData = res.result
                console.log(res.result)
            })
        },
        Ok() {
            this.$emit("update:ifWBS", false)
        },
        Cancel() {
            this.$emit("update:ifWBS", false)
        },
        addWBS() {
            this.visibleWBS = true
        },
        _GetProjectTaskUserList(obj) {
            const params = {
                UserID: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
                TaskID: obj.fProjectTaskID
            }
            GetProjectTaskUserList(params).then((res) => {
                console.log("this.editorData", res)
                this.editorData = []
                if (res.result.objReturnInfo !== null) {
                    this.editorData = res.result.objReturnInfo
                }
            })
        },
        //打开任务参与人详情界面，并调用接口获取某子项目的所有参与人
        editor(obj) {
            console.log(obj)
            this.subKeyObj = obj
            this.ifEditor = true
            this._GetProjectTaskUserList(obj)
        }
    }
}
</script>

<style scoped lang="stylus">
#WBSDecomposition
    width 100%
    height 100%

.editable-row-operations a
    margin-right 8px
</style>