<template>
    <div id="tasks">
        <div class="title">
            <i class="el-icon-document"></i>
            <span>我的任务</span>
        </div>
        <div class="tab">
            <el-tabs type="border-card">
                <!--                <el-tab-pane label="我管理的任务">我管理的任务</el-tab-pane>-->
                <el-tab-pane label="我参与的任务">
                    <div v-show="condition" style="display: flex;flex-direction: row;flex-wrap: wrap">
                        <el-form :model="formInline" label-width="97px">
                            <el-form-item label="所属项目">
                                <el-input
                                    style="width: 150px"
                                    size="mini"
                                    placeholder="请输入内容"
                                    v-model="input"
                                    clearable>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="任务负责人">
                                <el-input
                                    style="width: 150px"
                                    size="mini"
                                    placeholder="请输入内容"
                                    v-model="input"
                                    clearable>
                                </el-input>
                            </el-form-item>
                        </el-form>
                        <el-form :model="formInline" label-width="80px">
                            <el-form-item label="任务名称">
                                <el-input
                                    style="width: 150px"
                                    size="mini"
                                    placeholder="请输入内容"
                                    v-model="input"
                                    clearable>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="里程碑">
                                <el-checkbox v-model="formInline.checked"></el-checkbox>
                            </el-form-item>
                        </el-form>
                        <el-form :model="formInline" label-width="80px">
                            <el-form-item label="任务编码">
                                <el-input
                                    style="width: 150px"
                                    size="mini"
                                    placeholder="请输入内容"
                                    v-model="input"
                                    clearable>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="任务状态">
                                <el-select style="width: 150px" v-model="formInline.region" size="mini"
                                           placeholder="任务状态">
                                    <el-option label="不指定" value=""></el-option>
                                    <el-option label="未开始" value="v"></el-option>
                                    <el-option label="进行中" value="a"></el-option>
                                    <el-option label="已完成" value="b"></el-option>
                                    <el-option label="延期完成" value="g"></el-option>
                                    <el-option label="已暂停" value="s"></el-option>
                                    <el-option label="已取消" value="w"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                        <el-form-item label="计划开始时间">
                            <el-date-picker
                                style="width: 150px"
                                v-model="startData"
                                size="mini"
                                type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="计划结束时间">
                            <el-date-picker
                                style="width: 150px"
                                v-model="endData"
                                size="mini"
                                type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="日期范围">
                            <el-select style="width: 150px" v-model="formInline.region" size="mini" placeholder="日期范围">
                                <el-option label="请选择" value="v"></el-option>
                                <el-option label="去年" value="a"></el-option>
                                <el-option label="上季度" value="b"></el-option>
                                <el-option label="上2月" value="g"></el-option>
                                <el-option label="上月" value="s"></el-option>
                                <el-option label="上2周" value="w"></el-option>
                                <el-option label="上周" value="e"></el-option>
                                <el-option label="今年" value="r"></el-option>
                                <el-option label="本季度" value="t"></el-option>
                                <el-option label="本月" value="y"></el-option>
                                <el-option label="本周" value="u"></el-option>
                                <el-option label="下季度" value="i"></el-option>
                                <el-option label="下月" value="o"></el-option>
                                <el-option label="下2月" value="p"></el-option>
                                <el-option label="下周" value="z"></el-option>
                                <el-option label="下2周" value="x"></el-option>
                                <el-option label="截止当前" value="c"></el-option>
                                <el-option label="不指定" value=""></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="mini" @click="onSubmit">查询</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="info" size="mini" @click="onSubmit">重置</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-show="!condition" type="info" size="mini" @click="onCondition">显示更多条件
                            </el-button>
                            <el-button v-show="condition" type="info" size="mini" @click="onCondition">显示更少条件
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <el-table
                        size="mini"
                        :data="tableData"
                        style="width: 100%;">
                        <el-table-column
                            prop="name"
                            label="项目编码">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="项目名称">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="任务编码">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="任务名称">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="计划开始日期">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="计划完成日期">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="实际开始日期">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="实际完成日期">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="工作日">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="权重">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="进度">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="负责人">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="任务状态">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="里程碑">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="工作内容描述">
                        </el-table-column>
                    </el-table>
                    <div class="block">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage4"
                            :page-sizes="[10, 200, 300, 400]"
                            :page-size="100"
                            layout="total,prev, pager, next, jumper"
                            :total="400">
                        </el-pagination>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
export default {
    name: "tasks",
    data() {
        return {
            condition: false,
            input: "",
            currentPage4: 1,
            formInline: {
                user: '',
                region: '',
                formInline: false
            },
            startData: '',
            endData: '',
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }]
        }
    },
    mounted() {
     /*   let user = JSON.parse(localStorage.getItem("AdminUser"))
        console.log(user.fEmployeeID)*/
    },
    methods: {
        onCondition() {
            this.condition = !this.condition
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        onSubmit() {
            console.log('submit!');
        }
    }
}
</script>

<style scoped lang="stylus">
#tasks
    width 100%
    height 100%

    .title
        width 100%
        height 25px
        border-bottom solid 2px #6694E3
        padding-left 10px
        box-sizing border-box

        span
            font-weight bold
            font-size 14px
            margin-left 3px

    .tab
        width 100%
        height calc(100% - 33px)
        box-sizing border-box
        margin-top 6px

        .block
            width 100%
            margin-top 10px
            display flex
            justify-content flex-end
            align-items center


.el-pagination__total {
    margin-left: 10px !important
}

.el-tabs--border-card {
    //height: 98%
}

.el-table__header {
    width: 100% !important
}

.el-form-item {
    margin: 0;
}


</style>