<template>
  <div class="emp-list">
    <div class="title">
      <span>员工列表</span>
    </div>
    <a-divider />
    <div class="people-list-action">
      <div class="people-list-select">
        <div class="people-list-select-item">
          <span class="people-list-select-item-text">人员姓名：</span>
          <a-input
            style="width: 193px; height: 30px; margin-left: 10px"
            v-model="searchName"
            placeholder="请输入人员姓名"
            allow-clear
          />
        </div>
        <div class="people-list-select-item">
          <span class="people-list-select-item-text">人员职位：</span>
          <a-select
            placeholder="请选择"
            :value="searchStation"
            @change="searchStationChange"
            style="width: 193px; height: 30px; margin-left: 10px"
            allow-clear
          >
            <a-select-option v-for="p in positiondata" :key="p.fStationID">
              {{ p.fStationName }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="people-list-select">
        <div class="people-list-select-item">
          <span class="people-list-select-item-text">手机号：</span>
          <a-input
            style="width: 193px; height: 30px; margin-left: 10px"
            v-model="searchPhone"
            placeholder="请输入手机号"
            allow-clear
          />
        </div>
        <div class="people-list-select-item">
          <span class="people-list-select-item-text">所属部门：</span>
          <a-select
            placeholder="请选择"
            :value="searchDepartment"
            @change="searchDepartmentChange"
            style="width: 193px; height: 30px; margin-left: 10px"
            allow-clear
          >
            <a-select-option v-for="d in departmentdata" :key="d.fDepartmentID">
              {{ d.fDepartmentName }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="people-list-select">
        <div class="people-list-select-item">
          <span class="people-list-select-item-text">人员卡号：</span>
          <a-input
            style="width: 193px; height: 30px; margin-left: 10px"
            v-model="searchIDCard"
            placeholder="请输入人员卡号"
            allow-clear
          />
        </div>
        <div class="people-list-select-item">
          <span class="people-list-select-item-text">员工状态：</span>
          <a-select
            placeholder="请选择"
            :value="searchState"
            @change="searchStateChange"
            style="width: 193px; height: 30px; margin-left: 10px"
          >
            <a-select-option value="T">在职</a-select-option>
            <a-select-option value="F">离职</a-select-option>
          </a-select>
        </div>
      </div>
      <div class="people-list-button1">
        <a-button type="primary" @click="onSearch">搜索</a-button>
      </div>
    </div>
    <div class="people-list-button2">
      <a-button type="primary" @click="showAddModal">新增人员</a-button>
      <!-- <a-button @click="deletePeople">批量删除</a-button> -->
    </div>
    <div class="people-list-list">
      <a-table
        :rowKey="(record) => record.fUserName"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ y: scrollY }"
        @change="handleTableChange"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="showEditModal(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="是否重置该员工密码"
            ok-text="是"
            cancel-text="否"
            @confirm="resetPassword(record)"
          >
            <a>重置密码</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-popconfirm
            title="是否将该员工离职"
            ok-text="是"
            cancel-text="否"
            @confirm="deleteEmployee(record)"
          >
            <a>离职</a>
          </a-popconfirm>
        </span>
      </a-table>
      <employee-edit
        :Visible.sync="editModalVisible"
        :EmpItemData="employeeItemData"
      />
      <employee-add :Visible.sync="addModalVisible" />
    </div>
  </div>
</template>
  
<script>
import {
  SelecttEmployeesPaged,
  SelecttDepartmentsDynamic,
  SelecttStationsDynamic,
  SelecttEmployeesByfDepartmentIDPaged,
  SelectEmployeesByfStationIDPaged,
  DepartEmployeesPaged,
  DepartEmployeesByfDepartmentIDPaged,
  ResetPassword,
  DeletetEmployee,
  GetEmployee,
} from "../../api/Employee.js";
import EmployeeAdd from "../../components/employee/EmployeeAdd.vue";
import EmployeeEdit from "../../components/employee/EmployeeEdit.vue";
const columns = [
  {
    title: "用户名",
    dataIndex: "fUserName",
  },
  {
    title: "姓名",
    dataIndex: "fChineseName",
  },
  {
    title: "部门",
    dataIndex: "fDepartmentName",
  },
  {
    title: "职务",
    dataIndex: "fStationName",
  },
  {
    title: "汇报人",
    dataIndex: "fManagerNameCN",
  },
  {
    title: "手机号",
    dataIndex: "fMobilePhone",
  },
  {
    title: "人员类别",
    dataIndex: "fUserTypeName",
  },
  {
    title: "员工状态",
    dataIndex: "state",
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "18%",
  },
];

export default {
  components: {
    EmployeeAdd,
    EmployeeEdit,
  },
  data() {
    return {
      data: [],
      pagination: {},
      columns,
      departmentdata: [],
      positiondata: [],
      searchName: undefined,
      searchStation: undefined,
      searchPhone: undefined,
      searchDepartment: undefined,
      searchIDCard: undefined,
      searchState: "T",
      loading: false,
      scrollY: "",
      editModalVisible: false,
      addModalVisible: false,
      employeeItemData: [],
    };
  },
  computed: {},
  beforeUpdate() {
    var that = this;
    this.$nextTick(() => {
      that.scrollY = this.getTableScroll();
      //console.log(that.scrollY);
      setTimeout(() => {
        that.checkScrollBar();
      });
    });
  },
  mounted() {
    var that = this;
    this.searchPeopleList({}, {});
    this.getDepartmentList();
    this.getPosList();
    //监听事件
    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          that.scrollY = this.getTableScroll();
          that.checkScrollBar();
          console.log(that.scrollY);
        });
      })();
    };
  },
  methods: {
    handleTableChange(pagination) {
      console.log(pagination);
      //const user=JSON.parse(localStorage.getItem("AdminUser"));
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      let data = {
        fChineseName: this.searchName,
        fUserName: this.searchIDCard,
        fMobilePhone: this.searchPhone,
        fDepartmentID: this.searchDepartment,
        fStationID: this.searchStation,
        fState: this.searchState,
      };
      this.searchPeopleList(
        {
          Page: pagination.current,
          PageNum: pagination.pageSize,
        },
        data
      );
      // if (this.searchState == "在职") {
      //   if (this.searchDepartment) {
      //     this.getPeopleListByDepartment({
      //       Page: pagination.current,
      //       PageNum: pagination.pageSize,
      //     });
      //   } else if (this.searchStation) {
      //     this.getPeopleListByPosition({
      //       Page: pagination.current,
      //       PageNum: pagination.pageSize,
      //     });
      //   } else {
      //     this.getPeopleList({
      //       Page: pagination.current,
      //       PageNum: pagination.pageSize,
      //     });
      //   }
      // } else if (this.searchState == "离职") {
      //   if (this.searchDepartment) {
      //     this.getDepartPeopleListByDepartment({
      //       Page: pagination.current,
      //       PageNum: pagination.pageSize,
      //     });
      //   } else {
      //     this.getDepartPeopleList({
      //       Page: pagination.current,
      //       PageNum: pagination.pageSize,
      //     });
      //   }
      // }
    },
    getPeopleList(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      this.loading = true;
      SelecttEmployeesPaged({
        UserID: user.fEmployeeID,
        Page: 1,
        PageNum: 10,
        ...param,
      }).then((res) => {
        console.log(res);
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = 90;
        this.loading = false;
        this.data = res.result;
        this.pagination = pagination;
      });
    },
    getDepartPeopleList(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      this.loading = true;
      DepartEmployeesPaged({
        UserID: user.fEmployeeID,
        Page: 1,
        PageNum: 10,
        ...param,
      }).then((res) => {
        console.log(res);
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = 330;
        this.loading = false;
        this.data = res.result;
        this.pagination = pagination;
      });
    },
    getPeopleListByDepartment(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      this.loading = true;
      SelecttEmployeesByfDepartmentIDPaged({
        UserID: user.fEmployeeID,
        fDepartmentID: this.searchDepartment,
        Page: 1,
        PageNum: 10,
        ...param,
      }).then((res) => {
        console.log(res);
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = 20;
        this.loading = false;
        this.data = res.result;
        this.pagination = pagination;
      });
    },
    getDepartPeopleListByDepartment(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      this.loading = true;
      DepartEmployeesByfDepartmentIDPaged({
        UserID: user.fEmployeeID,
        fDepartmentID: this.searchDepartment,
        Page: 1,
        PageNum: 10,
        ...param,
      }).then((res) => {
        console.log(res);
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = 20;
        this.loading = false;
        this.data = res.result;
        this.pagination = pagination;
      });
    },
    getPeopleListByPosition(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      this.loading = true;
      SelectEmployeesByfStationIDPaged({
        UserID: user.fEmployeeID,
        fStationID: this.searchStation,
        Page: 1,
        PageNum: 10,
        ...param,
      }).then((res) => {
        console.log(res);
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = 10;
        this.loading = false;
        this.data = res.result;
        this.pagination = pagination;
      });
    },
    getDepartmentList(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      SelecttDepartmentsDynamic({
        UserID: user.fEmployeeID,
        WhereClause: "[fState] ='T'",
        OrderField: "[fOrderValue]",
        ...param,
      }).then((res) => {
        console.log(res);
        this.departmentdata = res.result;
      });
    },
    getPosList(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      SelecttStationsDynamic({
        UserID: user.fEmployeeID,
        WhereClause: "[fState] ='T'",
        OrderField: "[fOrderValue]",
        ...param,
      }).then((res) => {
        console.log(res);
        this.positiondata = res.result;
      });
    },
    searchStationChange(value) {
      this.searchStation = value;
    },
    searchDepartmentChange(value) {
      this.searchDepartment = value;
    },
    searchStateChange(value) {
      this.searchState = value;
      this.searchDepartment = undefined;
      this.searchStation = undefined;
    },
    searchPeopleList(param, data) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      this.loading = true;
      GetEmployee({
        UserID: user.fEmployeeID,
        Page: 1,
        PageNum: 10,
        Data: data,
        ...param,
      }).then((res) => {
        console.log(res);
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = res.result.totalData;
        this.loading = false;
        this.data = res.result.objReturnInfo;
        this.pagination = pagination;
      });
    },
    onSearch() {
      console.log(
        this.searchName,
        this.searchStation,
        this.searchPhone,
        this.searchDepartment,
        this.searchIDCard,
        this.searchState
      );
      let data = {
        fChineseName: this.searchName,
        fUserName: this.searchIDCard,
        fMobilePhone: this.searchPhone,
        fDepartmentID: this.searchDepartment,
        fStationID: this.searchStation,
        fState: this.searchState,
      };
      this.searchPeopleList({}, data);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    showEditModal(data) {
      console.log(data);
      this.employeeItemData = { ...data };
      this.editModalVisible = true;
    },
    showAddModal() {
      this.addModalVisible = true;
    },
    resetPassword(data) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      ResetPassword({
        UserID: user.fEmployeeID,
        fEmpID: data.fEmployeeID,
      }).then((res) => {
        console.log(res);
      });
    },
    deleteEmployee(data) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      console.log(data, user.fEmployeeID);
      DeletetEmployee({
        UserID: user.fEmployeeID,
        fEmployeeID: data.fEmployeeID,
      }).then((res) => {
        console.log(res);
      });
    },
    getTableScroll(extraHeight, id) {
      // 获取表格滚动高度
      if (typeof extraHeight == "undefined") {
        //  默认底部分页32 + 边距32
        extraHeight = 84;
      }
      let tHeader = null;
      if (id) {
        tHeader = document.getElementById(id)
          ? document
              .getElementById(id)
              .getElementsByClassName("ant-table-thead")[0]
          : null;
      } else {
        tHeader = document.getElementsByClassName("ant-table-thead")[0];
      }
      // 表格内容距离顶部的距离
      let tHeaderBottom = 0;
      if (tHeader) {
        tHeaderBottom = tHeader.getBoundingClientRect().bottom;
      }
      // 窗体高度-表格内容顶部的高度-表格内容底部的高度
      let height = document.body.clientHeight - tHeaderBottom - extraHeight;
      //let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
      return height;
    },
    checkScrollBar() {
      let antTableBody = document.querySelector(".ant-table-body");
      let antTableBodyScrollHeight = antTableBody && antTableBody.scrollHeight;
      let antTableBodyClientHeight = antTableBody && antTableBody.clientHeight;
      let antTableFixHeader = document.querySelector(
        ".ant-table-hide-scrollbar"
      );
      //console.log("xxxxx", antTableBodyScrollHeight, antTableBodyClientHeight);
      if (antTableBodyScrollHeight <= antTableBodyClientHeight) {
        antTableFixHeader &&
          antTableFixHeader.classList.add("change-scrollBar");
      } else {
        antTableFixHeader &&
          antTableFixHeader.classList.remove("change-scrollBar");
      }
    },
  },
};
</script>
  
<style scoped lang="stylus">
.emp-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 800;
  line-height: 24px;

  span {
    font-weight: 500;
    font-size: 16px;
    margin-left: 25px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.people-list-action {
  margin: 10px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.people-list-button2 {
  margin: 0 16px;
}

.people-list-list {
  margin: 16px;
}

.people-list-select {
  display: flex;
  flex-direction: column;
}

.people-list-select-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  margin-right: 35px;
}

.people-list-select-item:first-child {
  margin-bottom: 10px;
}

.people-list-select-item-text {
  font-family: 'Arial Normal', 'Arial';
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: #797979;
}

.people-list-button1 {
  display: flex;
  flex-direction: row;
}

.ant-btn {
  margin-left: 10px;
}

.ant-btn:first-child {
  margin-left: 0;
}
</style>