import request from '../utils/request'
//'http://10.192.167.42:8084/api/SystemModule/GetSystemModuleSubset?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&ModuleType=f6998a68-e4da-433c-9624-6f7dae16ffa7'
export function GetSystemModuleSubset(params) {
    if (!params) {
        params = {
            UserID: '',
            ModuleType:''
        }
    }
    const urlBitTxt = "/api/SystemModule/GetSystemModuleSubset?UserID=" + params.UserID+"&ModuleType=" + params.ModuleType;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/SystemModule/GetModuleALL?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be'
export function GetModuleALL(params) {
    if (!params) {
        params = {
            UserID: '',
        }
    }
    const urlBitTxt = "/api/SystemModule/GetModuleALL?UserID=" + params.UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/SystemModule/InsertOrUpdatetSystemModuleRights?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be'
export function InsertOrUpdatetSystemModuleRights(UserID, params) {
    if (!UserID) {
        UserID = {
            UserID: '00000000-0000-0000-0000-000000000000',
        }
    }
    if (!params) {
        params = {}
    }
    const urlBitTxt = "/api/SystemModule/InsertOrUpdatetSystemModuleRights?UserID=" + UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/SystemModule/DeletetSystemModuleRights?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&fSystemModuleID=a47549d9-e756-4d06-9204-501f0e247d13&pfState=F'
export function DeletetSystemModuleRights(params) {
    if (!params) {
        params = {
            UserID:'',
            fSystemModuleID:'',
            pfState:'',
        }
    }
    const urlBitTxt = "/api/SystemModule/DeletetSystemModuleRights?UserID=" + params.UserID+"&fSystemModuleID="+params.fSystemModuleID+"&pfState="+params.pfState;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//http://10.192.167.42:8084/api/SystemModule/GetGuid
export function GetGuid() {
    const urlBitTxt = "/api/SystemModule/GetGuid";
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET',
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}