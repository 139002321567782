<template>
    <div class="role-list">
        <div class="title">
            <span>权限管理</span>
        </div>
        <a-divider/>
        <div class="role-list-action">
            <div class="role-list-select">
                <div class="role-list-select-item">
                    <span class="role-list-select-item-text">人员职位：</span>
                    <a-select
                        allowClear
                        placeholder="请选择"
                        :value="StationID"
                        @change="searchStationChange"
                        style="width: 193px; height: 30px; margin-left: 10px"
                    >
                        <a-select-option v-for="p in positionData" :key="p.fStationID">
                            {{ p.fStationName }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="role-list-button1">
                <a-button type="primary" @click="showRoleListAdd">编辑权限</a-button>
            </div>
        </div>
        <div class="role-list-list">
            <a-table
                :rowKey="(record,index) => index"
                :columns="columns"
                :data-source="roleData"
                :loading="loading"
                :scroll="{ y: scrollY }"
            >
            </a-table>
        </div>
        <role-list-add v-if="addShow" :addShow.sync="addShow" :StationID="StationID"></role-list-add>
    </div>
</template>

<script>
import {GetStationModuleALL, SelecttStationsDynamic} from "../../api/RoleList";
import RoleListAdd from "../../components/RoleList/RoleListAdd";

export default {
    components: {
        RoleListAdd
    },
    data() {
        return {
            columns: [
                {
                    title: "模块名称",
                    dataIndex: "fModuleName",
                },
                {
                    title: "模块编号",
                    dataIndex: "fModuleNo",
                },
                {
                    title: "链接",
                    dataIndex: "fLink",
                },
                {
                    title: "状态",
                    dataIndex: "showCloseState",
                },
            ],
            positionData: [],
            loading: false,
            scrollY: "",
            editModalVisible: false,
            addShow: false,
            employeeItemData: [],
            roleData: [],
            UserID: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
            StationID: undefined,
        };
    },
    computed: {},
    beforeUpdate() {
        var that = this;
        this.$nextTick(() => {
            that.scrollY = this.getTableScroll();
            //console.log(that.scrollY);
            setTimeout(() => {
                that.checkScrollBar();
            });
        });
    },
    mounted() {
        var that = this;
        this.getPosList();
        //监听事件
        window.onresize = () => {
            return (() => {
                this.$nextTick(() => {
                    that.scrollY = this.getTableScroll();
                    that.checkScrollBar();
                    // console.log(that.scrollY);
                });
            })();
        };
    },
    methods: {
        //获取权限信息
        _GetStationModuleALL() {
            const param = {
                UserID: this.UserID,
                StationID: this.StationID,
            }
            GetStationModuleALL(param).then((res) => {
                if (res.result.bRet === true) {
                    // console.log(res)
                    // console.log(1111)
                    this.roleData = res.result.objReturnInfo
                }
            })
        },
        //获取人员职位信息
        getPosList(param = {}) {
            const user = JSON.parse(localStorage.getItem("AdminUser"));
            SelecttStationsDynamic({
                UserID: user.fEmployeeID,
                WhereClause: "[fState] ='T'",
                OrderField: "[fOrderValue]",
                ...param,
            }).then((res) => {
                // console.log(res);
                if (res.result.length > 0) {
                    this.positionData = res.result;
                    this.StationID = res.result[0].fStationID
                    this._GetStationModuleALL();
                }
            });
        },
        //人员职位select发生改变执行
        searchStationChange(value) {
            this.StationID = value
            if (value) {
                // console.log(value)
                this._GetStationModuleALL();
            } else {
                this.roleData = []
            }
        },
        showRoleListAdd() {
            this.addShow = true;
        },
        getTableScroll(extraHeight, id) {
            // 获取表格滚动高度
            if (typeof extraHeight == "undefined") {
                //  默认底部分页32 + 边距32
                extraHeight = 84;
            }
            let tHeader = null;
            if (id) {
                tHeader = document.getElementById(id)
                    ? document
                        .getElementById(id)
                        .getElementsByClassName("ant-table-thead")[0]
                    : null;
            } else {
                tHeader = document.getElementsByClassName("ant-table-thead")[0];
            }
            // 表格内容距离顶部的距离
            let tHeaderBottom = 0;
            if (tHeader) {
                tHeaderBottom = tHeader.getBoundingClientRect().bottom;
            }
            // 窗体高度-表格内容顶部的高度-表格内容底部的高度
            let height = document.body.clientHeight - tHeaderBottom - extraHeight;
            //let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
            return height;
        },
        checkScrollBar() {
            let antTableBody = document.querySelector(".ant-table-body");
            let antTableBodyScrollHeight = antTableBody && antTableBody.scrollHeight;
            let antTableBodyClientHeight = antTableBody && antTableBody.clientHeight;
            let antTableFixHeader = document.querySelector(
                ".ant-table-hide-scrollbar"
            );
            //console.log("xxxxx", antTableBodyScrollHeight, antTableBodyClientHeight);
            if (antTableBodyScrollHeight <= antTableBodyClientHeight) {
                antTableFixHeader &&
                antTableFixHeader.classList.add("change-scrollBar");
            } else {
                antTableFixHeader &&
                antTableFixHeader.classList.remove("change-scrollBar");
            }
        },
    },
};
</script>

<style scoped lang="stylus">
.emp-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 800;
    line-height: 24px;

    span {
        font-weight: 500;
        font-size: 16px;
        margin-left: 25px;
        color: rgba(0, 0, 0, 0.85);
    }
}

.role-list-action {
    margin: 10px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.role-list-button2 {
    margin: 0 16px;
}

.role-list-list {
    margin: 16px;
}

.role-list-select {
    display: flex;
    flex-direction: column;
}

.role-list-select-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    margin-right: 35px;
}

.role-list-select-item-text {
    font-family: 'Arial Normal', 'Arial';
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #797979;
}

.role-list-button1 {
    display: flex;
    flex-direction: row;
}

.ant-btn {
    margin-left: 10px;
}

.ant-btn:first-child {
    margin-left: 0;
}
</style>