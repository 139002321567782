<template>
    <div id="WBSAdd">
        <a-modal
            centered
            width="500px"
            title="选择任务参与人"
            :visible="ifEditor"
            @ok="handleOk"
            @cancel="handleCancel"
        >
            <div style="margin-bottom: 10px">
                <a-button type="primary" @click="addPeople">新增参与人</a-button>
            </div>
            <div>
                <a-table :rowKey="(record,index) =>index" :columns="columns" :data-source="editorData"
                         :scroll="{ y: 490 }" :pagination="false">
                    <div slot="action" slot-scope="text, record">
                        <a-popconfirm
                            title="您确定要调离这个参与人吗?"
                            ok-text="是"
                            cancel-text="否"
                            @confirm="confirm(record)"
                        >
                            <a-button type="primary">调离</a-button>
                        </a-popconfirm>
                    </div>
                </a-table>
            </div>
        </a-modal>
        <a-modal
            centered
            width="600px"
            title="新增参与人"
            :visible="ifEditor1"
            @ok="handleOk1"
            @cancel="handleCancel1"
        >
            <div style="width: 100%;height: 100%">
                <a-form layout="inline">
                    <a-form-item label="姓名">
                        <a-input placeholder="请输入"/>
                    </a-form-item>
                    <a-form-item label="部门">
                        <a-select placeholder="请选择" :value="fDepartmentID" @change="handleChange2" allowClear>
                            <a-select-option :key="index" :value="item.fDepartmentID"
                                             v-for="(item,index) in fDepartmentData">
                                {{ item.fDepartmentName }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-form>
            </div>
            <div>
                <a-table
                    :rowKey="(record) =>record.fEmployeeID"
                    :scroll="{ y: 490 }"
                    :columns="columns1"
                    :data-source="staffData"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                    :pagination="false"/>
            </div>
        </a-modal>
    </div>
</template>

<script>
import {
    SelectDepartmentsParent, SelecttEmployeesALL, AddProjectUserList,
    DeleteProjectTaskUserList
} from "../../api/projects";

export default {
    props: ['ifEditor', 'editorData', 'subKeyObj', '_GetProjectTaskUserList'],
    name: "editor",
    data() {
        return {
            ModalText: 'Content of the modal',
            ifEditor1: false,
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'fChineseName',
                    key: 'fChineseName',
                },
                {
                    title: '操作',
                    key: 'action',
                    scopedSlots: {customRender: 'action'},
                },
            ],
            columns1: [
                {
                    title: '姓名',
                    dataIndex: 'fChineseName',
                    key: 'fChineseName',
                },
            ],
            sumWeight: 0,
            fDepartmentData: [],
            fDepartmentID: '',
            selectedRowKeys: [], // Check here to configure the default column
            staffData: [],
        };
    },
    mounted() {
        this._SelecttEmployeesALL()
        this._SelectDepartmentsParent()
    },
    methods: {
        confirm(e) {
            console.log(e);
            const params = {
                UserID: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
                TaskID: e.fProjectTaskID,
                TaskUserID: e.fUserID,
            }
            DeleteProjectTaskUserList(params).then((res) => {
                console.log(res)
                if(res.result.bRet===true){
                    this.$message.success('调离成功');
                    this._GetProjectTaskUserList(this.subKeyObj)
                }else{
                    this.$message.error(res.result.errMsg);
                }
            })
        },
        _SelecttEmployeesALL() {
            const params = {
                UserID: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID
            }
            SelecttEmployeesALL(params).then((res) => {
                console.log('人员', res.result)
                this.staffData = res.result
            })
        },
        onSelectChange(selectedRowKeys) {
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            this.selectedRowKeys = selectedRowKeys;
        },
        handleChange2(value) {
            this.fDepartmentID = value === undefined ? "" : value
            console.log(this.fDepartmentID)
        },
        addPeople() {
            this.ifEditor1 = true
            this.selectedRowKeys = []
            if (this.editorData.length > 0) {
                this.editorData.forEach(item => this.selectedRowKeys.push(item.fUserID))
            }
            console.log('this.selectedRowKeys', this.selectedRowKeys)
            // console.log('this.editorData', this.editorData)
        },
        handleOk1() {
            this.ifEditor1 = false;
            let arr = []
            if (this.editorData.length > 0) {
                this.editorData.forEach(item => arr.push(item.fUserID))
            }
            let c = this.selectedRowKeys.filter(v => arr.findIndex(el => el === v) === -1);
            console.log('c', c)
            const params = {
                UserID: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
                ProjectID: this.subKeyObj.fProjectID,
                ProjectTaskID: this.subKeyObj.fProjectTaskID
            }
            let data = []
            for (let i of this.staffData) {
                for (let j of c) {
                    if (i.fEmployeeID === j) {
                        data.push({
                            isModified: true,
                            fUserListID: "00000000-0000-0000-0000-000000000000",
                            fProjectTaskID: this.subKeyObj.fProjectTaskID,
                            fUserID: j,
                            fWeight: 0,
                            fValidHour: 0,
                            fHour: 0,
                            fActualHour: 0,
                            fPersonalPercent: 0,
                            fOrderValue: 0,
                            fIsLock: "F",
                            fState: "T",
                            fRemark: "",
                            fCreateTime: "2023-05-24T06:12:25.454Z",
                            fCreateUser: "196E729B-3CF3-48CD-8C79-1DFC8267D8BE",
                            fChangedTime: "2023-05-24T06:12:25.454Z",
                            fChangedUser: "196E729B-3CF3-48CD-8C79-1DFC8267D8BE"
                        })
                    }
                }
            }
            console.log(JSON.stringify(data))
            AddProjectUserList(params, data).then((res) => {
                console.log(res)
                if (res.result.bRet === true) {
                    this._GetProjectTaskUserList(this.subKeyObj)
                }
            })
        },
        handleCancel1() {
            this.ifEditor1 = false;
        },
        handleOk() {
            this.$emit("update:ifEditor", false);
        },
        handleCancel() {
            this.$emit("update:ifEditor", false);
        },
        //部门
        _SelectDepartmentsParent() {
            SelectDepartmentsParent(JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID).then((res) => {
                this.fDepartmentData = res.result
                // this.fDepartmentID=this.fDepartmentData[0].fDepartmentID
                console.log('部门', res.result)
            })
        },
    },
}
</script>

<style scoped lang="stylus">
/deep/ .components-input-demo-size .ant-input
    width 200px
    height 32px
    box-sizing border-box

/deep/ form .ant-select
    width 200px
    height 32px
    box-sizing border-box
</style>