<template>
  <div class="dep-list">
    <div class="title">
      <span>部门列表</span>
    </div>
    <a-divider />
    <div class="department-action">
      <div class="department-select">
        <div class="department-select-item">
          <span class="department-select-item-text">部门编号：</span>
          <a-input
            style="width: 193px; height: 30px; margin-left: 10px"
            v-model="searchNum"
            placeholder="请输入部门编号"
            allow-clear
          />
        </div>
        <div class="department-select-item">
          <span class="department-select-item-text">部门名称：</span>
          <a-input
            style="width: 193px; height: 30px; margin-left: 10px"
            v-model="searchName"
            placeholder="请输入部门名称"
            allow-clear
          />
        </div>
        <div class="department-select-item">
          <span class="department-select-item-text">部门状态：</span>
          <a-select
            placeholder="请选择"
            :value="searchState"
            @change="searchStateChange"
            style="width: 97px; height: 30px; margin-left: 10px"
          >
            <a-select-option value="T">启用</a-select-option>
            <a-select-option value="F">弃用</a-select-option>
          </a-select>
        </div>
      </div>
      <div class="department-button1">
        <a-button type="primary" @click="onSearch">搜索</a-button>
        <a-button @click="onClear">重置</a-button>
      </div>
    </div>
    <div class="department-button2">
      <a-button type="primary" @click="showAddModal">新增部门</a-button>
    </div>
    <div class="department-list">
      <a-table
        :rowKey="(record) => record.fDepartmentID"
        :columns="columns"
        :data-source="data"
        :scroll="{ y: scrollY }"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="showEditModal(record)">编辑</a>
        </span>
      </a-table>
      <department-edit
        :Visible.sync="editModalVisible"
        :DepartmentData="departmentItemData"
      />
      <department-add :Visible.sync="addModalVisible" />
    </div>
  </div>
</template>
  
<script>
import { SelectDepartments } from "../../api/Employee.js";
import DepartmentAdd from "../../components/department/DepartmentAdd";
import DepartmentEdit from "../../components/department/DepartmentEdit";
const columns = [
  {
    title: "金蝶编号",
    dataIndex: "fKingdeeID",
  },
  {
    title: "部门编号",
    dataIndex: "fDepartmentNum",
  },
  {
    title: "部门名称",
    dataIndex: "fDepartmentName",
  },
  {
    title: "创建时间",
    dataIndex: "fCreateTime",
  },
  {
    title: "编辑时间",
    dataIndex: "fChangedTime",
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];

// const data = [];
// for (let i = 0; i < 46; i++) {
//   data.push({
//     key: i,
//     level: "一级区域",
//     num: "00001",
//     name: "东区1栋",
//     belong: "默认",
//     time: "2022.04.12  18:23:40",
//     people: 10,
//     device: 12,
//   });
// }
export default {
  components: {
    DepartmentEdit,
    DepartmentAdd,
  },
  data() {
    return {
      data: [],
      columns,
      searchState: "T",
      searchNum: undefined,
      searchName: undefined,
      loading: false,
      scrollY: "",
      editModalVisible: false,
      addModalVisible: false,
      departmentItemData: [],
    };
  },
  watch: {
    addModalVisible: {
      handler(val) {
        if (val === false) {
          this.getDepartmentList();
        }
      },
    },
  },
  computed: {},
  beforeUpdate() {
    var that = this;
    this.$nextTick(() => {
      that.scrollY = this.getTableScroll();
      //console.log(that.scrollY);
      setTimeout(() => {
        that.checkScrollBar();
      });
    });
  },
  mounted() {
    var that = this;
    this.getDepartmentList();
    //监听事件
    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          that.scrollY = this.getTableScroll();
          that.checkScrollBar();
          console.log(that.scrollY);
        });
      })();
    };
  },
  methods: {
    getDepartmentList(data = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      this.loading = true;
      SelectDepartments({
        UserID: user.fEmployeeID,
        Page: 1,
        PageNum: 99,
        Data: data,
      }).then((res) => {
        console.log(res);
        this.loading = false;
        this.data = res.result.objReturnInfo;
      });
    },
    searchStateChange(value) {
      this.searchState = value;
    },
    onSearch() {
      let data = {
        fDepartmentNum: this.searchNum,
        fDepartmentName: this.searchName,
        fState: this.searchState,
      };
      this.getDepartmentList(data);
    },
    onClear() {
      this.searchState = "T";
      this.searchNum = undefined;
      this.searchName = undefined;
    },
    showEditModal(data) {
      console.log(data);
      this.departmentItemData = { ...data };
      this.editModalVisible = true;
    },
    showAddModal() {
      this.addModalVisible = true;
    },
    getTableScroll(extraHeight, id) {
      // 获取表格滚动高度
      if (typeof extraHeight == "undefined") {
        //  默认底部分页32 + 边距32
        extraHeight = 84;
      }
      let tHeader = null;
      if (id) {
        tHeader = document.getElementById(id)
          ? document
              .getElementById(id)
              .getElementsByClassName("ant-table-thead")[0]
          : null;
      } else {
        tHeader = document.getElementsByClassName("ant-table-thead")[0];
      }
      // 表格内容距离顶部的距离
      let tHeaderBottom = 0;
      if (tHeader) {
        tHeaderBottom = tHeader.getBoundingClientRect().bottom;
      }
      // 窗体高度-表格内容顶部的高度-表格内容底部的高度
      let height = document.body.clientHeight - tHeaderBottom - extraHeight;
      //let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
      return height;
    },
    checkScrollBar() {
      let antTableBody = document.querySelector(".ant-table-body");
      let antTableBodyScrollHeight = antTableBody && antTableBody.scrollHeight;
      let antTableBodyClientHeight = antTableBody && antTableBody.clientHeight;
      let antTableFixHeader = document.querySelector(
        ".ant-table-hide-scrollbar"
      );
      //console.log("xxxxx", antTableBodyScrollHeight, antTableBodyClientHeight);
      if (antTableBodyScrollHeight <= antTableBodyClientHeight) {
        antTableFixHeader &&
          antTableFixHeader.classList.add("change-scrollBar");
      } else {
        antTableFixHeader &&
          antTableFixHeader.classList.remove("change-scrollBar");
      }
    },
  },
};
</script>
  
<style scoped lang="stylus">
.dep-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 35px);
}

.title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 800;
  line-height: 24px;

  span {
    font-weight: 500;
    font-size: 16px;
    margin-left: 25px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.department-button2 {
  margin: 0 16px;
}

.department-list {
  margin: 16px;
}

.department-action {
  margin: 10px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.department-select {
  display: flex;
  flex-direction: row;
}

.department-select-item {
  margin-left: 10px;
}

.department-select-item:first-child {
  margin-left: 0;
}

.department-select-item-text {
  font-family: 'Arial Normal', 'Arial';
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: #797979;
}

.department-button1 {
  display: flex;
  flex-direction: row;
}

.ant-btn {
  margin-left: 10px;
}

.ant-btn:first-child {
  margin-left: 0;
}
</style>