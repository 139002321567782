import request from "../utils/request";
//'http://39.104.20.60:8081/api/BasicData/SelecttDepartmentsDynamic?
// UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be
// &WhereClause=%5BfState%5D%20%3D%27T%27%20AND%20%5BfIsWorkingHours%5D%20%3D%20%27T%27
// &OrderField=%5BfOrderValue%5D'
export function SelecttDepartmentsDynamic(params) {
    if (!params) {
        params = {
            UserID: '',
            WhereClause: '',
            OrderField: '',
        }
    }
    const urlBitTxt = "/api/BasicData/SelecttDepartmentsDynamic?UserID=" + params.UserID + "&WhereClause=" + params.WhereClause + "&OrderField=" + params.OrderField;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://39.104.20.60:8081/api/StatisticalReport/DeptHoursMonthReport?
// UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be
// &DepartmentID=e8f7958f-2031-4e11-8aa0-b621c03e300f
// &dt=2023-02-23' \
export function DeptHoursMonthReport(params) {
    if (!params) {
        params = {
            UserID: '',
            DepartmentID: '',
            dt: '',
        }
    }
    const urlBitTxt = "/api/StatisticalReport/DeptHoursMonthReport?UserID=" + params.UserID + "&DepartmentID=" + params.DepartmentID + "&dt=" + params.dt;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/StatisticalReport/GetDepartmentTimeFrameHour?
// UserID=e8f7958f-2031-4e11-8aa0-b621c03e300f&
// DepartmentID=e8f7958f-2031-4e11-8aa0-b621c03e300f&
// StartDate=2023-04-26&
// EndDate=2023-04-26&
// IsSend=false'
export function GetDepartmentTimeFrameHour(params) {
    if (!params) {
        params = {
            UserID: '',
            DepartmentID: '',
            StartDate: '',
            EndDate: '',
            IsSend: ''
        }
    }
    const urlBitTxt = "/api/StatisticalReport/GetDepartmentTimeFrameHour?UserID=" + params.UserID + "&DepartmentID=" + params.DepartmentID + "&StartDate=" + params.StartDate + "&EndDate=" + params.EndDate + "&IsSend=" + params.IsSend;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}