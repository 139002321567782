<template>
  <a-modal
    title="职位新增"
    :visible="Visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    width="350px"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="公司" prop="fCompanyID">
        <a-select v-model="form.fCompanyID" placeholder="请选择公司">
          <a-select-option v-for="c in companydata" :key="c.fCompanyID">
            {{ c.fCompanyName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="职务编码" prop="fStationNum">
        <a-input v-model="form.fStationNum" placeholder="请输入部门编码" />
      </a-form-model-item>
      <a-form-model-item label="职务名称" prop="fStationName">
        <a-input v-model="form.fStationName" placeholder="请输入部门名称" />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="fDescription">
        <a-input v-model="form.fDescription" placeholder="请输入描述" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
    
  <script>
import {
  SelecttCompaniesPaged,
  InsertOrUpdatetStation,
} from "../../api/Employee.js";
import moment from "moment";
export default {
  props: ["Visible"],
  data() {
    return {
      confirmLoading: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      form: {
        isModified: true,
        fStationID: "00000000-0000-0000-0000-000000000000",
        fCompanyID: undefined,
        fStationNum: undefined,
        fStationName: undefined,
        fDescription: "",
        fSpelling: "",
        fOrderValue: 0,
        fState: "T",
        fRemark: "",
        fCreateTime: undefined,
        fCreateUser: undefined,
        fChangedTime: undefined,
        fChangedUser: undefined,
      },
      rules: {
        fCompanyID: [
          {
            required: true,
            message: "请选择公司",
            trigger: "change",
          },
        ],
        fStationNum: [
          {
            required: true,
            message: "请输入职务编码",
            trigger: "blur",
          },
        ],
        fStationName: [
          {
            required: true,
            message: "请输入职务名称",
            trigger: "blur",
          },
        ],
      },
      companydata: [],
    };
  },
  mounted() {
    this.getCompanyList();
  },
  methods: {
    handleOk() {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          this.form.fChangedUser = user.fEmployeeID;
          this.form.fChangedTime = moment(new Date()).format();
          this.form.fCreateUser = user.fEmployeeID;
          this.form.fCreateTime = moment(new Date()).format();
          var form = this.form;
          console.log(form, valid);
          let param = {
            UserID: user.fEmployeeID,
            Data: form,
          };
          InsertOrUpdatetStation(param).then((res) => {
            console.log(res);
            this.$emit("update:visible", false);
            this.confirmLoading = false;
          });
          // setTimeout(() => {
          //   this.$emit("update:visible", false);
          //   this.confirmLoading = false;
          // }, 2000);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCancel() {
      console.log("Clicked cancel button");
      this.$emit("update:visible", false);
    },
    getCompanyList(param = {}) {
      SelecttCompaniesPaged({
        Page: 1,
        PageNum: 10,
        ...param,
      }).then((res) => {
        this.companydata = res.result;
      });
    },
  },
};
</script>
    
    <style></style>
    