<template>
    <div id="ModuleList">
        <div class="title">
            <span>模块管理</span>
        </div>
        <div class="main">
            <div class="action-bar">
                <div class="action-bar-select">
                    <span>系统模块:</span>
                    <a-select
                        allowClear
                        placeholder="请选择"
                        :value="moduleId"
                        @change="selectChange"
                    >
                        <a-select-option v-for="item in ModuleData" :value="item.fBasicCodeID" :key="item.fBasicCodeID">
                            {{ item.fCodeName }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="action-bar-button">
                    <a-button type="primary" @click="_InsertOrUpdatetSystemModuleRights('add')">
                        新增
                    </a-button>
                </div>
            </div>
            <div class="div-table">
                <a-table :rowKey="(record,index) => {return record.key}" :loading="loading" :columns="columns"
                         :data-source="data" :default-expand-all-rows="true" :scroll="{ y: scrollY }">
                    <span slot="fCloseState" slot-scope="fCloseState" v-if="fCloseState==='T'">未关闭</span>
                    <span slot="fCloseState" slot-scope="fCloseState" v-else-if="fCloseState==='F'">关闭</span>
                    <span slot="fChangedTime"
                          slot-scope="fChangedTime">{{ $moment(fChangedTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
                    <span slot="action" slot-scope="text, record">
                      <span style="color: #ED6D01;cursor: pointer;"
                            @click="_InsertOrUpdatetSystemModuleRights('edit',record)">编辑</span>
                     <a-divider style="background-color: #ED6D01;" type="vertical"/>
                        <a-popconfirm
                            title="确定要删除这个模块吗?"
                            ok-text="确定"
                            cancel-text="取消"
                            @confirm="confirm(record)"
                        >
                       <a href="#" style="color: #ED6D01;cursor: pointer;">删除</a>
                      </a-popconfirm>
                    </span>
                </a-table>
            </div>
        </div>
        <module-list-add v-if="addShow" :addShow.sync="addShow" :fModuleType="moduleId" :ModuleSubset="ModuleSubset"></module-list-add>
        <module-list-edit v-if="editShow" :editShow.sync="editShow" :editObj="editObj"></module-list-edit>
    </div>
</template>

<script>
import {DeletetSystemModuleRights, GetModuleALL, GetSystemModuleSubset} from "../../api/ModuleList";
import ModuleListAdd from "../../components/ModuleList/ModuleListAdd";
import ModuleListEdit from "../../components/ModuleList/ModuleListEdit";

export default {
    components: {ModuleListAdd, ModuleListEdit},
    name: "ModuleList",
    data() {
        return {
            editObj: {},
            addShow: false,
            editShow: false,
            loading: false,
            scrollY: "",
            columns: [
                {
                    title: '模块编码',
                    dataIndex: 'fModuleNo',
                    key: 'fModuleNo',
                },
                {
                    title: '模块名称',
                    dataIndex: 'fModuleName',
                    key: 'fModuleName',
                },
                {
                    title: '链接',
                    dataIndex: 'fLink',
                    key: 'fLink',
                },
                {
                    title: '关闭状态',
                    dataIndex: 'fCloseState',
                    key: 'fCloseState',
                    scopedSlots: {customRender: 'fCloseState'},
                },
                {
                    title: '更新日期',
                    dataIndex: 'fChangedTime',
                    key: 'fChangedTime',
                    scopedSlots: {customRender: 'fChangedTime'},
                },
                {
                    title: '功能描述',
                    dataIndex: 'fRemark',
                    key: 'fRemark',
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    scopedSlots: {customRender: "action"},
                },
            ],
            UserID: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
            ModuleData: [],
            moduleId: undefined,
            data: [],
            ModuleSubset:[]
        }
    },
    beforeUpdate() {
        const that = this;
        this.$nextTick(() => {
            that.scrollY = this.getTableScroll();
            setTimeout(() => {
                that.checkScrollBar();
            });
        });
    },
    mounted() {
        this._GetModuleALL();
        //监听事件
        const that = this;
        window.onresize = () => {
            return (() => {
                this.$nextTick(() => {
                    that.scrollY = this.getTableScroll();
                    that.checkScrollBar();
                    console.log(that.scrollY);
                });
            })();
        };
    },
    methods: {
        confirm(record) {
            const param = {
                UserID: this.UserID,
                fSystemModuleID: record.fSystemModuleID,
                pfState: 'F',
            }
            DeletetSystemModuleRights(param).then((res) => {
                if (res.result === true) {
                    this.$message.success('删除成功');
                    this._GetModuleALL('del');
                } else {
                    this.$message.error('删除失败')
                }
                console.log(res)
            }).catch((err) => {
                console.log(err)
                this.$message.error('删除失败')
            })
        },
        _InsertOrUpdatetSystemModuleRights(title, obj) {
            this.addShow = title === 'add'
            this.editShow = title === 'edit'
            if (obj) {
                this.editObj = obj
            }
        },
        _GetModuleALL(title) {
            let param = {
                UserID: this.UserID
            };
            GetModuleALL(param).then((res) => {
                console.log(res.result)
                for (let i of res.result.objReturnInfo) {
                    for (let j of i.lSystemModuleRights) {
                        for (let k of j.children) {
                            if (k.children) {
                                if (k.children.length === 0) {
                                    delete k.children
                                }else{
                                    for (let l of k.children) {
                                        if (l.children) {
                                            if (l.children.length === 0) {
                                                delete l.children
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                this.ModuleData = res.result.objReturnInfo
                if (!title) {
                    this.selectChange(this.ModuleData[0].fBasicCodeID)
                } else {
                    this.selectChange(this.moduleId)
                }
            })
        },
        selectChange(value) {
            console.log(value)
            this._GetSystemModuleSubset({UserID:this.UserID,ModuleType:value})
            this.moduleId = value
            this.loading = true
            if (value) {
                this.data = this.ModuleData.filter((item) => item.fBasicCodeID === value)[0].lSystemModuleRights
                console.log('data', this.data)
                this.loading = false
            } else {
                this.data = []
                this.loading = false
            }
        },
        _GetSystemModuleSubset(param){
            GetSystemModuleSubset(param).then((res)=>{
                this.ModuleSubset=res.result.objReturnInfo
                console.log("12312414",res.result.objReturnInfo)
            })
        },
        getTableScroll(extraHeight, id) {
            // 获取表格滚动高度
            if (typeof extraHeight == "undefined") {
                //  默认底部分页32 + 边距32
                extraHeight = 84;
            }
            let tHeader = null;
            if (id) {
                tHeader = document.getElementById(id)
                    ? document
                        .getElementById(id)
                        .getElementsByClassName("ant-table-thead")[0]
                    : null;
            } else {
                tHeader = document.getElementsByClassName("ant-table-thead")[0];
            }
            // 表格内容距离顶部的距离
            let tHeaderBottom = 0;
            if (tHeader) {
                tHeaderBottom = tHeader.getBoundingClientRect().bottom;
            }
            // 窗体高度-表格内容顶部的高度-表格内容底部的高度
            //let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
            return document.body.clientHeight - tHeaderBottom - extraHeight;
        },
        checkScrollBar() {
            let antTableBody = document.querySelector(".ant-table-body");
            let antTableBodyScrollHeight = antTableBody && antTableBody.scrollHeight;
            let antTableBodyClientHeight = antTableBody && antTableBody.clientHeight;
            let antTableFixHeader = document.querySelector(
                ".ant-table-hide-scrollbar"
            );
            //console.log("xxxxx", antTableBodyScrollHeight, antTableBodyClientHeight);
            if (antTableBodyScrollHeight <= antTableBodyClientHeight) {
                antTableFixHeader &&
                antTableFixHeader.classList.add("change-scrollBar");
            } else {
                antTableFixHeader &&
                antTableFixHeader.classList.remove("change-scrollBar");
            }
        },
    }
}
</script>

<style scoped lang="stylus">
#ModuleList
    width 100%
    height 100%

    .title
        width 100%
        border-bottom solid 1px #E9E9E9
        box-sizing border-box
        padding-bottom 16px

        span
            font-weight 500
            font-size 16px
            margin-left 25px
            color rgba(0, 0, 0, 0.85)

    .main
        height calc(100% - 41px)
        padding 0 13px 13px 13px
        box-sizing border-box

        .action-bar
            width 100%
            height 64px
            display flex
            flex-direction row
            justify-content flex-start
            align-items center
            font-size 14px

            .action-bar-select
                width auto
                height auto

                .ant-select
                    width 200px

                span
                    margin-right 10px

            .action-bar-button
                width auto
                height auto

                .ant-btn
                    margin-left 24px

        .div-table
            width 100%
            height calc(100% - 65px)
</style>