<template>
  <div id="ReportForms">
    <div class="title">
      <span>工时明细统计</span>
    </div>
    <div class="main">
      <div class="operate">
        <a-form-model layout="inline">
          <a-form-model-item label="部门">
            <!-- <el-select
              @change="_DeptHoursMonthReport"
              size="small"
              v-model="departmentId"
              placeholder="请选择"
              style="width: 160px"
            >
              <el-option
                v-for="(item, index) in departmentData"
                :key="index"
                :label="item.fDepartmentName"
                :value="item.fDepartmentID"
              >
              </el-option>
            </el-select> -->

            <a-select
              v-model="form.departmentId"
              placeholder="请选择"
              style="width: 160px"
            >
              <a-select-option
                v-for="(item, index) in departmentData"
                :key="index"
                :value="item.fDepartmentID"
                >{{ item.fDepartmentName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="开始日期">
            <!-- <el-date-picker
              style="width: 160px"
              size="small"
              v-model="dt"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              @change="_DeptHoursMonthReport"
            >
            </el-date-picker> -->
            <a-date-picker
              v-model="form.startValue"
              :disabled-date="disabledStartDate"
              type="date"
              format="YYYY-MM-DD"
              placeholder="选择开始日期"
              @openChange="handleStartOpenChange"
              style="width: 160px"
            />
          </a-form-model-item>
          <a-form-model-item label="结束日期">
            <a-date-picker
              v-model="form.endValue"
              :disabled-date="disabledEndDate"
              type="date"
              format="YYYY-MM-DD"
              placeholder="选择结束日期"
              :open="endOpen"
              @openChange="handleEndOpenChange"
              style="width: 160px"
            />
          </a-form-model-item>
          <a-form-model-item label="是否发送邮件">
            <a-radio-group v-model="form.IsSend">
              <a-radio value="true">是</a-radio>
              <a-radio value="false">否</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="onSearch">搜索</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="demo-infinite-container">
        <a-table
          :rowKey="(record) => record.employeeID"
          :columns="columns"
          :data-source="reportData.lEmployeeSummaryHour"
        />
      </div>
      <div class="describe">
        <div>部门名称：</div>
        <span>{{ this.reportData.departmentName }}</span>
        <div>有效工作量：</div>
        <span>{{ this.reportData.departmenProjectHour }}</span>
        <div>部门总工作量：</div>
        <span>{{ this.reportData.departmenNotProject }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SelecttDepartmentsDynamic,
  GetDepartmentTimeFrameHour,
} from "../../api/ReportForms";
const columns = [
  {
    title: "填报对象",
    dataIndex: "employeeName",
  },
  {
    title: "项目工时",
    dataIndex: "projectHour",
  },
  {
    title: "非项目工时",
    dataIndex: "notProjectHour",
  },
  {
    title: "未填报工时",
    dataIndex: "difference",
  },
];

export default {
  name: "ReportForms",
  data() {
    return {
      form: {
        departmentId: "",
        startValue: this.$moment(this.$moment().format("YYYY-MM-DD")),
        endValue: this.$moment(this.$moment().format("YYYY-MM-DD")),
        IsSend: "false",
      },
      endOpen: false,
      data: [],
      columns,
      userId: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID, //获取用户id
      UserData: JSON.parse(localStorage.getItem("AdminUser")),
      departmentData: [],
      dt: this.$moment().format("YYYY-MM-DD"),
      reportData: [],
      loading: "",
    };
  },

  mounted() {
    this._SelecttDepartmentsDynamic();
  },
  methods: {
    disabledStartDate(startValue) {
      const endValue = this.form.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.form.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    openLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    _SelecttDepartmentsDynamic() {
      const param = {
        UserID: this.userId,
        WhereClause: "[fState] ='T' AND [fIsWorkingHours] = 'T'",
        OrderField: "[fOrderValue]",
      };
      SelecttDepartmentsDynamic(param)
        .then((res) => {
          // console.log(res.result)
          this.form.departmentId = res.result[0].fDepartmentID;
          this.departmentData = res.result;
          // console.log(this.departmentId)
          this._GetDepartmentTimeFrameHour();
        })
        .catch((err) => {
          this.$message.error("请求部门数据出现错误，请稍后再试");
          console.log(err);
        });
    },
    _GetDepartmentTimeFrameHour() {
      this.openLoading();
      const param = {
        UserID: this.userId,
        DepartmentID: this.form.departmentId,
        StartDate: this.$moment(this.form.startValue).format("YYYY-MM-DD"),
        EndDate: this.$moment(this.form.endValue).format("YYYY-MM-DD"),
        IsSend: this.form.IsSend,
      };
      console.log(param);
      GetDepartmentTimeFrameHour(param)
        .then((res) => {
          // console.log(res.result)
          this.reportData = res.result.objReturnInfo;
          this.loading.close();
        })
        .catch((err) => {
          this.$message.error("请求报表数据出现错误，请稍后再试");
          this.loading.close();
          console.log(err);
        });
    },
    onSearch() {
      console.log(this.form);
      this._GetDepartmentTimeFrameHour();
    },
  },
};
</script>

<style scoped lang="stylus">
#ReportForms {
  width: 100%;
  height: 100%;

  .title {
    width: 100%;
    border-bottom: solid 1px #E9E9E9;
    box-sizing: border-box;
    padding-bottom: 16px;

    span {
      font-weight: 500;
      font-size: 16px;
      margin-left: 25px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .main {
    width: 100%;
    height: calc(100% - 150px);
    padding: 24px;
    box-sizing: border-box;

    .demo-infinite-container {
      margin-top: 16px;
      overflow: auto;
      height: 100%;
    }

    .describe {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-left: 16px;
      box-sizing: border-box;

      div {
        font-size: 18px;
        font-weight: 400;
        margin-left: 32px;
      }

      span {
        font-size: 18px;
        font-weight: 400;
        color: #F86801;
      }
    }
  }

  .pagination {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
  }
}

.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

/*
.pagination >>> .ant-pagination-item-active{
    background: #E06A00 !important;
    border-color: #E06A00 !important;
    color: white !important;
}
.pagination >>> .ant-pagination-item-active a{
    color: white !important;
}
.pagination >>> .ant-pagination-item:hover{
    border: 1px #E06A00 solid!important;
    color: #E06A00 !important;
}
.pagination >>> .ant-pagination-item a:hover{
    color: #E06A00 !important;
} */
</style>