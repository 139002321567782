<template>
    <div>
        <a-modal :centered="true" :visible="addShow" title="新增" @ok="addOk" @cancel="addCancel">
            <a-form-model :model="form" :rules="rules" ref="ruleForm" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="模块编码" prop="fModuleNo">
                    <a-input v-model="form.fModuleNo"/>
                </a-form-model-item>
                <a-form-model-item label="模块名称" prop="fModuleName">
                    <a-input v-model="form.fModuleName"/>
                </a-form-model-item>
                <a-form-model-item label="链接" prop="fLink">
                    <a-input v-model="form.fLink"/>
                </a-form-model-item>
              <a-form-model-item label="父级模块">
                <a-select
                  allowClear
                  placeholder="请选择"
                  :default-value="form.fUpSystemModuleID"
                  @change="handleChange"
                >
                  <a-select-option v-for="item in ModuleSubset" :value="item.fSystemModuleID" :key="item.fSystemModuleID">
                    {{ item.fModuleName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
                <a-form-model-item label="图标编码" prop="fPicture">
                    <a-input v-model="form.fPicture"/>
                </a-form-model-item>
                <a-form-model-item label="关闭状态">
                    <a-radio-group v-model="form.fCloseState">
                        <a-radio value="T">
                            未关闭
                        </a-radio>
                        <a-radio value="F">
                            关闭
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="描述" prop="fRemark">
                    <a-input v-model="form.fRemark" type="textarea"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import {InsertOrUpdatetSystemModuleRights, GetGuid} from "../../api/ModuleList";

export default {
    props: ['addShow', 'fModuleType', 'ModuleSubset'],
    name: "ModuleListAdd",
    data() {
        return {
            labelCol: {span: 6},
            wrapperCol: {span: 17},
            destroy_on_close: true,
            form: {
                fSystemModuleID: '00000000-0000-0000-0000-000000000000',
                fUpSystemModuleID: '',//下拉框
                fUpModuleNo: '',
                fUpModuleName: '',
                fModuleType: '',//大类
                fModuleNo: '',
                fModuleName: '',
                fLink: '',
                fEvent: "",
                fPicture: '',
                fCloseState: 'T',
                fRemark: '',
                fOrderValue: 0,
                fIsLock: 'F',
                fState: 'T',
                fCreateTime: this.$moment().format(),
                fCreateUser: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
                fChangedTime: this.$moment().format(),
                fChangedUser: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
            },
            rules: {
                fModuleNo: [
                    {required: true, message: '请输入内容', trigger: 'blur'},
                ],
                fModuleName: [
                    {required: true, message: '请输入内容', trigger: 'blur'},
                ],
                fLink: [
                    {required: true, message: '请输入内容', trigger: 'blur'},
                ],
            },
        };
    },
    methods: {
      handleChange(value){
          this.form.fUpSystemModuleID = value
          console.log(this.form.fUpSystemModuleID)
          console.log(value)
      },
        addOk() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    GetGuid().then((guid) => {
                        this.form.fSystemModuleID = guid
                        this.form.fModuleType = this.fModuleType
                        console.log(JSON.stringify(this.form))
                        InsertOrUpdatetSystemModuleRights(JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID, this.form).then((res) => {
                            if (res.result.bRet === true) {
                                this.$message.success('新增成功')
                                this.$emit("update:addShow", false)
                                // this.$refs.ruleForm.resetFields();
                                this.form = {
                                    fSystemModuleID: '00000000-0000-0000-0000-000000000000',
                                    fUpSystemModuleID: '',
                                    fUpModuleNo: '',
                                    fUpModuleName: '',
                                    fModuleType: '',
                                    fModuleNo: '',
                                    fModuleName: '',
                                    fLink: '',
                                    fEvent: "",
                                    fPicture: '',
                                    fCloseState: 'T',
                                    fRemark: '',
                                    fOrderValue: 0,
                                    fIsLock: 'F',
                                    fState: 'T',
                                    fCreateTime: this.$moment().format(),
                                    fCreateUser: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
                                    fChangedTime: this.$moment().format(),
                                    fChangedUser: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
                                }
                                this.$parent._GetModuleALL('add');
                            } else {
                                this.$message.error('新增失败')
                            }
                        }).catch((err) => {
                            this.$message.error('新增失败')
                            console.log(err)
                        })
                    })
                } else {
                    return false;
                }
            });
        },
        addCancel() {
            // this.$refs.ruleForm.resetFields();
            /*this.form = {
                fSystemModuleID: '00000000-0000-0000-0000-000000000000',
                fModuleType: '',
                fModuleNo: '',
                fModuleName: '',
                fLink: '',
                fEvent: "",
                fPicture: '',
                fCloseState: 'T',
                fRemark: '',
                fOrderValue: 0,
                fIsLock: 'F',
                fState: 'T',
                fCreateTime: this.$moment().format(),
                fCreateUser: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
                fChangedTime: this.$moment().format(),
                fChangedUser: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
            }*/
            this.$emit("update:addShow", false);
            this.$parent._GetModuleALL('add');
        },
    },
}
</script>

<style scoped lang="stylus">
</style>