import request from '../utils/request'

//http://10.192.167.42:8084/api/WorkingCalendar/SelecttCalendarsMain?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be
export function SelecttCalendarsMain(UserID) {
    if (!UserID) {
        UserID =  ''
    }
    const urlBitTxt = "/api/WorkingCalendar/SelecttCalendarsMain?UserID=" + UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/WorkingCalendar/GetCalendarItemMonth?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&CalendarID=156336d4-3d86-4c9c-92cc-8264b96dc81e&Year=2023&Month=1'
export function GetCalendarItemMonth(params) {
    if (!params) {
        params = {
            UserID: '',
            CalendarID:'',
            Year:'',
            Month:''
        }
    }
    const urlBitTxt = "/api/WorkingCalendar/GetCalendarItemMonth?UserID=" + params.UserID+"&CalendarID="+params.CalendarID+"&Year="+params.Year+"&Month="+params.Month;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}
//'http://10.192.167.42:8084/api/WorkingCalendar/UpdateCalendarItemWorkType?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&CalendarID=156336d4-3d86-4c9c-92cc-8264b96dc81e&CalendarItemID=1e74729d-c6d2-4935-b50c-3198f4a6307a&WorkType=O' \
export function UpdateCalendarItemWorkType(params) {
    if (!params) {
        params = {
            UserID: '',
            CalendarID:'',
            CalendarItemID:'',
            WorkType:''
        }
    }
    const urlBitTxt = "/api/WorkingCalendar/UpdateCalendarItemWorkType?UserID=" + params.UserID+"&CalendarID="+params.CalendarID+"&CalendarItemID="+params.CalendarItemID+"&WorkType="+params.WorkType;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}