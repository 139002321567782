<template>
    <div id="index">

        <div class="head">
            <p class="head-p1">
                <img class="head-p1-img" src="../../assets/dyzq.png" alt="天气">
                <span class="head-p1-span"
                      v-if="this.$moment().format('HH:mm')>='05:01'&&this.$moment().format('HH:mm')<='06:59'">清晨好</span>
                <span class="head-p1-span"
                      v-else-if="this.$moment().format('HH:mm')>='07:00'&&this.$moment().format('HH:mm')<='08:59'">早上好</span>
                <span class="head-p1-span"
                      v-else-if="this.$moment().format('HH:mm')>='09:00'&&this.$moment().format('HH:mm')<='12:00'">上午好</span>
                <span class="head-p1-span"
                      v-else-if="this.$moment().format('HH:mm')>='12:01'&&this.$moment().format('HH:mm')<='13:59'">中午好</span>
                <span class="head-p1-span"
                      v-else-if="this.$moment().format('HH:mm')>='14:00'&&this.$moment().format('HH:mm')<='17:59'">下午好</span>
                <span class="head-p1-span"
                      v-else-if="this.$moment().format('HH:mm')>='18:00'&&this.$moment().format('HH:mm')<='18:59'">傍晚好</span>
                <span class="head-p1-span"
                      v-else-if="this.$moment().format('HH:mm')>='19:00'&&this.$moment().format('HH:mm')<='23:59'">晚上好</span>
                <span class="head-p1-span"
                      v-else-if="this.$moment().format('HH:mm')>='24:00'&&this.$moment().format('HH:mm')<='05:00'">凌晨好</span>
            </p>
            <p class="head-p2">{{ userData.fChineseName }}，一日之计在于晨，大家加油！</p>
        </div>
        <div class="tag">
            <a-alert message="待我审核：0" type="warning" show-icon/>
        </div>
        <div class="main">
            <div class="main-1">
                    <div class="header">
                        <a-icon type="left" @click="subtract"/>
                        <p>{{ year }}月{{ month }}日</p>
                        <a-icon type="right" @click="add"/>
                    </div>
                    <div id="calendar">
                        <table class="calendar-table" cellspacing="0" cellpadding="0">
                            <thead>
                            <th>星期日</th>
                            <th>星期一</th>
                            <th>星期二</th>
                            <th>星期三</th>
                            <th>星期四</th>
                            <th>星期五</th>
                            <th>星期六</th>
                            </thead>
                            <tbody>
                            <tr v-for="(item,index) in CalenderData" :key="index">
                                <td v-for="(item1,index1) in item.ltCalendarItems" :key="index1">
                                <span
                                    :class="{color_span_calendar:$moment(month).format('M')+''!==item1.fMonth+'',background_span_calendar:$moment().format('D')+''===item1.showDay+''&&$moment().format('M')+''===item1.fMonth+''&&$moment().format('YYYY')+''===item1.fYear+''}">{{
                                        item1.showDay
                                    }}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            <div class="bulletinBoard">
                <div class="header">公告栏</div>
                <div>暂无公告内容</div>
            </div>
        </div>
    </div>
</template>

<script>
import {SelecttCalendarsMain, GetCalendarItemMonth} from "../../api/WorkingCalendar"

export default {
    name: "index",
    data() {
        return {
            userData: JSON.parse(localStorage.getItem("AdminUser")),
            userId: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,//获取用户id
            fCalendarID: "",
            year: this.$moment().format("YYYY"),
            month: this.$moment().format("MM"),
            CalenderData: [],
        }
    },
    mounted() {
        this._SelecttCalendars()
    },
    methods: {
        add() {
            let originalYtd = this.year + "-" + this.month
            const date = this.calcMonths(originalYtd, 1);
            this.year = date.split('-')[0]
            this.month = date.split('-')[1]
            this._GetCalendarItemMonth()
        },
        subtract() {
            let originalYtd = this.year + "-" + this.month
            const date = this.calcMonths(originalYtd, -1)
            this.year = date.split('-')[0]
            this.month = date.split('-')[1]
            this._GetCalendarItemMonth()
        },
        calcMonths(originalYtd, monthNum) {
            let arr = originalYtd.split('-');
            let year = parseInt(arr[0]);
            let month = parseInt(arr[1]);
            month = month + monthNum;
            if (month > 12) {
                let yearNum = (month - 1) / 12;
                month = month % 12 === 0 ? 12 : month % 12;
                year += yearNum;
            } else if (month <= 0) {
                month = Math.abs(month);
                let yearNum = (month + 12) / 12;
                let n = month % 12;
                if (n === 0) {
                    year -= yearNum;
                    month = 12
                } else {
                    year -= yearNum;
                    month = Math.abs(12 - n)
                }

            }
            month = month < 10 ? "0" + month : month;
            return year + "-" + month;
        },
        _SelecttCalendars() {
            SelecttCalendarsMain(this.userId).then(res => {
                this.fCalendarID = res.result[0].fCalendarID
                console.log(res.result[0].fCalendarID)
                this._GetCalendarItemMonth()
            }).catch(err => {
                console.log(err)
            })
        },
        _GetCalendarItemMonth() {
            const params = {
                UserID: this.userId,
                CalendarID: this.fCalendarID,
                Year: this.year,
                Month: this.month
            }
            console.log(params)
            GetCalendarItemMonth(params).then(res => {
                console.log(res)
                this.CalenderData = res.result
            }).catch(err => {
                this.loading.close();
                console.log(err)
            })
        },
    },
}
</script>

<style scoped lang="stylus">
#index
    width 100%
    height 100%
    display: flex;
    flex-direction: column;

    .head
        width 100%
        height 270px
        padding-left 90px
        box-sizing border-box
        display flex
        flex-direction column
        justify-content center
        align-items flex-start

        .head-p1
            display flex
            justify-content center
            align-items flex-end

            .head-p1-img
                width 80px
                height 80px

            .head-p1-span
                font-size 40px
                font-weight 400
                color #333333
                margin-left 30px

        .head-p2
            font-size 32px
            font-weight 400
            color #666666

    .tag
        width 400px
        margin-left 90px
        margin-bottom 4px

        .ant-alert
            width 400px
            background-color #FFDD98
            border-radius 4px
            border 1px #FAAD14 solid
            color #666666

    .main
        width 100%
        height 645px
        padding 0 32px 32px 32px
        box-sizing border-box
        display flex
        flex-direction row
        justify-content space-between

        .main-1
            width calc(100% - 472px)
            height 100%
            border 1px solid #ED6D01
            box-shadow 0 0 6px 2px rgba(0, 0, 0, 0.1)
            border-radius 8px
            padding 8px
            box-sizing border-box
            display: flex;
            flex-direction: column;

            .header
                width 100%
                height 100px
                display flex
                flex-direction row
                justify-content center
                align-items center
                font-size 36px
                font-weight 400

                p
                    margin 0 15px 6px 15px
                    color #ED6D01

                i
                    cursor pointer

            #calendar
                display flex
                flex 1

                table
                    flex 1
                    padding 0 60px
                    display flex
                    flex-direction column
                    box-sizing border-box
                    text-indent initial
                    border-color grey

                    thead
                        display flex
                        flex-direction row
                        justify-content space-between

                        th
                            width 55px
                            font-size 24px
                            font-weight 400
                            color #333333

                    tbody
                        display flex
                        flex-direction column
                        justify-content space-evenly
                        align-items: center
                        flex 1

                        tr
                            width 100%
                            display flex
                            flex-direction row
                            justify-content space-between

                            td
                                width 55px

                                span
                                    font-size 20px

                .calendar-table
                    table-layout fixed
                    width 100%

                    th
                        font-size 18px
                        font-weight 400
                        color #333333
                        text-align center
                        vertical-align top
                        transition background-color .2s ease

                    td
                        text-align center
                        vertical-align top
                        transition background-color .2s ease


        .bulletinBoard
            width 432px
            height 100%
            border 1px solid #ED6D01
            box-shadow 0 0 6px 2px rgba(0, 0, 0, 0.1)
            border-radius 8px
            padding 8px
            box-sizing border-box
            font-size 20px

            .header
                width 100%
                height 100px
                display flex
                flex-direction row
                justify-content flex-start
                align-items center
                padding-left 45px
                box-sizing border-box
                font-size 36px
                font-weight 400
                color #ED6D01

.color_span_calendar
    color #c0c4cc

.background_span_calendar
    width 30px
    height 30px
    display inline-block
    border-radius 20px
    background-color #ED6D01
    color #FFFFFF
</style>