<template>
    <div id="app" style="overflow: auto;">
        <a-config-provider :locale="locale">
            <router-view/>
        </a-config-provider>
    </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
    data() {
        return {
            locale: zhCN,
        };
    },
};
</script>

<style lang="stylus">
#app {
    display: flex;
    /*flex: 1;*/
    width: 100%;
    height: 100%;
}

/deep/ .ant-select
    width 200px!important

/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
// 滚动条整体部分
&::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

// 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
&::-webkit-scrollbar-button {
    display: none;
}

// 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
&::-webkit-scrollbar-thumb {
    background: rgba(144, 147, 153, 0.3);
    cursor: pointer;
    border-radius: 4px;
}

// 边角，即两个滚动条的交汇处
&::-webkit-scrollbar-corner {
    display: none;
}

// 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
&::-webkit-resizer {
    display: none;
}
#app >>> .el-input__inner:focus {
    border 1px #ED6C02 solid
}

#app >>> .el-textarea__inner:focus {
    border 1px #ED6C02 solid
}
</style>
