<template>
    <div id="ReportForms">
        <div class="title">
            <span>工时报表</span>
        </div>
        <div class="main">
            <div class="operate">
                <a-form layout="inline">
                    <a-form-item label="部门">
                        <el-select @change="_DeptHoursMonthReport" size="small" v-model="departmentId" placeholder="请选择"
                                   style="width: 160px">
                            <el-option
                                v-for="(item,index) in departmentData"
                                :key="index"
                                :label="item.fDepartmentName"
                                :value="item.fDepartmentID">
                            </el-option>
                        </el-select>
                    </a-form-item>
                    <a-form-item label="日期">
                        <el-date-picker
                            style="width: 160px"
                            size="small"
                            v-model="dt"
                            type="date"
                            placeholder="选择日期"
                            format="yyyy-MM-dd"
                            @change="_DeptHoursMonthReport">
                        </el-date-picker>
                    </a-form-item>
                </a-form>
            </div>
            <div class="demo-infinite-container"
                 :infinite-scroll-disabled="busy"
                 :infinite-scroll-distance="10">
                <div class="data_s" :key="index" v-for="(item,index) in this.reportData.employeeALLReport">
                    <div class="header">
                        <img src="../../assets/people.png" alt="人员">
                        <span>{{ item.employeeNameCN }}</span>
                    </div>
                    <ul :key="index1" v-for="(item1,index1) in item.projectALLReport">
                        <li>项目编号：{{ item1.projectNumber }}</li>
                        <li>项目工时：{{ item1.allHour }}</li>
                    </ul>
                    <div class="footer">
                        <div>本月项目总工时：</div>
                        <span>{{ item.allHour }}</span>
                        <div>本月非项目总工时：</div>
                        <span>{{ item.notProjectAllHour }}</span>
                    </div>
                </div>

            </div>
            <div class="describe">
                <span>{{ this.reportData.departmentName }}</span>
                <div>本月项目总工时：</div>
                <span>{{ this.reportData.departmenProjectHour }}</span>
                <div>本月非项目总工时：</div>
                <span>{{ this.reportData.departmenNotProject }}</span>
            </div>
<!--            <div class="pagination" style="">
                <a-pagination :default-current="6" :total="500"/>
            </div>-->
        </div>
    </div>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll';
import {SelecttDepartmentsDynamic, DeptHoursMonthReport} from "../../api/ReportForms";

export default {
    directives: {infiniteScroll},
    name: "ReportForms",
    data() {
        return {
            busy: false,
            data: [
                {
                    name: "张三",
                    sumProjectTime: 314,
                    FProjectTime: 0,
                    children: [
                        {
                            projectNumber: "XTExxxxxxxsh",
                            projectTime: "36"
                        },
                        {
                            projectNumber: "XTExxxxxxxsh",
                            projectTime: "136"
                        },
                        {
                            projectNumber: "XTExxxxxxxsh",
                            projectTime: "109"
                        },
                        {
                            projectNumber: "XTExxxxxxxsh",
                            projectTime: "33"
                        }
                    ]
                },
                {
                    name: "李四",
                    sumProjectTime: 172,
                    FProjectTime: 36,
                    children: [
                        {
                            projectNumber: "XTExxxxxxxsh",
                            projectTime: "36"
                        },
                        {
                            projectNumber: "XTExxxxxxxsh",
                            projectTime: "136"
                        },
                    ]
                },
            ],
            userId: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,//获取用户id
            UserData: JSON.parse(localStorage.getItem("AdminUser")),
            departmentData: [],
            departmentId: '',
            dt: this.$moment().format("YYYY-MM-DD"),
            reportData:[],
            loading: "",
        }
    },

    mounted() {
        this._SelecttDepartmentsDynamic()
    },
    methods: {
        openLoading() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        _SelecttDepartmentsDynamic() {
            const param = {
                UserID: this.userId,
                WhereClause: '[fState] =\'T\' AND [fIsWorkingHours] = \'T\'',
                OrderField: '[fOrderValue]',
            }
            SelecttDepartmentsDynamic(param).then(res => {
                // console.log(res.result)
                this.departmentId = res.result[0].fDepartmentID
                this.departmentData = res.result
                // console.log(this.departmentId)
                this._DeptHoursMonthReport()
            }).catch(err => {
                this.$message.error('请求部门数据出现错误，请稍后再试');
                console.log(err)
            })
        },
        _DeptHoursMonthReport() {
            this.openLoading()
            const param = {
                UserID: this.userId,
                DepartmentID: this.departmentId,
                dt: this.$moment(this.dt).format("YYYY-MM-DD"),
            }
            DeptHoursMonthReport(param).then(res => {
                // console.log(res.result)
                this.reportData = res.result
                this.loading.close();
            }).catch(err => {
                this.$message.error('请求报表数据出现错误，请稍后再试');
                this.loading.close();
                console.log(err)
            })
        }
    }
}
</script>

<style scoped lang="stylus">
#ReportForms
    width 100%
    height 100%

    .title
        width 100%
        border-bottom solid 1px #E9E9E9
        box-sizing border-box
        padding-bottom 16px

        span
            font-weight 500
            font-size 16px
            margin-left 25px
            color rgba(0, 0, 0, 0.85)

    .main
        width 100%
        height calc(100% - 150px)
        padding 24px
        box-sizing border-box

        .demo-infinite-container
            margin-top 16px
            overflow auto
            height 100%

            .data_s
                width 100%

                .header
                    width 100%
                    height 50px
                    background-color #FAFAFA
                    border-bottom 1px #E8E8E8 solid
                    border-radius: 4px 4px 0 0
                    display flex
                    align-items center
                    padding-left 18px
                    box-sizing border-box

                    img
                        width 24px
                        height 24px

                    span
                        display inline-block
                        font-size 18px
                        font-weight bold
                        color #333333
                        margin-left 5px

                .footer
                    width 100%
                    height 50px
                    border-bottom 1px #E8E8E8 solid
                    display flex
                    flex-direction row
                    flex-wrap wrap
                    align-items center
                    justify-content flex-end
                    box-sizing border-box
                    padding-right 51px

                    div
                        margin-left 69px
                        font-size 18px
                        font-weight 400
                        color #333333

                    span
                        font-size 18px
                        font-weight 400
                        color #F86801

                ul
                    width 100%
                    height 50px
                    margin 0
                    display flex
                    flex-direction row
                    justify-content center
                    align-items center
                    border-bottom 1px #E8E8E8 solid
                    box-sizing border-box
                    flex-wrap wrap
                    transition background-color 0.5s

                    li
                        min-width 300px
                        width 50%
                        display flex
                        justify-content center
                        align-items center
                        font-size 18px
                        font-weight 400
                        color #333333

                ul:hover
                    background-color #E8E8E8

        .describe
            width 100%
            height 50px
            display flex
            flex-direction row
            align-items center
            justify-content flex-start
            padding-left 16px
            box-sizing border-box

            div
                font-size 18px
                font-weight 400
                margin-left 32px

            span
                font-size 18px
                font-weight 400
                color #F86801

    .pagination
        width 100%
        height 40px
        display flex
        justify-content flex-end
        align-items center
        margin-top 8px

.demo-loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
}

/*
.pagination >>> .ant-pagination-item-active{
    background: #E06A00 !important;
    border-color: #E06A00 !important;
    color: white !important;
}
.pagination >>> .ant-pagination-item-active a{
    color: white !important;
}
.pagination >>> .ant-pagination-item:hover{
    border: 1px #E06A00 solid!important;
    color: #E06A00 !important;
}
.pagination >>> .ant-pagination-item a:hover{
    color: #E06A00 !important;
}*/

</style>