<template>
  <a-modal
    title="部门新增"
    :visible="Visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    width="350px"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="上级部门" prop="fUpDepartmentID">
        <a-select v-model="form.fUpDepartmentID" placeholder="请选择上级部门">
          <a-select-option v-for="d in departmentdata" :key="d.fDepartmentID">
            {{ d.fDepartmentName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="部门负责人" prop="fEmployeeID">
        <a-select v-model="form.fEmployeeID" placeholder="请选择部门负责人">
          <a-select-option v-for="e in employeedata" :key="e.fEmployeeID">
            {{ e.fChineseName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="金蝶编号" prop="fKingdeeID">
        <a-input v-model="form.fKingdeeID" placeholder="请输入金蝶编号" />
      </a-form-model-item>
      <a-form-model-item label="部门编码" prop="fDepartmentNum">
        <a-input v-model="form.fDepartmentNum" placeholder="请输入部门编码" />
      </a-form-model-item>
      <a-form-model-item label="部门名称" prop="fDepartmentName">
        <a-input v-model="form.fDepartmentName" placeholder="请输入部门名称" />
      </a-form-model-item>
      <a-form-model-item label="工时类型" prop="fHourTypeID">
        <a-select v-model="form.fHourTypeID" placeholder="请选择工时类型">
          <a-select-option v-for="h in hourdata" :key="h.fBasicCodeID">
            {{ h.fCodeName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="公司" prop="fCompanyID">
        <a-select v-model="form.fCompanyID" placeholder="请选择公司">
          <a-select-option v-for="c in companydata" :key="c.fCompanyID">
            {{ c.fCompanyName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否填报工时" prop="fIsWorkingHours">
        <a-radio-group v-model="form.fIsWorkingHours">
          <a-radio value="T"> 是 </a-radio>
          <a-radio value="F"> 否 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="fDescription">
        <a-input v-model="form.fDescription" placeholder="请输入描述" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
  
<script>
import {
  SelectDepartmentsParent,
  SelecttEmployeesALL,
  SelecttHourType,
  SelecttCompaniesPaged,
  InsertOrUpdatetDepartment,
} from "../../api/Employee.js";
import moment from "moment";
export default {
  props: ["Visible"],
  data() {
    return {
      confirmLoading: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      form: {
        isModified: true,
        fDepartmentID: "00000000-0000-0000-0000-000000000000",
        fKingdeeID: undefined,
        fUpDepartmentID: undefined,
        fHourTypeID: undefined,
        fEmployeeID: undefined,
        fEnglishName: "",
        fChineseName: "",
        fUserNum: "",
        fUserName: "",
        fCompanyID: undefined,
        fDepartmentNum: undefined,
        fDepartmentName: undefined,
        fIsWorkingHours: undefined,
        fIsThirdLevel: "F",
        fDescription: "",
        fSpelling: "",
        fOrderValue: 0,
        fState: "T",
        fRemark: "",
        fCreateTime: undefined,
        fCreateUser: undefined,
        fChangedTime: undefined,
        fChangedUser: undefined,
      },
      rules: {
        fEmployeeID: [
          {
            required: true,
            message: "请选择部门负责人",
            trigger: "change",
          },
        ],
        fHourTypeID: [
          {
            required: true,
            message: "请选择工时类型",
            trigger: "change",
          },
        ],
        fCompanyID: [
          {
            required: true,
            message: "请选择公司",
            trigger: "change",
          },
        ],
        fDepartmentNum: [
          {
            required: true,
            message: "请输入部门编码",
            trigger: "blur",
          },
        ],
        fDepartmentName: [
          {
            required: true,
            message: "请输入部门名称",
            trigger: "blur",
          },
        ],
        fIsWorkingHours: [
          {
            required: true,
            message: "请选择是否填报",
            trigger: "change",
          },
        ],
      },
      departmentdata: [],
      employeedata: [],
      hourdata: [],
      companydata: [],
    };
  },
  mounted() {
    this.getDepartmentList();
    this.getEmployeeList();
    this.getHourList();
    this.getCompanyList();
  },
  methods: {
    handleOk() {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          this.form.fUpDepartmentID =
            this.form.fUpDepartmentID || "00000000-0000-0000-0000-000000000000";
          this.form.fChangedUser = user.fEmployeeID;
          this.form.fChangedTime = moment(new Date()).format();
          this.form.fCreateUser = user.fEmployeeID;
          this.form.fCreateTime = moment(new Date()).format();
          this.form.fKingdeeID = this.form.fKingdeeID || 0;
          var form = this.form;
          console.log(form, valid);
          InsertOrUpdatetDepartment(form).then((res) => {
            console.log(res);
            this.$emit("update:visible", false);
            this.confirmLoading = false;
          });
          // setTimeout(() => {
          //   this.$emit("update:visible", false);
          //   this.confirmLoading = false;
          // }, 2000);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCancel() {
      console.log("Clicked cancel button");
      this.$emit("update:visible", false);
    },
    getCompanyList(param = {}) {
      SelecttCompaniesPaged({
        Page: 1,
        PageNum: 10,
        ...param,
      }).then((res) => {
        this.companydata = res.result;
      });
    },
    getDepartmentList(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      SelectDepartmentsParent({
        UserID: user.fEmployeeID,
        ...param,
      }).then((res) => {
        this.departmentdata = res.result;
      });
    },
    getHourList(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      SelecttHourType({
        UserID: user.fEmployeeID,
        ...param,
      }).then((res) => {
        //console.log("gognshileixing", res);
        this.hourdata = res.result;
      });
    },
    getEmployeeList(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      SelecttEmployeesALL({
        UserID: user.fEmployeeID,
        ...param,
      }).then((res) => {
        this.employeedata = res.result;
      });
    },
  },
};
</script>
  
  <style></style>
  