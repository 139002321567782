import request from "../utils/request";
//'http://10.192.167.42:8084/api/SystemModule/GetStationModuleALL?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&StationID=14e60ac8-21f8-4cde-b923-c62bd33aee65'
export function GetStationModuleALL(params) {
    if (!params) {
        params = {
            UserID:'',
            StationID:'',
        }
    }
    const urlBitTxt = "/api/SystemModule/GetStationModuleALL?UserID=" + params.UserID+"&StationID="+params.StationID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/BasicData/SelecttStationsDynamic?UserID=56f639ce-fc4b-49a9-9fc3-67f1ce586c0e&WhereClause=%5BfState%5D%20%3D%27T%27&OrderField=%5BfOrderValue%5D'
export function SelecttStationsDynamic(params) {
    if (!params) {
        params = {
            UserID: '',
            WhereClause: '[fState] =\'T\'',
            OrderField: '[fOrderValue]',
        }
    }
    const urlBitTxt = "/api/BasicData/SelecttStationsDynamic?UserID=" + params.UserID + "&WhereClause=" + params.WhereClause + "&OrderField=" + params.OrderField;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}
//http://10.192.167.42:8084/api/SystemModule/GetModuleByStationOrModuleType?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&fModuleType=0a1708fb-6702-47e0-8e00-3773f5694fe3&StationID=14e60ac8-21f8-4cde-b923-c62bd33aee65
export function GetModuleByStationOrModuleType(params) {
    if (!params) {
        params = {
            UserID: '',
            fModuleType: '',
            StationID: '',
        }
    }
    const urlBitTxt = "/api/SystemModule/GetModuleByStationOrModuleType?UserID=" + params.UserID + "&fModuleType=" + params.fModuleType + "&StationID=" + params.StationID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}
//http://10.192.167.42:8084/api/SystemModule/GetStationModule?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&StationID=14e60ac8-21f8-4cde-b923-c62bd33aee65
export function GetStationModule(params) {
    if (!params) {
        params = {
            UserID: '',
            StationID: '',
        }
    }
    const urlBitTxt = "/api/SystemModule/GetStationModule?UserID=" + params.UserID + "&StationID=" + params.StationID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}
//http://10.192.167.42:8084/api/SystemModule/DeletetPermissionsByfStationIDOrModuleID?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&fStationID=14e60ac8-21f8-4cde-b923-c62bd33aee65&fSystemModuleID=012795fb-3ee8-4658-89d6-2539abf0642f
export function DeletetPermissionsByfStationIDOrModuleID(params) {
    if (!params) {
        params = {
            UserID: '',
            fStationID: '',
            fSystemModuleID:''
        }
    }
    const urlBitTxt = "/api/SystemModule/DeletetPermissionsByfStationIDOrModuleID?UserID=" + params.UserID + "&fStationID=" + params.fStationID+ "&fSystemModuleID=" + params.fSystemModuleID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}
//ttp://10.192.167.42:8084/api/SystemModule/InsertRolePermissions?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be
export function InsertRolePermissions(params,data) {
    if (!params) {
        params = {
            UserID: '',
        }
    }
    const urlBitTxt = "/api/SystemModule/InsertRolePermissions?UserID=" + params.UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: data
    }
    console.log(urlBitTxt)
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}
