<template>
    <div id="Calendar">
        <div class="title">
            <span>工时报表</span>
        </div>
        <div class="div_tabs">
            <div class="inputs">
                <div class="forms">
                    <span>工作时间：</span>
                    <el-time-picker
                        style="width: 180px"
                        size="mini"
                        is-range
                        v-model="value1"
                        range-separator="——"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                    </el-time-picker>
                </div>
                <div class="forms">
                    <span>休息时间：</span>
                    <el-time-picker
                        style="width: 180px"
                        size="mini"
                        is-range
                        v-model="value2"
                        range-separator="——"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                    </el-time-picker>
                </div>
                <div class="forms">
                    <span>选择日期：</span>
                    <el-date-picker
                        style="width: 180px"
                        size="mini"
                        v-model="years"
                        type="month"
                        format="yyyy-MM"
                        placeholder="选择日期"
                        @change="goYears">
                    </el-date-picker>
                </div>
            </div>
            <div class="buttons">
                <el-button size="mini" style="margin-right: 12px">创建</el-button>
                <el-button size="mini">修改</el-button>
            </div>
        </div>
        <div id="el-calendar">
            <table class="el-calendar-table is-range" cellspacing="0" cellpadding="0">
                <thead>
                <th>星期日</th>
                <th>星期一</th>
                <th>星期二</th>
                <th>星期三</th>
                <th>星期四</th>
                <th>星期五</th>
                <th>星期六</th>
                </thead>
                <tbody>
                <tr v-for="(item,index) in CalenderData" :key="index">
                    <td v-for="(item1,index1) in item.ltCalendarItems" :key="index1">
                        <div class="calendar-day"
                             :class="{backgroundColor_day_blue:item1.fWorkType==='E',
                             backgroundColor_day_green:item1.fWorkType==='O',
                             backgroundColor_day_yellow:item1.fWorkType==='H'}"
                             @click="updateWorkType(item1)">
                            <span
                                :class="{color_span_calendar:moment(years).format('M')+''!==item1.fMonth+''}">{{
                                    item1.showDay
                                }}</span><br>
                            <span v-if="item1.fWorkType==='E'">休息日</span>
                            <span v-else-if="item1.fWorkType==='O'">工作日</span>
                            <span v-else-if="item1.fWorkType==='H'">节假日</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {SelecttCalendarsMain, GetCalendarItemMonth, UpdateCalendarItemWorkType} from "../../api/WorkingCalendar"

export default {
    name: "Calendar",
    data() {
        return {
            loading: "",
            color: "#5bae33",
            activeName: 'first',
            value1: "",
            value2: "",
            value3: "",
            value4: "",
            years: this.$moment().format("YYYY-MM"),
            day: this.$moment().format("MM"),
            userId: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,//获取用户id
            fCalendarID: "",
            CalenderData: [],
            moment: this.$moment
        }
    },
    mounted() {
        console.log(this.$moment().format("YYYY-MM"))
        this._SelecttCalendars()
    },
    methods: {
        openLoading() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        updateWorkType(obj) {
            console.log(obj)
            let param = {}
            if (obj.fWorkType === 'E') {
                console.log(obj)
                param = {
                    UserID: this.userId,
                    CalendarID: this.fCalendarID,
                    CalendarItemID: obj.fCalendarItemID,
                    WorkType: 'O'
                }
                this._UpdateCalendarItemWorkType(param)
            } else if (obj.fWorkType === 'O') {
                console.log(obj)
                param = {
                    UserID: this.userId,
                    CalendarID: this.fCalendarID,
                    CalendarItemID: obj.fCalendarItemID,
                    WorkType: 'H'
                }
                this._UpdateCalendarItemWorkType(param)
            } else if (obj.fWorkType === 'H') {
                console.log(obj)
                param = {
                    UserID: this.userId,
                    CalendarID: this.fCalendarID,
                    CalendarItemID: obj.fCalendarItemID,
                    WorkType: 'E'
                }
                this._UpdateCalendarItemWorkType(param)
            }
        },
        _UpdateCalendarItemWorkType(param) {
            UpdateCalendarItemWorkType(param).then(res => {
                if (res.result === true) {
                    this._GetCalendarItemMonth()
                }
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
        },
        _SelecttCalendars() {
            SelecttCalendarsMain(this.userId).then(res => {
                this.fCalendarID = res.result[0].fCalendarID
                console.log(res.result[0].fCalendarID)
                this._GetCalendarItemMonth()
            }).catch(err => {
                console.log(err)
            })
        },
        _GetCalendarItemMonth() {
            console.log(this.$moment(this.years).format('M'))
            console.log(this.years)
            this.openLoading()
            const params = {
                UserID: this.userId,
                CalendarID: this.fCalendarID,
                Year: this.years.split("-")[0],
                Month: this.years.split("-")[1]
            }
            console.log(params)
            GetCalendarItemMonth(params).then(res => {
                console.log(res)
                this.CalenderData = res.result
                this.loading.close();
            }).catch(err => {
                console.log(err)
            })
        },
        goYears() {
            this.years = this.$moment(this.years).format("YYYY-MM")
            console.log(this.$moment(this.years).format("YYYY-MM"))
            this._GetCalendarItemMonth()
        },
        handleClick(tab, event) {
            console.log(tab, event);
        }
    }
}
</script>

<style scoped lang="stylus">
#Calendar
    width 100%
    height 100%

    .title
        width 100%
        border-bottom solid 1px #E9E9E9
        box-sizing border-box
        padding-bottom 16px

        span
            font-weight 500
            font-size 16px
            margin-left 25px
            color rgba(0, 0, 0, 0.85)

    .div_tabs
        width 100%
        height 70px
        display flex
        flex-direction row

        .inputs
            width 80%
            height 100%
            display flex
            flex-direction row
            justify-content flex-start
            align-items center
            padding-left 24px
            box-sizing border-box

            .forms
                margin-right 40px

        .buttons
            width 20%
            height 100%
            display flex
            flex-direction row
            justify-content flex-end
            align-items center
            padding-right 32px
            box-sizing border-box

            .el-button
                width 65px
                height 32px
                background #FFEAD8
                border 1px solid #ED6D01
                font-weight 400
                color #ED6D01
                font-size 14px
                margin-left 12px

            .el-button:hover
                color #ED6D01
                background #fadec6

    #el-calendar
        margin 0 16px

        table
            display: table;
            border-collapse: separate;
            box-sizing: border-box;
            text-indent: initial;
            border-color: grey;

        .el-calendar-table
            table-layout fixed
            width 100%

            thead th:first-child
                border-left 1px solid #B8B8B8

            thead:first-child th
                border-top 1px solid #B8B8B8

            th
                font-size 18px
                font-weight 400
                color #333333
                text-align center
                border-right 1px solid #B8B8B8
                vertical-align top
                transition background-color .2s ease

            .calendar-day
                box-sizing border-box
                padding 8px
                height 60px
                cursor pointer

                span
                    font-size 16px

            tr td:first-child
                border-left 1px solid #B8B8B8

            tr:first-child td
                border-top 1px solid #B8B8B8

            td
                border-bottom 1px solid #B8B8B8
                border-right 1px solid #B8B8B8
                vertical-align top
                transition background-color .2s ease

.color_span_calendar
    color #c0c4cc

.backgroundColor_day_blue
    background-color #80DBAA

.backgroundColor_day_green
    background-color #FFFFFF

.backgroundColor_day_yellow
    background-color #FFD4B0
</style>