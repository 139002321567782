import request from '../utils/request'

//'http://10.192.167.42:8084/api/WorkingHours/ReadWorkingHours?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&ReadDay=2022-10-27' \
export function ReadWorkingHours(params) {
    if (!params) {
        params = {
            UserID: '',
            ReadDay: ''
        }
    }
    const urlBitTxt = "/api/WorkingHours/ReadWorkingHours?UserID=" + params.UserID + "&ReadDay=" + params.ReadDay;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/WorkingHours/GetSheetDetail?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&WorkingDate=2022-11-2&Order=1' \
export function GetSheetDetail(params) {
    if (!params) {
        params = {
            UserID: '',
            WorkingDate: '',
            Order: 0
        }
    }
    const urlBitTxt = "/api/WorkingHours/GetSheetDetail?UserID=" + params.UserID + "&WorkingDate=" + params.WorkingDate + "&Order=" + params.Order;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//http://10.192.167.42:8084/api/WorkingHours/GetParticipateProject?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be'
export function GetParticipateProject(params) {
    if (!params) {
        params = {
            UserID: '',
        }
    }
    const urlBitTxt = "/api/WorkingHours/GetParticipateProject?UserID=" + params.UserID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//'http://10.192.167.42:8084/api/WorkingHours/GetProjectTask?UserID=196e729b-3cf3-48cd-8c79-1dfc8267d8be&ProjectID=00000000-0000-0000-0000-000000000000' \
export function GetProjectTask(params) {
    if (!params) {
        params = {
            UserID: '',
            ProjectID: ''
        }
    }
    const urlBitTxt = "/api/WorkingHours/GetProjectTask?UserID=" + params.UserID + "&ProjectID=" + params.ProjectID;
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'GET'
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}

//http://10.192.167.42:8084/api/WorkingHours/WriteWorkingHours
export function WriteWorkingHours(params) {
    if (!params) {
        params = {}
    }
    const urlBitTxt = "/api/WorkingHours/WriteWorkingHours";
    const jsonData = {
        urlBit: urlBitTxt,
        method: 'POST',
        data: params
    }
    return new Promise((resolve, reject) => {
        request(jsonData).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    });
}