<template>
    <div id="fillin">
        <div class="title">
            <span>工时填报</span>
        </div>
        <el-form :model="WorkingHourData" ref="dynamicValidateForm">
            <div class="select_operation">
                <div class="el_button">
                    <el-button size="mini" style="margin-right: 12px" @click="tableAdd">新增</el-button>
                    <el-button size="mini" style="margin-right: 12px" @click="save">保存</el-button>
                    <el-button size="mini" @click="submit('dynamicValidateForm')">提交</el-button>
                </div>
                <div class="el_button">
                    <el-button class="period_button" @click="getBeforeDateMinus" v-show="PeriodShow" size="medium"
                               type="primary"
                               icon="el-icon-arrow-left">
                        上个期间
                    </el-button>
                    <el-button class="period_button" @click="getBeforeDateAdd" v-show="!PeriodShow" size="medium"
                               type="warning">下个期间<i
                        class="el-icon-arrow-right el-icon--right"></i></el-button>
                    <el-button class="present" @click="BelowPeriod" size="medium">当前</el-button>
                </div>
            </div>
            <div class="demo-infinite-container"
                 :infinite-scroll-disabled="busy"
                 :infinite-scroll-distance="10">
                <table class="table">
                    <tr class="table_header">
                        <td class="forTdBody">操作</td>
                        <td class="tdBorderBottom" style="width: 400px">所属项目</td>
                        <td class="tdBorderBottom">所属任务</td>
                        <td class="forTdHeader"
                            :class="{'WhiteBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fSundayType==='O',
                        'greenBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fSundayType==='E',
                        'yellowBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fSundayType==='H'}">
                    <span>{{
                            $moment(WorkingHourData.ltProjectTimeSheetDetails[0].fSundayDate).format("MM/DD")
                        }}</span><br/>
                            <span>星期日</span>
                        </td>
                        <td class="forTdHeader"
                            :class="{'WhiteBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fMondayType==='O',
                        'greenBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fMondayType==='E',
                        'yellowBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fMondayType==='H'}">
                    <span>{{
                            $moment(WorkingHourData.ltProjectTimeSheetDetails[0].fMondayDate).format("MM/DD")
                        }}</span><br/>
                            <span>星期一</span>

                        </td>
                        <td class="forTdHeader"
                            :class="{'WhiteBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fTuesdayType==='O',
                        'greenBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fTuesdayType==='E',
                        'yellowBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fTuesdayType==='H'}">
                    <span>{{
                            $moment(WorkingHourData.ltProjectTimeSheetDetails[0].fTuesdayDate).format("MM/DD")
                        }}</span><br/>
                            <span>星期二</span>
                        </td>
                        <td class="forTdHeader"
                            :class="{'WhiteBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fWednesdayType==='O',
                        'greenBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fWednesdayType==='E',
                        'yellowBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fWednesdayType==='H'}">
                    <span>{{
                            $moment(WorkingHourData.ltProjectTimeSheetDetails[0].fWednesdayDate).format("MM/DD")
                        }}</span><br/>
                            <span>星期三</span>
                        </td>
                        <td class="forTdHeader"
                            :class="{'WhiteBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fThursdayType==='O',
                        'greenBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fThursdayType==='E',
                        'yellowBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fThursdayType==='H'}">
                    <span>{{
                            $moment(WorkingHourData.ltProjectTimeSheetDetails[0].fThursdayDate).format("MM/DD")
                        }}</span><br/>
                            <span>星期四</span>
                        </td>
                        <td class="forTdHeader"
                            :class="{'WhiteBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fFridayType==='O',
                        'greenBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fFridayType==='E',
                        'yellowBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fFridayType==='H'}">
                    <span>{{
                            $moment(WorkingHourData.ltProjectTimeSheetDetails[0].fFridayDate).format("MM/DD")
                        }}</span><br/>
                            <span>星期五</span>
                        </td>
                        <td class="forTdHeader"
                            :class="{'WhiteBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fSaturdayType==='O',
                        'greenBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fSaturdayType==='E',
                        'yellowBackground':WorkingHourData.ltProjectTimeSheetDetails[0].fSaturdayType==='H'}">
                    <span>{{
                            $moment(WorkingHourData.ltProjectTimeSheetDetails[0].fSaturdayDate).format("MM/DD")
                        }}</span><br/>
                            <span>星期六</span>
                        </td>
                        <td class="forTdHeader" style="width: 40px">总计(小时)</td>
                    </tr>
                    <tr class="table_body" v-for="(item,index) in WorkingHourData.ltProjectTimeSheetDetails"
                        :key="index">
                        <td class="forTdBody" style="min-width: 60px">
                            <a-icon type="plus-circle" class="table-add" @click="tableAdd"/>
                            <a-icon type="minus-circle" class="table-delete" @click="tableDelete(index)"/>
                        </td>
                        <td class="tdBorderBottom">
                            <el-select style="width: 100%;min-width: 130px" size="medium" v-model="item.fProjectID"
                                       :disabled="item.disabled" placeholder="请选择" @change="GetProjectTask(item,index)">
                                <el-option
                                    style="color: #282727!important;"
                                    v-for="item in ParticipateProjectData"
                                    :key="item.fProjectID"
                                    :label="item.fProjectNum+'-'+item.fProjectName+'（剩余工时: '+item.workingHoursSurplus+'）'"
                                    :value="item.fProjectID">
                                </el-option>
                            </el-select>
                        </td>
                        <td class="tdBorderBottom">
                            <el-popover
                                placement="bottom-end"
                                width="400"
                                trigger="click"
                                v-model="item.isModified">
                                <el-tree default-expand-all :data="ProjectTaskData" :props="defaultProps"
                                         @node-click="(data) => handleNodeClick(data,index,'dynamicValidateForm',item)"></el-tree>
                                <el-form-item style="margin-bottom: 0!important;" slot="reference"
                                              :prop="'ltProjectTimeSheetDetails['+index+'].fTaskName'"
                                              :rules="[
                                {required: true,message:' ', trigger: 'blur' }]">
                                    <el-input style="min-width: 130px;" :disabled="item.disabled" size="medium"
                                              v-model="item.fTaskName"></el-input>
                                </el-form-item>
                            </el-popover>
                        </td>
                        <td class="forTdBody" :class="{'bodyTop':item.fSundayContent!==''}" style="position: relative;">
                            <el-popover
                                popper-class="popperOptions"
                                placement="bottom-end"
                                width="400"
                                trigger="click">
                                <el-form ref="form" label-width="90px">
                                    <el-form-item label="办公地点">
                                        <el-input :disabled="item.disabled&&showDay.SunShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fSundayIocation"></el-input>
                                    </el-form-item>
                                    <el-form-item label="工作日志">
                                        <el-input :disabled="item.disabled&&showDay.SunShow" size="medium"
                                                  type="textarea"
                                                  resize="none" rows="6"
                                                  placeholder="请输入内容"
                                                  maxlength="400" show-word-limit
                                                  v-model="item.fSundayContent"></el-input>
                                    </el-form-item>
                                    <el-form-item label="处理问题">
                                        <el-input :disabled="item.disabled&&showDay.SunShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fSundayProblem"></el-input>
                                    </el-form-item>
                                    <el-form-item label="参与人">
                                        <el-input :disabled="item.disabled&&showDay.SunShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fSundayPersonnel"></el-input>
                                    </el-form-item>
                                    <el-form-item label="状态">
                                        <span>{{ item.state }}</span>
                                    </el-form-item>
                                </el-form>
                                <el-input slot="reference" size="medium"
                                          style="min-width: 80px"
                                          @keyup.native="upInput(index,'7')"
                                          oninput="if(value>24)value=24;if(value.length>2)value=value.slice(0,2);if(value<0)value=0;value=value.replace(/^(0+)|[^\d]+/g,'')"
                                          v-model.number="item.fSunday"
                                          :disabled="item.disabled&&showDay.SunShow"></el-input>
                            </el-popover>
                        </td>
                        <td class="forTdBody" :class="{'bodyTop':item.fMondayContent!==''}" style="position: relative;">
                            <el-popover
                                popper-class="popperOptions"
                                placement="bottom-end"
                                width="400"
                                trigger="click">
                                <el-form ref="form" label-width="90px">
                                    <el-form-item label="办公地点">
                                        <el-input :disabled="item.disabled&&showDay.MonShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fMondayIocation"></el-input>
                                    </el-form-item>
                                    <el-form-item label="工作日志">
                                        <el-input :disabled="item.disabled&&showDay.MonShow" size="medium"
                                                  type="textarea"
                                                  resize="none" rows="6"
                                                  placeholder="请输入内容"
                                                  maxlength="400" show-word-limit
                                                  v-model="item.fMondayContent"></el-input>
                                    </el-form-item>
                                    <el-form-item label="处理问题">
                                        <el-input :disabled="item.disabled&&showDay.MonShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fMondayProblem"></el-input>
                                    </el-form-item>
                                    <el-form-item label="参与人">
                                        <el-input :disabled="item.disabled&&showDay.MonShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fMondayPersonnel"></el-input>
                                    </el-form-item>
                                    <el-form-item label="状态">
                                        <span>{{ item.state }}</span>
                                    </el-form-item>
                                </el-form>
                                <el-input slot="reference" size="medium"
                                          style="min-width: 80px"
                                          @keyup.native="upInput(index,'1')"
                                          oninput="if(value>24)value=24;if(value.length>2)value=value.slice(0,2);if(value<0)value=0;value=value.replace(/^(0+)|[^\d]+/g,'')"
                                          v-model.number="item.fMonday"
                                          :disabled="item.disabled&&showDay.MonShow"></el-input>
                            </el-popover>
                        </td>
                        <td class="forTdBody" :class="{'bodyTop':item.fTuesdayContent!==''}"
                            style="position: relative;">
                            <el-popover
                                placement="bottom-end"
                                width="400"
                                trigger="click">
                                <el-form ref="form" label-width="90px">
                                    <el-form-item label="办公地点">
                                        <el-input :disabled="item.disabled&&showDay.TuesShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fTuesdayIocation"></el-input>
                                    </el-form-item>
                                    <el-form-item label="工作日志">
                                        <el-input :disabled="item.disabled&&showDay.TuesShow" size="medium"
                                                  type="textarea"
                                                  resize="none" rows="6"
                                                  placeholder="请输入内容"
                                                  maxlength="400" show-word-limit
                                                  v-model="item.fTuesdayContent"></el-input>
                                    </el-form-item>
                                    <el-form-item label="处理问题">
                                        <el-input :disabled="item.disabled&&showDay.TuesShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fTuesdayProblem"></el-input>
                                    </el-form-item>
                                    <el-form-item label="参与人">
                                        <el-input :disabled="item.disabled&&showDay.TuesShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fTuesdayPersonnel"></el-input>
                                    </el-form-item>
                                    <el-form-item label="状态">
                                        <span>{{ item.state }}</span>
                                    </el-form-item>
                                </el-form>
                                <el-input slot="reference" size="medium"
                                          style="min-width: 80px"
                                          @keyup.native="upInput(index,'2')"
                                          oninput="if(value>24)value=24;if(value.length>2)value=value.slice(0,2);if(value<0)value=0;value=value.replace(/^(0+)|[^\d]+/g,'')"
                                          v-model.number="item.fTuesday"
                                          :disabled="item.disabled&&showDay.TuesShow"></el-input>
                            </el-popover>
                        </td>
                        <td class="forTdBody" :class="{'bodyTop':item.fWednesdayContent!==''}"
                            style="position: relative;">
                            <el-popover
                                placement="bottom-end"
                                width="400"
                                trigger="click">
                                <el-form ref="form" label-width="90px">
                                    <el-form-item label="办公地点">
                                        <el-input :disabled="item.disabled&&showDay.WednesShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fWednesdayIocation"></el-input>
                                    </el-form-item>
                                    <el-form-item label="工作日志">
                                        <el-input :disabled="item.disabled&&showDay.WednesShow" size="medium"
                                                  type="textarea"
                                                  resize="none" rows="6"
                                                  placeholder="请输入内容"
                                                  maxlength="400" show-word-limit
                                                  v-model="item.fWednesdayContent"></el-input>
                                    </el-form-item>
                                    <el-form-item label="处理问题">
                                        <el-input :disabled="item.disabled&&showDay.WednesShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fWednesdayProblem"></el-input>
                                    </el-form-item>
                                    <el-form-item label="参与人">
                                        <el-input :disabled="item.disabled&&showDay.WednesShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fWednesdayPersonnel"></el-input>
                                    </el-form-item>
                                    <el-form-item label="状态">
                                        <span>{{ item.state }}</span>
                                    </el-form-item>
                                </el-form>
                                <el-input slot="reference" size="medium"
                                          style="min-width: 80px"
                                          @keyup.native="upInput(index,'3')"
                                          oninput="if(value>24)value=24;if(value.length>2)value=value.slice(0,2);if(value<0)value=0;value=value.replace(/^(0+)|[^\d]+/g,'')"
                                          v-model.number="item.fWednesday"
                                          :disabled="item.disabled&&showDay.WednesShow"></el-input>
                            </el-popover>
                        </td>
                        <td class="forTdBody" :class="{'bodyTop':item.fThursdayContent!==''}"
                            style="position: relative;">
                            <el-popover
                                placement="bottom-end"
                                width="400"
                                trigger="click">
                                <el-form ref="form" label-width="90px">
                                    <el-form-item label="办公地点">
                                        <el-input :disabled="item.disabled&&showDay.ThursShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fThursdayIocation"></el-input>
                                    </el-form-item>
                                    <el-form-item label="工作日志">
                                        <el-input :disabled="item.disabled&&showDay.ThursShow" size="medium"
                                                  type="textarea"
                                                  resize="none" rows="6"
                                                  placeholder="请输入内容"
                                                  maxlength="400" show-word-limit
                                                  v-model="item.fThursdayContent"></el-input>
                                    </el-form-item>
                                    <el-form-item label="处理问题">
                                        <el-input :disabled="item.disabled&&showDay.ThursShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fThursdayProblem"></el-input>
                                    </el-form-item>
                                    <el-form-item label="参与人">
                                        <el-input :disabled="item.disabled&&showDay.ThursShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fThursdayPersonnel"></el-input>
                                    </el-form-item>
                                    <el-form-item label="状态">
                                        <span>{{ item.state }}</span>
                                    </el-form-item>
                                </el-form>
                                <el-input slot="reference" size="medium"
                                          style="min-width: 80px"
                                          @keyup.native="upInput(index,'4')"
                                          oninput="if(value>24)value=24;if(value.length>2)value=value.slice(0,2);if(value<0)value=0;value=value.replace(/^(0+)|[^\d]+/g,'')"
                                          v-model.number="item.fThursday"
                                          :disabled="item.disabled&&showDay.ThursShow"></el-input>
                            </el-popover>
                        </td>
                        <td class="forTdBody" :class="{'bodyTop':item.fFridayContent!==''}" style="position: relative;">
                            <el-popover
                                placement="bottom-end"
                                width="400"
                                trigger="click">
                                <el-form ref="form" label-width="90px">
                                    <el-form-item label="办公地点">
                                        <el-input :disabled="item.disabled&&showDay.FriShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fFridayIocation"></el-input>
                                    </el-form-item>
                                    <el-form-item label="工作日志">
                                        <el-input :disabled="item.disabled&&showDay.FriShow" size="medium"
                                                  type="textarea"
                                                  resize="none" rows="6"
                                                  placeholder="请输入内容"
                                                  maxlength="400" show-word-limit
                                                  v-model="item.fFridayContent"></el-input>
                                    </el-form-item>
                                    <el-form-item label="处理问题">
                                        <el-input :disabled="item.disabled&&showDay.FriShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fFridayProblem"></el-input>
                                    </el-form-item>
                                    <el-form-item label="参与人">
                                        <el-input :disabled="item.disabled&&showDay.FriShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fFridayPersonnel"></el-input>
                                    </el-form-item>
                                    <el-form-item label="状态">
                                        <span>{{ item.state }}</span>
                                    </el-form-item>
                                </el-form>
                                <el-input slot="reference" size="medium"
                                          style="min-width: 80px"
                                          @keyup.native="upInput(index,'5')"
                                          oninput="if(value>24)value=24;if(value.length>2)value=value.slice(0,2);if(value<0)value=0;value=value.replace(/^(0+)|[^\d]+/g,'')"
                                          v-model.number="item.fFriday"
                                          :disabled="item.disabled&&showDay.FriShow"></el-input>
                            </el-popover>
                        </td>
                        <td class="forTdBody" :class="{'bodyTop':item.fSaturdayContent!==''}"
                            style="position: relative;">
                            <el-popover
                                placement="bottom-end"
                                width="400"
                                trigger="click">
                                <el-form ref="form" label-width="90px">
                                    <el-form-item label="办公地点">
                                        <el-input :disabled="item.disabled&&showDay.SaturShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fSaturdayIocation"></el-input>
                                    </el-form-item>
                                    <el-form-item label="工作日志">
                                        <el-input :disabled="item.disabled&&showDay.SaturShow" size="medium"
                                                  type="textarea"
                                                  resize="none" rows="6"
                                                  placeholder="请输入内容"
                                                  maxlength="400" show-word-limit
                                                  v-model="item.fSaturdayContent"></el-input>
                                    </el-form-item>
                                    <el-form-item label="处理问题">
                                        <el-input :disabled="item.disabled&&showDay.SaturShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fSaturdayProblem"></el-input>
                                    </el-form-item>
                                    <el-form-item label="参与人">
                                        <el-input :disabled="item.disabled&&showDay.SaturShow" placeholder="请输入内容"
                                                  size="medium"
                                                  v-model="item.fSaturdayPersonnel"></el-input>
                                    </el-form-item>
                                    <el-form-item label="状态">
                                        <span>{{ item.state }}</span>
                                    </el-form-item>
                                </el-form>
                                <el-input slot="reference" size="medium"
                                          style="min-width: 80px"
                                          @keyup.native="upInput(index,'6')"
                                          oninput="if(value>24)value=24;if(value.length>2)value=value.slice(0,2);if(value<0)value=0;value=value.replace(/^(0+)|[^\d]+/g,'')"
                                          v-model.number="item.fSaturday"
                                          :disabled="item.disabled&&showDay.SaturShow"></el-input>
                            </el-popover>
                        </td>
                        <td class="forTdHeader" style="width: 40px;text-align: right">
                            <span>{{ item.fWeekHour }}</span>
                        </td>
                    </tr>
                    <tr class="table_bottom">
                        <td class="forTdBody"></td>
                        <td class="tdBorderBottom"></td>
                        <td class="tdBorderBottom" style="text-align: right;">总计(小时)</td>
                        <td class="tdBorderBottom" style="text-align: right;">{{ HourSum.Sunday }}</td>
                        <td class="tdBorderBottom" style="text-align: right;">{{ HourSum.Monday }}</td>
                        <td class="tdBorderBottom" style="text-align: right;">{{ HourSum.Tuesday }}</td>
                        <td class="tdBorderBottom" style="text-align: right;">{{ HourSum.Wednesday }}</td>
                        <td class="tdBorderBottom" style="text-align: right;">{{ HourSum.Thursday }}</td>
                        <td class="tdBorderBottom" style="text-align: right;">{{ HourSum.Friday }}</td>
                        <td class="tdBorderBottom" style="text-align: right;">{{ HourSum.Saturday }}</td>
                        <td class="tdBorderBottom" style="width: 40px;text-align: right">{{ HourSum.total }}</td>
                    </tr>
                </table>
            </div>
            <div class="data_sum">
                <div class="data_sum_div1">
                    <el-tag style="background-color: #FFFFFF;padding: 3px 10px;border-radius: 5px;" type="info">
                        <span style="color: #666666;font-weight: 400;">项目剩余工时: {{ workingHoursSurplus }}小时</span>
                    </el-tag>
                    <el-tag type="info"
                            style="margin-left: 40px;background-color: white;padding: 3px 13px;border-radius: 5px;">
                        <span style="color: #ED6D01">已保存({{ saveData }})</span>
                    </el-tag>
                    <el-tag type="info"
                            style="margin-left: 24px;background-color: white;padding: 3px 13px;border-radius: 5px;">
                        <span style="color: #FF4109;">已退回</span>
                    </el-tag>
                    <el-tag type="info"
                            style="margin-left: 24px;background-color: white;padding: 3px 13px;border-radius: 5px;">
                        <span style="color: #06B054;">已批准</span>
                    </el-tag>
                    <el-tag type="info"
                            style="margin-left: 24px;background-color: white;padding: 3px 13px;border-radius: 5px;">
                        <span style="color: #ED6D01;">包含日志</span>
                    </el-tag>
                </div>
                <div class="data_sum_div2">
                    <el-tag type="info"
                            style="margin-left: 10px;background-color: white;padding: 3px 13px;border-radius: 5px;border-color: #CDCDCD;">
                        <span style="color: #666666;font-weight: 400">正常工时: {{
                                HourSum.Monday + HourSum.Tuesday + HourSum.Wednesday + HourSum.Thursday + HourSum.Friday
                            }}小时</span>
                    </el-tag>
                    <el-tag type="info"
                            style="margin-left: 10px;background-color: white;padding: 3px 13px;border-radius: 5px;border-color: #80DBAA;">
                        <span style="color: #06B054;font-weight: 400">休假工时: 0小时</span>
                    </el-tag>
                    <el-tag type="info"
                            style="margin-left: 10px;background-color: white;padding: 3px 13px;border-radius: 5px;border-color: #FF3B02;">
                        <span style="color: #FF3B02;font-weight: 400">加班工时: {{
                                HourSum.Sunday + HourSum.Saturday
                            }}小时</span>
                    </el-tag>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import {
    GetParticipateProject,
    GetProjectTask,
    GetSheetDetail,
    ReadWorkingHours,
    WriteWorkingHours
} from "../../api/WorkingHours";
import infiniteScroll from 'vue-infinite-scroll';

export default {
    directives: {infiniteScroll},
    name: "FillIn",
    data() {
        return {
            busy: false,
            rules: {
                WorkingHourData: {
                    ltProjectTimeSheetDetails: {
                        fTaskName: [
                            {required: true, message: ' ', trigger: 'blur'}
                        ]
                    }
                }
            },
            loading: "",
            PeriodShow: true,
            options: [{
                value: '00000000-0000-0000-0000-000000000000',
                label: '00-非项目工时'
            }],
            userId: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,//获取用户id
            UserData: JSON.parse(localStorage.getItem("AdminUser")),
            ReadDay: this.$moment().format("YYYY-MM-DD"), // 获取当前时间
            // lastWeekDay: this.getBeforeDate(7),//上个期间所需日期
            lastWeekDay: this.$moment().format("YYYY-MM-DD"),//上个期间所需日期
            WorkingHourData: {
                "errMsg": "",
                "isConsistent": false,
                "isModified": false,
                "fTimeSheetMasterID": "9d23f808-09a0-4cae-817f-f37c48b28429",
                "fUserId": "196e729b-3cf3-48cd-8c79-1dfc8267d8be",
                "fYear": 2022,
                "fWeekNumber": 45,
                "weekNumber": 1,
                "startDay": "0001-01-01T00:00:00",
                "fOrderValue": 0,
                "fIsLock": "F",
                "fState": "",
                "fRemark": "",
                "fCreateTime": "2022-11-02T14:34:54.198274+08:00",
                "fCreateUser": "196e729b-3cf3-48cd-8c79-1dfc8267d8be",
                "fChangedTime": "2022-11-02T14:34:54.1982748+08:00",
                "fChangedUser": "196e729b-3cf3-48cd-8c79-1dfc8267d8be",
                "ltProjectTimeSheetDetails": [
                    {
                        "isModified": false,
                        "fTimeSheetDetailID": "4af7b565-98e0-4c22-9719-9f0e3f769264",
                        "fTimeSheetMasterID": "00000000-0000-0000-0000-000000000000",
                        "fYear": 0,
                        "fWeekNumber": 0,
                        "fUserId": "196e729b-3cf3-48cd-8c79-1dfc8267d8be",
                        "fProjectID": "00000000-0000-0000-0000-000000000000",
                        "fProjectTaskID": "00000000-0000-0000-0000-000000000000",
                        "fSunday": 0,
                        "fSundayDate": "2022-10-30T00:00:00",
                        "fSundayIocation": "",
                        "fSundayContent": "",
                        "fSundayProblem": "",
                        "fSundayPersonnel": "",
                        "fMonday": 0,
                        "fMondayDate": "2022-10-31T00:00:00",
                        "fMondayIocation": "",
                        "fMondayContent": "",
                        "fMondayProblem": "",
                        "fMondayPersonnel": "",
                        "fTuesday": 0,
                        "fTuesdayDate": "2022-11-01T00:00:00",
                        "fTuesdayIocation": "",
                        "fTuesdayContent": "",
                        "fTuesdayProblem": "",
                        "fTuesdayPersonnel": "",
                        "fWednesday": 0,
                        "fWednesdayDate": "2022-11-02T00:00:00",
                        "fWednesdayIocation": "",
                        "fWednesdayContent": "",
                        "fWednesdayProblem": "",
                        "fWednesdayPersonnel": "",
                        "fThursday": 0,
                        "fThursdayDate": "2022-11-03T00:00:00",
                        "fThursdayIocation": "",
                        "fThursdayContent": "",
                        "fThursdayProblem": "",
                        "fThursdayPersonnel": "",
                        "fFriday": 0,
                        "fFridayDate": "2022-11-04T00:00:00",
                        "fFridayIocation": "",
                        "fFridayContent": "",
                        "fFridayProblem": "",
                        "fFridayPersonnel": "",
                        "fSaturday": 0,
                        "fSaturdayDate": "2022-11-05T00:00:00",
                        "fSaturdayIocation": "",
                        "fSaturdayContent": "",
                        "fSaturdayProblem": "",
                        "fSaturdayPersonnel": "",
                        "fWeekHour": 0,
                        "fLabourRate": 0,
                        "fHourAmount": 0,
                        "fOrderValue": 0,
                        "fTaskName": "",
                        "fIsLock": "",
                        "fState": "",
                        "fRemark": "",
                        "fCreateTime": "0001-01-01T00:00:00",
                        "fCreateUser": "00000000-0000-0000-0000-000000000000",
                        "fChangedTime": "0001-01-01T00:00:00",
                        "fChangedUser": "00000000-0000-0000-0000-000000000000",
                        "weekHourALL": 0,
                        "ltProjectTimeSheetRecords": [],
                        "ltProjectTimeSheetApprovalRecords": []
                    }
                ]
            },
            ParticipateProjectData: [{
                fProjectID: '00000000-0000-0000-0000-000000000000',
                fProjectNum: '00',
                fProjectName: '非项目工时',
                workingHoursSurplus: 0
            }],
            workingHoursSurplus: 0,
            ProjectTaskData: [],
            defaultProps: {
                children: 'ltProjectTask',
                label: 'fTaskName'
            },
            HourSum: {
                Sunday: 0,
                Monday: 0,
                Tuesday: 0,
                Wednesday: 0,
                Thursday: 0,
                Friday: 0,
                Saturday: 0,
                total: 0
            },
            saveData: 0,
            showDay: {
                SunShow: false,
                MonShow: false,
                TuesShow: false,
                WednesShow: false,
                ThursShow: false,
                FriShow: false,
                SaturShow: false
            },
            popoverShow: false
        }
    },
    mounted() {
        // console.log(this.$moment().format("YYYY-MM-DDTHH:ss:mm"))
        // console.log(JSON.parse(localStorage.getItem("AdminUser")))
        this._ReadWorkingHours(this.ReadDay)
        this._GetParticipateProject()
        this.GetProjectTask({fProjectID: "00000000-0000-0000-0000-000000000000"}, 0)
        // console.log(this.lastWeekDay)
    },
    methods: {
        openLoading() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        BelowPeriod() {
          if(!this.PeriodShow){
            let unx = parseInt(this.$moment(this.lastWeekDay).format("x")) + 604800000
            this.lastWeekDay = this.$moment(unx).format("YYYY-MM-DD")
          }
            this.PeriodShow = true
            this._ReadWorkingHours(this.ReadDay)
        },
        getBeforeDate(n) {
            let d = new Date();
            let year = d.getFullYear();
            let mon = d.getMonth() + 1;
            let day = d.getDate();
            if (day <= n) {
                if (mon > 1) {
                    mon = mon - 1;
                } else {
                    year = year - 1;
                    mon = 12;
                }
            }
            d.setDate(d.getDate() - n);
            year = d.getFullYear();
            mon = d.getMonth() + 1;
            day = d.getDate();
            return year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
        },
        getBeforeDateAdd() {
            this.PeriodShow = !this.PeriodShow
            let unx = parseInt(this.$moment(this.lastWeekDay).format("x")) + 604800000
            this.lastWeekDay = this.$moment(unx).format("YYYY-MM-DD")
            // console.log(this.lastWeekDay)
            this._ReadWorkingHours(this.lastWeekDay)
        },
        getBeforeDateMinus() {
            this.PeriodShow = !this.PeriodShow
            let unx = parseInt(this.$moment(this.lastWeekDay).format("x")) - 604800000
            this.lastWeekDay = this.$moment(unx).format("YYYY-MM-DD")
            // console.log(this.lastWeekDay)
            this._ReadWorkingHours(this.lastWeekDay)
        },
        submit(formName) {
            if (this.WorkingHourData.fState === "A") {
                this.$message.warning('无效的操作');
            } else {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        for (let i of this.WorkingHourData.ltProjectTimeSheetDetails) {
                            i.fSunday = i.fSunday === "" ? 0 : i.fSunday
                            i.fMonday = i.fMonday === "" ? 0 : i.fMonday
                            i.fTuesday = i.fTuesday === "" ? 0 : i.fTuesday
                            i.fWednesday = i.fWednesday === "" ? 0 : i.fWednesday
                            i.fThursday = i.fThursday === "" ? 0 : i.fThursday
                            i.fFriday = i.fFriday === "" ? 0 : i.fFriday
                            i.fSaturday = i.fSaturday === "" ? 0 : i.fSaturday
                        }
                        this.WorkingHourData.fState = "T"
                        this.WorkingHourData.state = "提交"
                        this.WorkingHourData.startDay = this.WorkingHourData.ltProjectTimeSheetDetails[0].fMondayDate
                        // console.log(JSON.stringify(this.WorkingHourData))
                        WriteWorkingHours(this.WorkingHourData).then(res => {
                            // console.log(res)
                            if (res.result === true) {
                                this.$message.success('提交成功');
                                this._ReadWorkingHours(this.$moment(this.WorkingHourData.ltProjectTimeSheetDetails[0].fTuesdayDate).format("MM/DD"))
                            } else {
                                this.$message.error('提交失败');
                                console.log(res)
                            }
                        }).catch(err => {
                            console.log(err)
                            this.$message.error('提交失败');
                        })
                    } else {
                        this.$message.error('请选择任务');
                        return false;
                    }
                });

            }
        },
        save() {
            if (this.WorkingHourData.fState === "T" || this.WorkingHourData.fState === "A") {
                this.$message.warning('无效的操作');
            } else {
                for (let i of this.WorkingHourData.ltProjectTimeSheetDetails) {
                    i.fSunday = i.fSunday === "" ? 0 : i.fSunday
                    i.fMonday = i.fMonday === "" ? 0 : i.fMonday
                    i.fTuesday = i.fTuesday === "" ? 0 : i.fTuesday
                    i.fWednesday = i.fWednesday === "" ? 0 : i.fWednesday
                    i.fThursday = i.fThursday === "" ? 0 : i.fThursday
                    i.fFriday = i.fFriday === "" ? 0 : i.fFriday
                    i.fSaturday = i.fSaturday === "" ? 0 : i.fSaturday
                }
                // console.log("保存")
                // console.log(JSON.stringify(this.WorkingHourData))
                this.WorkingHourData.fState = "S"
                this.WorkingHourData.state = "保存"
                this.WorkingHourData.startDay = this.WorkingHourData.ltProjectTimeSheetDetails[0].fMondayDate
                // console.log(this.WorkingHourData.startDay)
                for (let i of this.WorkingHourData.ltProjectTimeSheetDetails) {
                    i.fCreateUser = this.userId
                    i.fChangedUser = this.userId
                }
                // console.log(this.WorkingHourData)
                WriteWorkingHours(this.WorkingHourData).then(res => {
                    // console.log(res)
                    if (res.result === true) {
                        this.$message.success('保存成功');
                        this._ReadWorkingHours(this.$moment(this.WorkingHourData.ltProjectTimeSheetDetails[0].fTuesdayDate).format("MM/DD"))
                    } else {
                        this.$message.error('保存失败');
                    }
                }).catch(err => {
                    this.$message.error('保存失败');
                    console.log(err)
                })
            }
        },
        handleNodeClick(data, index, formName,item) {
            console.log(data)
            if (data.ltProjectTask.length === 0 && !this.WorkingHourData.ltProjectTimeSheetDetails[index].disabled) {
                // console.log(1)
                // console.log(data)
                this.WorkingHourData.ltProjectTimeSheetDetails[index].fProjectTaskID = data.fProjectTaskID
                this.WorkingHourData.ltProjectTimeSheetDetails[index].fTaskName = data.fTaskName
                this.WorkingHourData.ltProjectTimeSheetDetails[index].fTaskCode = data.fTaskCode
                this.WorkingHourData.ltProjectTimeSheetDetails[index].fUserName = this.UserData.fUserName
                this.popoverShow = false
                item.isModified=false
                // console.log(this.WorkingHourData)
                // console.log(1)
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        // 验证不通过禁止提交
                        return false;
                    }
                });
            }
        },
        upInput(index, n) {
            this.HourSum = {
                Sunday: 0,
                Monday: 0,
                Tuesday: 0,
                Wednesday: 0,
                Thursday: 0,
                Friday: 0,
                Saturday: 0,
                total: 0
            }
            this.WorkingHourData.ltProjectTimeSheetDetails[index].fWeekHour =
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday) +
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday) +
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday) +
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday) +
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday) +
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday) +
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday)
            for (let i of this.WorkingHourData.ltProjectTimeSheetDetails) {
                if (n === "7") {
                    this.HourSum.Sunday = this.HourSum.Sunday + parseInt(i.fSunday === "" ? 0 : i.fSunday)
                    this.HourSum.Monday = this.HourSum.Monday + parseInt(i.fMonday === "" ? 0 : i.fMonday)
                    this.HourSum.Tuesday = this.HourSum.Tuesday + parseInt(i.fTuesday === "" ? 0 : i.fTuesday)
                    this.HourSum.Wednesday = this.HourSum.Wednesday + parseInt(i.fWednesday === "" ? 0 : i.fWednesday)
                    this.HourSum.Thursday = this.HourSum.Thursday + parseInt(i.fThursday === "" ? 0 : i.fThursday)
                    this.HourSum.Friday = this.HourSum.Friday + parseInt(i.fFriday === "" ? 0 : i.fFriday)
                    this.HourSum.Saturday = this.HourSum.Saturday + parseInt(i.fSaturday === "" ? 0 : i.fSaturday)
                    this.HourSum.total = this.HourSum.total + parseInt(i.fWeekHour === "" ? 0 : i.fWeekHour)
                    if (this.HourSum.Sunday > 24) {
                        this.HourSum.total = this.HourSum.total - this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday
                        this.HourSum.Sunday = this.HourSum.Sunday - this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday = ""
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fWeekHour =
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday)
                    }
                } else if (n === '1') {
                    this.HourSum.Sunday = this.HourSum.Sunday + parseInt(i.fSunday === "" ? 0 : i.fSunday)
                    this.HourSum.Monday = this.HourSum.Monday + parseInt(i.fMonday === "" ? 0 : i.fMonday)
                    this.HourSum.Tuesday = this.HourSum.Tuesday + parseInt(i.fTuesday === "" ? 0 : i.fTuesday)
                    this.HourSum.Wednesday = this.HourSum.Wednesday + parseInt(i.fWednesday === "" ? 0 : i.fWednesday)
                    this.HourSum.Thursday = this.HourSum.Thursday + parseInt(i.fThursday === "" ? 0 : i.fThursday)
                    this.HourSum.Friday = this.HourSum.Friday + parseInt(i.fFriday === "" ? 0 : i.fFriday)
                    this.HourSum.Saturday = this.HourSum.Saturday + parseInt(i.fSaturday === "" ? 0 : i.fSaturday)
                    this.HourSum.total = this.HourSum.total + parseInt(i.fWeekHour === "" ? 0 : i.fWeekHour)
                    if (this.HourSum.Monday > 24) {
                        this.HourSum.total = this.HourSum.total - this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday
                        this.HourSum.Monday = this.HourSum.Monday - this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday = ""
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fWeekHour =
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday)
                    }
                } else if (n === '2') {
                    this.HourSum.Sunday = this.HourSum.Sunday + parseInt(i.fSunday === "" ? 0 : i.fSunday)
                    this.HourSum.Monday = this.HourSum.Monday + parseInt(i.fMonday === "" ? 0 : i.fMonday)
                    this.HourSum.Tuesday = this.HourSum.Tuesday + parseInt(i.fTuesday === "" ? 0 : i.fTuesday)
                    this.HourSum.Wednesday = this.HourSum.Wednesday + parseInt(i.fWednesday === "" ? 0 : i.fWednesday)
                    this.HourSum.Thursday = this.HourSum.Thursday + parseInt(i.fThursday === "" ? 0 : i.fThursday)
                    this.HourSum.Friday = this.HourSum.Friday + parseInt(i.fFriday === "" ? 0 : i.fFriday)
                    this.HourSum.Saturday = this.HourSum.Saturday + parseInt(i.fSaturday === "" ? 0 : i.fSaturday)
                    this.HourSum.total = this.HourSum.total + parseInt(i.fWeekHour === "" ? 0 : i.fWeekHour)
                    if (this.HourSum.Tuesday > 24) {
                        this.HourSum.total = this.HourSum.total - this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday
                        this.HourSum.Tuesday = this.HourSum.Tuesday - this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday = ""
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fWeekHour =
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday)
                    }
                } else if (n === '3') {
                    this.HourSum.Sunday = this.HourSum.Sunday + parseInt(i.fSunday === "" ? 0 : i.fSunday)
                    this.HourSum.Monday = this.HourSum.Monday + parseInt(i.fMonday === "" ? 0 : i.fMonday)
                    this.HourSum.Tuesday = this.HourSum.Tuesday + parseInt(i.fTuesday === "" ? 0 : i.fTuesday)
                    this.HourSum.Wednesday = this.HourSum.Wednesday + parseInt(i.fWednesday === "" ? 0 : i.fWednesday)
                    this.HourSum.Thursday = this.HourSum.Thursday + parseInt(i.fThursday === "" ? 0 : i.fThursday)
                    this.HourSum.Friday = this.HourSum.Friday + parseInt(i.fFriday === "" ? 0 : i.fFriday)
                    this.HourSum.Saturday = this.HourSum.Saturday + parseInt(i.fSaturday === "" ? 0 : i.fSaturday)
                    this.HourSum.total = this.HourSum.total + parseInt(i.fWeekHour === "" ? 0 : i.fWeekHour)
                    if (this.HourSum.Wednesday > 24) {
                        this.HourSum.total = this.HourSum.total - this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday
                        this.HourSum.Wednesday = this.HourSum.Wednesday - this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday = ""
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fWeekHour =
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday)
                    }
                } else if (n === '4') {
                    this.HourSum.Sunday = this.HourSum.Sunday + parseInt(i.fSunday === "" ? 0 : i.fSunday)
                    this.HourSum.Monday = this.HourSum.Monday + parseInt(i.fMonday === "" ? 0 : i.fMonday)
                    this.HourSum.Tuesday = this.HourSum.Tuesday + parseInt(i.fTuesday === "" ? 0 : i.fTuesday)
                    this.HourSum.Wednesday = this.HourSum.Wednesday + parseInt(i.fWednesday === "" ? 0 : i.fWednesday)
                    this.HourSum.Thursday = this.HourSum.Thursday + parseInt(i.fThursday === "" ? 0 : i.fThursday)
                    this.HourSum.Friday = this.HourSum.Friday + parseInt(i.fFriday === "" ? 0 : i.fFriday)
                    this.HourSum.Saturday = this.HourSum.Saturday + parseInt(i.fSaturday === "" ? 0 : i.fSaturday)
                    this.HourSum.total = this.HourSum.total + parseInt(i.fWeekHour === "" ? 0 : i.fWeekHour)
                    if (this.HourSum.Thursday > 24) {
                        this.HourSum.total = this.HourSum.total - this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday
                        this.HourSum.Thursday = this.HourSum.Thursday - this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday = ""
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fWeekHour =
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday)
                    }
                } else if (n === '5') {
                    this.HourSum.Sunday = this.HourSum.Sunday + parseInt(i.fSunday === "" ? 0 : i.fSunday)
                    this.HourSum.Monday = this.HourSum.Monday + parseInt(i.fMonday === "" ? 0 : i.fMonday)
                    this.HourSum.Tuesday = this.HourSum.Tuesday + parseInt(i.fTuesday === "" ? 0 : i.fTuesday)
                    this.HourSum.Wednesday = this.HourSum.Wednesday + parseInt(i.fWednesday === "" ? 0 : i.fWednesday)
                    this.HourSum.Thursday = this.HourSum.Thursday + parseInt(i.fThursday === "" ? 0 : i.fThursday)
                    this.HourSum.Friday = this.HourSum.Friday + parseInt(i.fFriday === "" ? 0 : i.fFriday)
                    this.HourSum.Saturday = this.HourSum.Saturday + parseInt(i.fSaturday === "" ? 0 : i.fSaturday)
                    this.HourSum.total = this.HourSum.total + parseInt(i.fWeekHour === "" ? 0 : i.fWeekHour)
                    if (this.HourSum.Friday > 24) {
                        this.HourSum.total = this.HourSum.total - this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday
                        this.HourSum.Friday = this.HourSum.Friday - this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday = ""
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fWeekHour =
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday)
                    }
                } else if (n === '6') {
                    this.HourSum.Sunday = this.HourSum.Sunday + parseInt(i.fSunday === "" ? 0 : i.fSunday)
                    this.HourSum.Monday = this.HourSum.Monday + parseInt(i.fMonday === "" ? 0 : i.fMonday)
                    this.HourSum.Tuesday = this.HourSum.Tuesday + parseInt(i.fTuesday === "" ? 0 : i.fTuesday)
                    this.HourSum.Wednesday = this.HourSum.Wednesday + parseInt(i.fWednesday === "" ? 0 : i.fWednesday)
                    this.HourSum.Thursday = this.HourSum.Thursday + parseInt(i.fThursday === "" ? 0 : i.fThursday)
                    this.HourSum.Friday = this.HourSum.Friday + parseInt(i.fFriday === "" ? 0 : i.fFriday)
                    this.HourSum.Saturday = this.HourSum.Saturday + parseInt(i.fSaturday === "" ? 0 : i.fSaturday)
                    this.HourSum.total = this.HourSum.total + parseInt(i.fWeekHour === "" ? 0 : i.fWeekHour)
                    if (this.HourSum.Saturday > 24) {
                        this.HourSum.total = this.HourSum.total - this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday
                        this.HourSum.Saturday = this.HourSum.Saturday - this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday = ""
                        this.WorkingHourData.ltProjectTimeSheetDetails[index].fWeekHour =
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday) +
                            parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday)
                    }
                }
            }
            this.WorkingHourData.ltProjectTimeSheetDetails[index].fWeekHour =
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSunday) +
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fMonday) +
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fTuesday) +
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fWednesday) +
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fThursday) +
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fFriday) +
                parseInt(this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday === "" ? 0 : this.WorkingHourData.ltProjectTimeSheetDetails[index].fSaturday)
        },
        getHourSum() {
            this.HourSum = {
                Sunday: 0,
                Monday: 0,
                Tuesday: 0,
                Wednesday: 0,
                Thursday: 0,
                Friday: 0,
                Saturday: 0,
                total: 0
            }
            for (let i of this.WorkingHourData.ltProjectTimeSheetDetails) {
                this.HourSum.Sunday = this.HourSum.Sunday + parseInt(i.fSunday === "" ? 0 : i.fSunday)
                this.HourSum.Monday = this.HourSum.Monday + parseInt(i.fMonday === "" ? 0 : i.fMonday)
                this.HourSum.Tuesday = this.HourSum.Tuesday + parseInt(i.fTuesday === "" ? 0 : i.fTuesday)
                this.HourSum.Wednesday = this.HourSum.Wednesday + parseInt(i.fWednesday === "" ? 0 : i.fWednesday)
                this.HourSum.Thursday = this.HourSum.Thursday + parseInt(i.fThursday === "" ? 0 : i.fThursday)
                this.HourSum.Friday = this.HourSum.Friday + parseInt(i.fFriday === "" ? 0 : i.fFriday)
                this.HourSum.Saturday = this.HourSum.Saturday + parseInt(i.fSaturday === "" ? 0 : i.fSaturday)
                this.HourSum.total = this.HourSum.total + parseInt(i.fWeekHour === "" ? 0 : i.fWeekHour)
            }
        },
        _ReadWorkingHours(ReadDay) {
            this.openLoading()
            const param = {
                UserID: this.userId,
                ReadDay: ReadDay
            }
            this.saveData = 0
            ReadWorkingHours(param).then(res => {
                // console.log(res)
                res.result.fRemark = ""
                let ReadDay = this.ReadDay
                let head = ReadDay.split("-")[0] + "-" + (parseInt(ReadDay.split("-")[1]) - 2) + "-26"
                this.showDay.SunShow = this.$moment(res.result.ltProjectTimeSheetDetails[0].fSundayDate).format("YYYY-M-DD") < head
                this.showDay.MonShow = this.$moment(res.result.ltProjectTimeSheetDetails[0].fMondayDate).format("YYYY-M-DD") < head
                this.showDay.TuesShow = this.$moment(res.result.ltProjectTimeSheetDetails[0].fTuesdayDate).format("YYYY-M-DD") < head
                this.showDay.WednesShow = this.$moment(res.result.ltProjectTimeSheetDetails[0].fWednesdayDate).format("YYYY-M-DD") < head
                this.showDay.ThursShow = this.$moment(res.result.ltProjectTimeSheetDetails[0].fThursdayDate).format("YYYY-M-DD") < head
                this.showDay.FriShow = this.$moment(res.result.ltProjectTimeSheetDetails[0].fFridayDate).format("YYYY-M-DD") < head
                this.showDay.SaturShow = this.$moment(res.result.ltProjectTimeSheetDetails[0].fSaturdayDate).format("YYYY-M-DD") < head
                for (let i of res.result.ltProjectTimeSheetDetails) {
                    i.fSunday = i.fSunday === 0 ? "" : i.fSunday
                    i.fMonday = i.fMonday === 0 ? "" : i.fMonday
                    i.fTuesday = i.fTuesday === 0 ? "" : i.fTuesday
                    i.fWednesday = i.fWednesday === 0 ? "" : i.fWednesday
                    i.fThursday = i.fThursday === 0 ? "" : i.fThursday
                    i.fFriday = i.fFriday === 0 ? "" : i.fFriday
                    i.fSaturday = i.fSaturday === 0 ? "" : i.fSaturday
                    if (i.fState === "") {
                        i.fHourTypeID = this.UserData.fHourTypeID
                        i.fHourTypeName = this.UserData.fHourTypeName
                        i.fHourTypeNumber = this.UserData.fHourTypeNum
                        res.result.fState = ""
                        i.fDepartmentNum = this.UserData.fDepartmentNum
                        i.fDepartmentName = this.UserData.fDepartmentName
                        i.fLabourRate = this.UserData.fLabourRate
                        i.fTaskEndDate = "9999-01-01T00:00:00"
                        i.fTaskStartDate = "1796-01-01T00:00:00"
                        i.fProjectTaskID = i.fProjectTaskID === "00000000-0000-0000-0000-000000000000" ? '' : i.fProjectTaskID
                        if (i.fProjectID === "00000000-0000-0000-0000-000000000000") {
                            i.fProjectNum = this.ParticipateProjectData[0].fProjectNum
                            i.fProjectName = this.ParticipateProjectData[0].fProjectName
                        }
                    } else if (i.fState === "S") {
                        this.saveData++
                    } else i.disabled = i.fState === "T" || i.fState === "A";
                }
                this.WorkingHourData = {}
                this.WorkingHourData = res.result
                this.getHourSum()
                this.loading.close();
                // console.log("00000")
                // console.log(this.WorkingHourData)
            }).catch(err => {
                console.log(err)
                this.$message.error('请求工时数据出现错误，请稍后再试');
                this.loading.close();
            })
        },
        _GetParticipateProject() {
            const param = {
                UserID: this.userId
            }
            GetParticipateProject(param).then(res => {
                // console.log(res)
                for (let i of res.result) {
                    this.ParticipateProjectData.push(i)
                    // console.log("项目------------")
                    // console.log(this.ParticipateProjectData)
                }
            }).catch(err => {
                this.$message.error('请求项目数据出现错误，请稍后再试');
                console.log(err)
            })
        },
        GetProjectTask(value, index) {
            value.fTaskName = ""
            // console.log(this.ParticipateProjectData)
            for (let i of this.ParticipateProjectData) {
                if (i.fProjectID === value.fProjectID) {
                    this.WorkingHourData.ltProjectTimeSheetDetails[index].fProjectNum = i.fProjectNum
                    this.WorkingHourData.ltProjectTimeSheetDetails[index].fProjectName = i.fProjectName
                    this.workingHoursSurplus = i.workingHoursSurplus;
                }
            }
            const param = {
                UserID: this.userId,
                ProjectID: value.fProjectID
            }
            GetProjectTask(param).then(res => {
                this.ProjectTaskData = res.result
                // console.log("任务------------")
                // console.log(this.ProjectTaskData)
            }).catch(err => {
                this.$message.error('请求任务数据出现错误，请稍后再试');
                console.log(err)
            })
        },
        tableDelete(index) {
            // console.log(this.WorkingHourData)
            if (this.WorkingHourData.ltProjectTimeSheetDetails[index].fState === "T" || this.WorkingHourData.ltProjectTimeSheetDetails[index].fState === "A" || this.WorkingHourData.ltProjectTimeSheetDetails.length <= 1) {
                this.$message.warning('无效的操作');
            } else {
                this.WorkingHourData.ltProjectTimeSheetDetails.splice(index, 1)
                for (let i in this.WorkingHourData.ltProjectTimeSheetDetails) {
                    this.WorkingHourData.ltProjectTimeSheetDetails[i].fOrderValue = parseInt(i)
                }
                this.getHourSum()
            }
        },
        tableAdd() {
            if (this.WorkingHourData.ltProjectTimeSheetDetails.length >= 24 || this.WorkingHourData.fState === "A") {
                this.$message.warning('无效的操作');
            } else {
                // console.log(this.WorkingHourData.ltProjectTimeSheetDetails.length)
                const param = {
                    UserID: this.userId,
                    WorkingDate: this.ReadDay,
                    Order: this.WorkingHourData.ltProjectTimeSheetDetails.length
                }
                GetSheetDetail(param).then(res => {
                    // console.log(res)
                    res.fSunday = ""
                    res.fMonday = ""
                    res.fTuesday = ""
                    res.fWednesday = ""
                    res.fThursday = ""
                    res.fFriday = ""
                    res.fSaturday = ""
                    res.fTaskName = ""
                    res.fIsLock = "F"
                    res.fState = ""
                    res.fHourTypeID = this.UserData.fHourTypeID
                    res.fHourTypeName = this.UserData.fHourTypeName
                    res.fHourTypeNumber = this.UserData.fHourTypeNum
                    res.fDepartmentNum = this.UserData.fDepartmentNum
                    res.fDepartmentName = this.UserData.fDepartmentName
                    res.fDepartmentID = this.UserData.fDepartmentID
                    res.fProjectTaskID = res.fProjectTaskID === "00000000-0000-0000-0000-000000000000" ? '' : res.fProjectTaskID
                    if (res.fProjectID === "00000000-0000-0000-0000-000000000000") {
                        res.fProjectNum = this.ParticipateProjectData[0].fProjectNum
                        res.fProjectName = this.ParticipateProjectData[0].fProjectName
                    }
                    res.fChangedTime = this.$moment().format("YYYY-MM-DDTHH:ss:mm")
                    res.fCreateTime = this.$moment().format("YYYY-MM-DDTHH:ss:mm")
                    res.fTaskEndDate = "9999-01-01T00:00:00"
                    res.fTaskStartDate = "1796-01-01T00:00:00"
                    res.fLabourRate = this.UserData.fLabourRate
                    // res.fTimeSheetMasterID = this.WorkingHourData.ltProjectTimeSheetDetails[this.WorkingHourData.ltProjectTimeSheetDetails.length - 1].fTimeSheetMasterID
                    res.fYear = this.WorkingHourData.ltProjectTimeSheetDetails[this.WorkingHourData.ltProjectTimeSheetDetails.length - 1].fYear
                    this.WorkingHourData.ltProjectTimeSheetDetails.push(res)
                    // console.log(res)
                    // console.log(res.fProjectNum, res.fProjectName, this.ParticipateProjectData[0].fProjectNum, this.ParticipateProjectData[0].fProjectName)
                    // console.log("新增")
                }).catch(err => {
                    this.$message.error('新增失败');
                    console.log(err)
                })
            }
        }
    }
}
</script>

<style scoped lang="stylus">
#fillin
    width 100%
    height 100%

    .title
        width 100%
        border-bottom solid 1px #E9E9E9
        box-sizing border-box
        padding-bottom 16px

        span
            font-weight 500
            font-size 16px
            margin-left 25px
            color rgba(0, 0, 0, 0.85)

    .select_operation
        width 100%
        margin 0
        background-color white
        display flex
        flex-direction row
        align-items center
        flex-wrap wrap

        .el_button
            width 360px
            height 100%
            display flex
            flex-direction row
            align-items center
            margin 12px 12px

            .period_button
                width 102px
                height 26px
                display flex
                flex-direction row
                justify-content center
                align-items center
                background-color rgb(237, 108, 2)
                border-color rgb(238, 116, 16)
                color white
                font-size 16px
                font-weight 400

            .period_button:hover
                background-color #fd8733
                border-color #fd8733

            .present
                width 48px
                height 26px
                background #FFFFFF
                border 1px solid #ED6C02
                display flex
                flex-direction row
                justify-content center
                align-items center
                font-size 16px
                font-weight 400


            .present:hover
                background #fadec6
                border-color #fd8733

    .demo-infinite-container
        overflow auto
        max-height 540px

        .table
            width 100%
            border-collapse collapse
            box-sizing border-box
            border-top .5px solid #CDCDCD
            border-right .5px solid #CDCDCD
            margin 5px 0

            .table_header
                height 60px
                font-size 12px
                font-weight bold
                background-color #EFEFEF

                td
                    padding 5px 5px

            .table_body
                background-color white
                font-size 12px
                font-weight bold

                td
                    padding 5px 5px

            .table_bottom
                font-size 12px
                font-weight bold
                background-color #EFEFEF

                td
                    padding 5px 5px

    .data_sum
        width 100%
        margin 10px 0
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        flex-wrap wrap

        .data_sum_div1
            display flex
            flex-direction row
            padding 10px 24px
            box-sizing border-box
            flex-wrap wrap

        .data_sum_div2
            display flex
            justify-content space-between
            padding-right 16px
            box-sizing border-box
            flex-wrap wrap

    .forTdBody
        width 60px
        border-bottom .5px solid #CDCDCD
        border-left .5px solid #CDCDCD
        text-align center

        .table-add
            color: #ED6C02;
            cursor: pointer;
            margin-right: 5px;
            font-size: 16px;

        .table-delete
            color: #7D7D7D;
            cursor: pointer;
            margin-left: 5px;
            font-size: 16px;

    .forTdHeader
        text-align center
        border-left .5px solid #CDCDCD
        border-bottom .5px solid #CDCDCD

        span
            display inline-block
            margin 2px 0

    .tdBorderBottom
        border-bottom .5px solid #CDCDCD

    .el-button {
        width: 65px;
        height: 32px;
        background: #FFEAD8;
        border: 1px solid #ED6D01;
        font-weight: 400;
        color: #ED6D01;
        font-size: 14px;
        margin-left: 12px;
    }

    .el-button:hover {
        color: #ED6D01;
        background: #fadec6;
    }

    .el-tag {
        height revert
        line-height revert
        padding 2px 5px
        font-size 12px
        border 1px solid #CCC
        border-radius 0
    }

    .el-cascader--mini {
        width 100%
    }

    .forTdBody >>> .el-input__inner {
        text-align center
    }

#fillin >>> .el-input__inner:focus {
    border 1px #ED6C02 solid
}

.el-form-item >>> .el-input__inner:focus {
    border 1px #ED6C02 solid
}

.el-form-item >>> .el-textarea__inner:focus {
    border 1px #ED6C02 solid
}

.el-popover {
    color red
}

.el-form >>> .el-form-item
    margin-bottom 5px !important

/*
.el-form-item >>> .el-form-item__content {
    line-height inherit !important
}
*/
.bodyTop:before
    content ""
    position absolute
    left 25%
    top -2px
    width 50%
    height 2px
    background-color #ED6C02


.WhiteBackground
    background-color #FFFFFF

.greenBackground
    background-color #80DBAA

.yellowBackground
    background-color #FFD4B0

</style>