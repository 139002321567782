<template>
    <div>
        <a-modal :visible="editShow" title="编辑" @ok="editOk" @cancel="editCancel">
            <a-form-model :model="editObj" :rules="rules" ref="ruleForm" :label-col="labelCol"
                          :wrapper-col="wrapperCol">
                <a-form-model-item label="模块编码" prop="fModuleNo">
                    <a-input v-model="editObj.fModuleNo"/>
                </a-form-model-item>
                <a-form-model-item label="模块名称" prop="fModuleName">
                    <a-input v-model="editObj.fModuleName"/>
                </a-form-model-item>
                <a-form-model-item label="链接" prop="fLink">
                    <a-input v-model="editObj.fLink"/>
                </a-form-model-item>
                <a-form-model-item label="图标编码" prop="fPicture">
                    <a-input v-model="editObj.fPicture"/>
                </a-form-model-item>
                <a-form-model-item label="关闭状态">
                    <a-radio-group v-model="editObj.fCloseState">
                        <a-radio value="T">
                            未关闭
                        </a-radio>
                        <a-radio value="F">
                            关闭
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="描述" prop="fRemark">
                    <a-input v-model="editObj.fRemark" type="textarea"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import {InsertOrUpdatetSystemModuleRights} from "../../api/ModuleList";

export default {
    props: ['editShow', 'editObj'],
    name: "ModuleListEdit",
    data() {
        return {
            labelCol: {span: 6},
            wrapperCol: {span: 17},
            rules: {
                fModuleNo: [
                    {required: true, message: '请输入内容', trigger: 'blur'},
                ],
                fModuleName: [
                    {required: true, message: '请输入内容', trigger: 'blur'},
                ],
                fLink: [
                    {required: true, message: '请输入内容', trigger: 'blur'},
                ],
            },
        };
    },
    mounted() {
    },
    methods: {
        editOk() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.editObj.fCreateTime = this.$moment().format()
                    this.editObj.fCreateUser = JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID
                    this.editObj.fChangedTime = this.$moment().format()
                    this.editObj.fChangedUser = JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID
                    console.log(JSON.stringify(this.editObj))
                    InsertOrUpdatetSystemModuleRights(JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID, this.editObj).then((res) => {
                        if (res.result.bRet === true) {
                            this.$message.success('编辑成功')
                            this.$emit("update:editShow", false);
                            // this.$refs.ruleForm.resetFields();
                            this.$parent._GetModuleALL('edit');
                        } else {
                            this.$message.error('编辑失败')
                        }
                    }).catch((err) => {
                        this.$message.error('编辑失败')
                        console.log(err)
                    })
                } else {
                    return false;
                }
            });
        },
        editCancel() {
            // this.$refs.ruleForm.resetFields();
            this.$emit("update:editShow", false);
            this.$parent._GetModuleALL('edit');
        },
    },
}
</script>

<style scoped lang="stylus">
/deep/ .ant-form-item
    margin-bottom 16px

</style>