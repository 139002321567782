<template>
  <div class="position-list">
    <div class="title">
      <span>职位列表</span>
    </div>
    <a-divider />
    <div class="pos-action">
      <div class="pos-select">
        <div class="pos-select-item">
          <span class="pos-select-item-text">职位编号：</span>
          <a-input
            style="width: 193px; height: 30px; margin-left: 10px"
            v-model="searchNum"
            placeholder="请输入职位编号"
            allow-clear
          />
        </div>
        <div class="pos-select-item">
          <span class="pos-select-item-text">职位名称：</span>
          <a-input
            style="width: 193px; height: 30px; margin-left: 10px"
            v-model="searchName"
            placeholder="请输入职位名称"
            allow-clear
          />
        </div>
        <div class="pos-select-item">
          <span class="pos-select-item-text">职位状态：</span>
          <a-select
            placeholder="请选择"
            :value="searchState"
            @change="searchStateChange"
            style="width: 97px; height: 30px; margin-left: 10px"
          >
            <a-select-option value="T">启用</a-select-option>
            <a-select-option value="F">弃用</a-select-option>
          </a-select>
        </div>
      </div>
      <div class="pos-button1">
        <a-button type="primary" @click="onSearch">搜索</a-button>
        <a-button @click="onClear">重置</a-button>
      </div>
    </div>
    <div class="pos-button2">
      <a-button type="primary" @click="showAddModal">新增职位</a-button>
      <a-button @click="deletePos">批量删除</a-button>
    </div>
    <div class="pos-list">
      <a-table
        :rowKey="(record) => record.fStationID"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :data-source="data"
        :scroll="{ y: scrollY }"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="showEditModal(record)">编辑</a>
        </span>
      </a-table>
      <station-add :Visible.sync="addModalVisible" />
      <station-edit
        :Visible.sync="editModalVisible"
        :PosItemData="posItemData"
      />
    </div>
  </div>
</template>

<script>
import { SelectStations } from "../../api/Employee.js";
import StationAdd from "../../components/station/StationAdd.vue";
import StationEdit from "../../components/station/StationEdit.vue";
const columns = [
  {
    title: "职位编号",
    dataIndex: "fStationNum",
  },
  {
    title: "职位名称",
    dataIndex: "fStationName",
  },
  {
    title: "描述",
    dataIndex: "fDescription",
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "fCreateTime",
  },
  {
    title: "编辑时间",
    dataIndex: "fChangedTime",
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];

// const data = [];
// for (let i = 0; i < 46; i++) {
//   data.push({
//     key: i,
//     level: "一级区域",
//     num: "00001",
//     name: "东区1栋",
//     belong: "默认",
//     time: "2022.04.12  18:23:40",
//     people: 10,
//     device: 12,
//   });
// }
export default {
  components: {
    StationAdd,
    StationEdit,
  },
  data() {
    return {
      data: [],
      columns,
      searchState: "T",
      searchNum: undefined,
      searchName: undefined,
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      scrollY: "",
      editModalVisible: false,
      addModalVisible: false,
      posItemData: [],
    };
  },
  watch: {
    addModalVisible: {
      handler(val) {
        if (val === false) {
          this.getPosList();
        }
      },
    },
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  beforeUpdate() {
    var that = this;
    this.$nextTick(() => {
      that.scrollY = this.getTableScroll();
      //console.log(that.scrollY);
      setTimeout(() => {
        that.checkScrollBar();
      });
    });
  },
  mounted() {
    var that = this;
    this.getPosList();
    //监听事件
    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          that.scrollY = this.getTableScroll();
          that.checkScrollBar();
          console.log(that.scrollY);
        });
      })();
    };
  },
  methods: {
    getPosList(data = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      this.loading = true;
      SelectStations({
        UserID: user.fEmployeeID,
        Page: 1,
        PageNum: 99,
        Data: data,
      }).then((res) => {
        console.log(res);
        this.loading = false;
        this.data = res.result.objReturnInfo;
      });
    },
    deletePos() {
      console.log(this.selectedRowKeys);
      var that = this;
      var id = that.selectedRowKeys;
      this.$confirm({
        title: "提示",
        content: "是否确认删除选择的部门?",
        onOk() {
          that.$axios.post("/pos/delete", { data: id }).then((res) => {
            console.log(res);
            that.getPosList();
          });
        },
        onCancel() {},
      });
    },
    start() {
      this.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },
    searchStateChange(value) {
      this.searchState = value;
    },
    searchNumChange(value) {
      this.searchNum = value;
    },
    searchNameChange(value) {
      this.searchName = value;
    },
    onSearch() {
      let data = {
        fStationNum: this.searchNum,
        fStationName: this.searchName,
        fState: this.searchState,
      };
      this.getPosList(data);
    },
    onClear() {
      this.searchState = "T";
      this.searchNum = undefined;
      this.searchName = undefined;
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    showEditModal(data) {
      console.log(data);
      this.posItemData = { ...data };
      this.editModalVisible = true;
    },
    showAddModal() {
      this.addModalVisible = true;
    },
    getTableScroll(extraHeight, id) {
      // 获取表格滚动高度
      if (typeof extraHeight == "undefined") {
        //  默认底部分页32 + 边距32
        extraHeight = 84;
      }
      let tHeader = null;
      if (id) {
        tHeader = document.getElementById(id)
          ? document
              .getElementById(id)
              .getElementsByClassName("ant-table-thead")[0]
          : null;
      } else {
        tHeader = document.getElementsByClassName("ant-table-thead")[0];
      }
      // 表格内容距离顶部的距离
      let tHeaderBottom = 0;
      if (tHeader) {
        tHeaderBottom = tHeader.getBoundingClientRect().bottom;
      }
      // 窗体高度-表格内容顶部的高度-表格内容底部的高度
      let height = document.body.clientHeight - tHeaderBottom - extraHeight;
      //let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
      return height;
    },
    checkScrollBar() {
      let antTableBody = document.querySelector(".ant-table-body");
      let antTableBodyScrollHeight = antTableBody && antTableBody.scrollHeight;
      let antTableBodyClientHeight = antTableBody && antTableBody.clientHeight;
      let antTableFixHeader = document.querySelector(
        ".ant-table-hide-scrollbar"
      );
      //console.log("xxxxx", antTableBodyScrollHeight, antTableBodyClientHeight);
      if (antTableBodyScrollHeight <= antTableBodyClientHeight) {
        antTableFixHeader &&
          antTableFixHeader.classList.add("change-scrollBar");
      } else {
        antTableFixHeader &&
          antTableFixHeader.classList.remove("change-scrollBar");
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.position-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 800;
  line-height: 24px;

  span {
    font-weight: 500;
    font-size: 16px;
    margin-left: 25px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.pos-button2 {
  margin: 0 16px;
}

.pos-list {
  margin: 16px;
}

.pos-action {
  margin: 10px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pos-select {
  display: flex;
  flex-direction: row;
}

.pos-select-item {
  margin-left: 10px;
}

.pos-select-item:first-child {
  margin-left: 0;
}

.pos-select-item-text {
  font-family: 'Arial Normal', 'Arial';
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: #797979;
}

.pos-button1 {
  display: flex;
  flex-direction: row;
}

.ant-btn {
  margin-left: 10px;
}

.ant-btn:first-child {
  margin-left: 0;
}
</style>