<template>
  <a-modal
    title="员工新增"
    :visible="Visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    width="700px"
  >
    <div class="edit-form">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="用户名" prop="fUserName">
          <a-input v-model="form.fUserName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="中文名" prop="fChineseName">
          <a-input v-model="form.fChineseName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="英文名" prop="fEnglishName">
          <a-input v-model="form.fEnglishName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="员工工号" prop="fUserNum">
          <a-input v-model="form.fUserNum" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="公司" prop="fCompanyID">
          <a-select
            v-model="form.fCompanyID"
            placeholder="请选择公司"
            @change="CompanyChange"
          >
            <a-select-option v-for="c in companydata" :key="c.fCompanyID">
              {{ c.fCompanyName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="上级主管">
          <a-select v-model="form.fUpManager" placeholder="请选择上级主管">
            <a-select-option v-for="e in employeedata" :key="e.fEmployeeID">
              {{ e.fChineseName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="二级主管">
          <a-select
            v-model="form.fTwoLevelManager"
            placeholder="请选择二级主管"
          >
            <a-select-option v-for="e in employeedata" :key="e.fEmployeeID">
              {{ e.fChineseName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="入职日期" prop="fDatesEmployed">
          <a-date-picker
            v-model="form.fDatesEmployed"
            type="date"
            placeholder="选择入职日期"
            style="width: 100%"
          />
        </a-form-model-item>
        <a-form-model-item label="性别" prop="fGender">
          <a-radio-group v-model="form.fGender">
            <a-radio value="M"> 男 </a-radio>
            <a-radio value="F"> 女 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
      <a-form-model
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="邮箱" prop="fEmail">
          <a-input v-model="form.fEmail" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="移动电话" prop="fMobilePhone">
          <a-input v-model="form.fMobilePhone" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="区域" prop="fAreaID">
          <a-select v-model="form.fAreaID" placeholder="请选择区域">
            <a-select-option v-for="a in areadata" :key="a.fBasicCodeID">
              {{ a.fCodeName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="地址">
          <a-input v-model="form.fAddress" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="部门" prop="fDepartmentID">
          <a-select v-model="form.fDepartmentID" placeholder="请选择部门">
            <a-select-option v-for="d in departmentdata" :key="d.fDepartmentID">
              {{ d.fDepartmentName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="是否填报工时" prop="fIsWorkingHours">
          <a-radio-group v-model="form.fIsWorkingHours">
            <a-radio value="T"> 是 </a-radio>
            <a-radio value="F"> 否 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="职务" prop="fStationID">
          <a-select v-model="form.fStationID" placeholder="请选择职务">
            <a-select-option v-for="p in positiondata" :key="p.fStationID">
              {{ p.fStationName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
  
<script>
import {
  SelecttEmployeesALL,
  SelecttCompaniesPaged,
  SelecttBasicCodesChild,
  SelecttDepartmentsDynamic,
  SelecttStationsDynamic,
  InsertOrUpdatetEmployee,
} from "../../api/Employee.js";
import moment from "moment";
export default {
  props: ["Visible"],
  data() {
    return {
      confirmLoading: false,
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      form: {
        isModified: true,
        fEmployeeID: "00000000-0000-0000-0000-000000000000",
        fCompanyID: undefined,
        fCompanyNum: "",
        fCompanyName: "",
        fChineseName: undefined,
        fEnglishName: undefined,
        fUserNum: undefined,
        fUserName: undefined,
        fPassword: "",
        fWeChatVerification: "",
        fUserType: "00000000-0000-0000-0000-000000000000",
        fUpManager: undefined,
        fTwoLevelManager: undefined,
        fGlossary: "00000000-0000-0000-0000-000000000000",
        fDatesEmployed: undefined,
        fGender: undefined,
        fBirthDate: "2000-01-01T00:00:00Z",
        fEmail: undefined,
        fPhone: "",
        fMobilePhone: undefined,
        fZipcode: "",
        fAreaID: undefined,
        fAreaNum: "",
        fAreaName: "",
        fAddress: "",
        fDepartmentID: undefined,
        fDepartmentNum: "",
        fDepartmentName: "",
        fHourTypeID: "00000000-0000-0000-0000-000000000000",
        fHourTypeNum: "",
        fHourTypeName: "",
        fLabourRate: 0,
        fIsWorkingHours: "",
        fStationID: undefined,
        fStationNum: "",
        fStationName: "",
        fState: "T",
        fOrderValue: "0",
        fIsLock: "F",
        fRemark: "",
        fCreateTime: undefined,
        fCreateUser: undefined,
        fChangedTime: undefined,
        fChangedUser: undefined,
      },
      rules: {
        fCompanyID: [
          {
            required: true,
            message: "请选择公司",
            trigger: "change",
          },
        ],
        fChineseName: [
          {
            required: true,
            message: "请输入中文名",
            trigger: "blur",
          },
        ],
        fEnglishName: [
          {
            required: true,
            message: "请输入英文名",
            trigger: "blur",
          },
        ],
        fUserNum: [
          {
            required: true,
            message: "请输入员工工号",
            trigger: "blur",
          },
        ],
        fUserName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        fDatesEmployed: [
          {
            required: true,
            message: "请选择入职日期",
            trigger: "change",
          },
        ],
        fGender: [
          {
            required: true,
            message: "请选择性别",
            trigger: "change",
          },
        ],
        fEmail: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        fMobilePhone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        fAreaID: [
          {
            required: true,
            message: "请选择区域",
            trigger: "change",
          },
        ],
        fDepartmentID: [
          {
            required: true,
            message: "请选择部门",
            trigger: "change",
          },
        ],
        fIsWorkingHours: [
          {
            required: true,
            message: "请选择是否填报",
            trigger: "change",
          },
        ],
        fStationID: [
          {
            required: true,
            message: "请选择职务",
            trigger: "change",
          },
        ],
      },
      companydata: [],
      areadata: [],
      departmentdata: [],
      positiondata: [],
      employeedata: [],
    };
  },
  mounted() {
    this.getCompanyList();
    this.getAreaList();
    this.getDepartmentList();
    this.getPosList();
    this.getEmployeeList();
  },
  methods: {
    handleOk() {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          this.form.fChangedUser = user.fEmployeeID;
          this.form.fChangedTime = moment(new Date()).format();
          this.form.fCreateUser = user.fEmployeeID;
          this.form.fCreateTime = moment(new Date()).format();
          this.form.fDatesEmployed = moment(this.form.fDatesEmployed).format();
          this.form.fUpManager =
            this.form.fUpManager || "00000000-0000-0000-0000-000000000000";
          this.form.fTwoLevelManager =
            this.form.fTwoLevelManager ||
            "00000000-0000-0000-0000-000000000000";
          var form = this.form;
          console.log(form, valid);
          InsertOrUpdatetEmployee(form).then((res) => {
            console.log(res);
            this.$emit("update:visible", false);
            this.confirmLoading = false;
          });
          //   this.axios.post("/department/add", { data: form }).then((res) => {
          //     console.log(res);
          //     this.$emit("update:visible", false);
          //     this.confirmLoading = false;
          //   });
          // setTimeout(() => {
          //   this.$emit("update:visible", false);
          //   this.confirmLoading = false;
          // }, 2000);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCancel() {
      console.log("Clicked cancel button");
      this.$emit("update:visible", false);
    },
    getCompanyList(param = {}) {
      SelecttCompaniesPaged({
        Page: 1,
        PageNum: 10,
        ...param,
      }).then((res) => {
        this.companydata = res.result;
      });
    },
    getAreaList(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      SelecttBasicCodesChild({
        UserID: user.fEmployeeID,
        ParentID: "8E3171AE-574B-40E8-9E2B-9B09F7302546",
        Status: "T",
        OrderField: "fOrderValue",
        ...param,
      }).then((res) => {
        this.areadata = res.result;
      });
    },
    getDepartmentList(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      SelecttDepartmentsDynamic({
        UserID: user.fEmployeeID,
        WhereClause: "[fState] ='T'",
        OrderField: "[fOrderValue]",
        ...param,
      }).then((res) => {
        this.departmentdata = res.result;
      });
    },
    getPosList(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      SelecttStationsDynamic({
        UserID: user.fEmployeeID,
        WhereClause: "[fState] ='T'",
        OrderField: "[fOrderValue]",
        ...param,
      }).then((res) => {
        this.positiondata = res.result;
      });
    },
    getEmployeeList(param = {}) {
      const user = JSON.parse(localStorage.getItem("AdminUser"));
      SelecttEmployeesALL({
        UserID: user.fEmployeeID,
        ...param,
      }).then((res) => {
        this.employeedata = res.result;
      });
    },
    CompanyChange(value) {
      console.log(value);
      for (let c of this.companydata) {
        if (c.fCompanyID === value) {
          console.log(c);
        }
      }
    },
  },
};
</script>
  
<style>
.edit-form {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 16px;
}
</style>
  